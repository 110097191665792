import React, { useCallback, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { CoreSDK, requestWithdrawalFromInvestment, WithdrawalFromInvestmentResponse } from '@investown/fe/api-sdk';
import { TestIds } from '@investown/fe/test-utils/testIds';

import InvestmentsList from './InvestmentsList';
import WithdrawalErrorModal from './WithdrawalErrorModal';
import WithdrawalSuccessModal from './WithdrawalSuccessModal';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Spacer from 'components/V2/Spacer/Spacer';
import Lang from 'util/IntlMessages';
import WarningOutlineIcon from 'components/Icons/WarningOutlineIcon';
import Button from 'components/V2/Button';
import { investmentWithdrawnAction } from 'appRedux/investment/actions';
import Typography from 'components/V2/Typography/Typography';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import TooltipInfo from 'components/V2/Icons/TooltipInfo';

type ModalPropsPicked = Pick<ModalProps, 'onClose' | 'visible'>;

interface InvestmentsWithdrawalModalProps extends ModalPropsPicked {
  investments: CoreSDK.InvestmentObjectType[];
  investmentRoundId: string;
  investmentRoundStatus: CoreSDK.PropertyInvestmentRoundStatus;
}

function InvestmentsWithdrawalModal({
  investments,
  onClose,
  visible,
  investmentRoundId,
  investmentRoundStatus,
}: InvestmentsWithdrawalModalProps) {
  const [selectedInvestment, setSelectedInvestment] = useState<CoreSDK.InvestmentObjectType>();
  const {
    mutate: requestWithdrawal,
    isLoading: submitIsLoading,
    isSuccess,
    isError,
    reset,
    data: withdrawalResponse,
  } = useMutation(requestWithdrawalFromInvestment);
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    setSelectedInvestment(undefined);
    onClose();
    reset();
  }, [onClose, reset]);

  const handleMutationSuccess = useCallback(
    (response: WithdrawalFromInvestmentResponse) => {
      if (response.result === 'SUCCESS_INVESTMENT_WITHDRAWN') {
        dispatch(investmentWithdrawnAction());
      } else {
        console.warn('Business error during withdrawal', response);
      }
    },
    [dispatch]
  );

  const handleConfirmWithdrawal = useCallback(() => {
    if (selectedInvestment && investmentRoundId) {
      requestWithdrawal(
        {
          transactionId: selectedInvestment.id,
          investmentRoundId,
          investmentAmount: {
            value: selectedInvestment.amount.amount,
            currency: selectedInvestment.amount.currency,
          },
        },
        {
          onSuccess: handleMutationSuccess,
        }
      );
    }
  }, [selectedInvestment, requestWithdrawal, investmentRoundId, handleMutationSuccess]);

  if ((isError || withdrawalResponse?.result === 'SUCCESS_INVESTMENT_NOT_WITHDRAWN') && selectedInvestment) {
    return <WithdrawalErrorModal onCloseModal={handleCloseModal} visible={visible} />;
  }

  if (isSuccess && selectedInvestment) {
    return <WithdrawalSuccessModal onCloseModal={handleCloseModal} visible={visible} />;
  }

  const getWithdrawnModalTitle = () => {
    if (investmentRoundStatus === CoreSDK.PropertyInvestmentRoundStatus.FullyInvested) {
      return 'property.withdrawFromInvestment.manual.heading';
    }

    if (!selectedInvestment) {
      return 'property.withdrawFromInvestment.heading';
    }

    return 'property.withdrawFromInvestment.confirm.heading';
  };

  return (
    <Modal
      maxWidth={700}
      header={
        <Typography variant="displayXSMedium" color="strong">
          <Lang id={getWithdrawnModalTitle()} />
        </Typography>
      }
      visible={visible}
      onClose={handleCloseModal}
    >
      {selectedInvestment ? (
        <InvestmentsWithdrawalConfirm
          handleConfirmWithdrawal={handleConfirmWithdrawal}
          handleCloseModal={handleCloseModal}
          submitIsLoading={submitIsLoading}
          isAutoinvest={selectedInvestment.isAutoinvest}
        />
      ) : (
        <>
          {investmentRoundStatus === CoreSDK.PropertyInvestmentRoundStatus.FullyInvested ||
          investmentRoundStatus === CoreSDK.PropertyInvestmentRoundStatus.Open ? (
            <>
              <Typography variant="bodyBASERegular" color="strong">
                <Lang id="property.withdrawFromInvestment.description1" />
              </Typography>
              <Spacer height="large" />
              <Typography variant="bodyBASERegular" color="strong">
                <Lang id="property.withdrawFromInvestment.description2" />
              </Typography>
              <Spacer height="extraLarge" />
              <InvestmentsList investments={investments} onSelectInvestment={setSelectedInvestment} />
            </>
          ) : (
            <>
              <Typography variant="bodyBASERegular" color="strong">
                <Lang id="property.withdrawFromInvestment.manual.description1" />
              </Typography>
              <Spacer height="large" />
            </>
          )}
        </>
      )}
    </Modal>
  );
}

function InvestmentsWithdrawalConfirm({
  handleConfirmWithdrawal,
  handleCloseModal,
  submitIsLoading,
  isAutoinvest,
}: {
  handleConfirmWithdrawal: () => void;
  handleCloseModal: () => void;
  submitIsLoading: boolean;
  isAutoinvest: boolean;
}) {
  const theme = useContext(ThemeContext);
  const { formatMessage } = useIntl();

  return (
    <>
      {isAutoinvest ? (
        <>
          <Typography variant="bodyBASERegular" color="strong">
            <Lang id="property.withdrawFromInvestment.confirm.disclaimer" />
          </Typography>
          <Spacer height="extraLarge" />
          <InfoLabel
            backgroundColor={theme.colorTokens.surface.brandFaded25}
            text={formatMessage({ id: 'property.withdrawFromInvestment.confirm.autoinvest.description' })}
            textColor={theme.colorTokens.text.strong}
            fullWidth
            icon={
              <TooltipInfo
                color={theme.colorTokens.icon.brand}
                hoverColor={theme.colorTokens.icon.brand}
                fillColor="transparent"
                width="24px"
                heightInherit
              />
            }
          />
        </>
      ) : (
        <>
          <FlexRow alignVertical={AlignDirections.Center}>
            <Alert>
              <WarningOutlineIcon color={theme.tag.color.alert} width="20px" />
            </Alert>
            <Spacer width="large" />
            <Typography variant="bodyBASESemiBold" color="strong">
              <Lang id="property.withdrawFromInvestment.confirm.disclaimer" />
            </Typography>
          </FlexRow>
          <Spacer height="extraLarge" />
          <Typography variant="bodyBASERegular" color="strong">
            <Lang id="property.withdrawFromInvestment.confirm.description" />
          </Typography>
        </>
      )}
      <Spacer height="massive" />
      <FullWidthRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexEnd}>
        <Button
          data-testid={TestIds.ConfirmWithdrawFromInvestment}
          variant="danger"
          onClick={handleConfirmWithdrawal}
          loading={submitIsLoading}
          disabled={submitIsLoading}
        >
          <Lang id="property.withdrawFromInvestment.confirm.confirmButton" />
        </Button>
        <Spacer width="regular" />
        <Button
          data-testid={TestIds.KeepInvestment}
          variant="primary"
          onClick={handleCloseModal}
          disabled={submitIsLoading}
        >
          <Lang id="property.withdrawFromInvestment.confirm.closeButton" />
        </Button>
      </FullWidthRow>
    </>
  );
}

const FullWidthRow = styled(FlexRow)`
  width: 100%;
`;

const Alert = styled.div`
  background: ${({ theme }) => theme.tag.background.alert};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export default InvestmentsWithdrawalModal;

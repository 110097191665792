/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useFeatureFlag } from 'configcat-react';

import { CoreSDK } from '@investown/fe/api-sdk';
import { createImageResizeUrl, currencyFormat } from '@investown/fe/common-utils';
import { InvestmentLengthInMonths } from '@investown/fe/ui-utils/InvestmentLengthInMonths';
import { RemainingTime } from '@investown/fe/ui-utils/RemainingTime';

import Spacer from 'components/Spacer';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import RoundStatusWithTooltip from 'containers/Property/Sidebar/RoundStatusWithTooltip';
import InvestmentInfoRow from 'components/V2/InvestmentInfo/InvestmentInfoRow';
import Euro from 'components/V2/Icons/Euro';
import { theme } from 'styles/theme';
import { getAppLanguageLocalVariable } from 'lngProvider';
import TrendingUp from 'components/V2/Icons/StatusLabel/TrendingUp';
import Clock from 'components/V2/Icons/Clock';
import withUserDetailsAndLevels, { WithUserDetailsAndLevelsProps } from 'util/withUserDetailsAndLevels';
import PerAnnumBonusYield from 'components/V2/PerAnnum/PerAnnumBonusYield';
import VipPill from 'components/V2/VipPill/VipPill';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';

interface Props {
  name: string;
  photo: Pick<CoreSDK.PropertyPhotoObjectType, 'id' | 'url' | 'isPrimary'>;
  annualPercentageYield: number;
  oldestUnpaidInstallmentDate: string;
  oldestUnpaidInstallmentDateWithGracePeriod: string;
  investmentRoundRepaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  status: CoreSDK.PropertyInvestmentRoundStatus;
  investmentTermEnd: number | null;
  investmentLengthInMonths: number;
  investmentAmount: number;
  investmentCurrency: string;
  interestAmount: CoreSDK.FloatMonetaryAmountObjectType;
  bonusYieldEligible: boolean;
  isProjectVip: boolean;
}

export const imageSize = {
  width: 316 * 2,
  height: 241 * 2,
};

const RelatedProjectsCard: FC<Props & WithUserDetailsAndLevelsProps> = ({
  name,
  photo,
  annualPercentageYield,
  oldestUnpaidInstallmentDate,
  oldestUnpaidInstallmentDateWithGracePeriod,
  investmentRoundRepaymentStatus,
  status,
  investmentTermEnd,
  investmentLengthInMonths,
  investmentAmount,
  investmentCurrency,
  interestAmount,
  bonusYieldForUser,
  bonusYieldEligible,
  isProjectVip,
}) => {
  const intl = useIntl();
  const userLocale = getAppLanguageLocalVariable();

  const { value: showYieldRangeEnabled, loading: showYieldRangeLoading } = useFeatureFlag(
    FeatureFlags.ShowYieldRangeWeb,
    FallbackFeatureFlagValues[FeatureFlags.ShowYieldRangeWeb]
  );

  const showYieldRangeLoadedAndEnabled = !showYieldRangeLoading && showYieldRangeEnabled;

  return (
    <Card>
      <FlexColumn>
        <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
          {showYieldRangeLoadedAndEnabled ? (
            <PerAnnumBonusYield
              bonusYieldEligible={bonusYieldEligible}
              annualPercentageYield={annualPercentageYield}
              layout="row"
              valueFont="labelLGSemiBold"
              valueColor="strong"
              suffixFont="labelXSRegular"
              suffixColor="subtle"
              prefixFont="labelLGRegular"
              strikeThroughPrefixFont="bodyXSRegular"
              strikeThroughFont="labelXSSemiBold"
              strikeThroughSuffixFont="bodyXXSRegular"
              isWithYieldRangeShortText={false}
              isWithTooltip={false}
            />
          ) : (
            <PerAnnumBonusYield
              bonusYieldEligible={bonusYieldEligible}
              annualPercentageYield={annualPercentageYield}
              layout="row"
            />
          )}
        </Row>
        <Spacer height="large" />
        <Typography variant="labelLGRegular" color="strong">
          {name}
        </Typography>
        <Spacer height="large" />
        <RoundStatusWithTooltip
          oldestUnpaidInstallmentDate={oldestUnpaidInstallmentDate}
          oldestUnpaidInstallmentDateWithGracePeriod={oldestUnpaidInstallmentDateWithGracePeriod}
          repaymentStatus={investmentRoundRepaymentStatus}
          status={status}
          investmentTermEnd={investmentTermEnd}
          isThin
        />
        <Spacer height="regular" />

        <InvestmentInfoRow
          title="property.relatedProjectInfo.investmentAmount"
          titleColor="subtle"
          icon={<Euro color={theme.colorTokens.icon.subtle} hoverColor={theme.colorTokens.icon.subtle} />}
        >
          {currencyFormat({ input: investmentAmount, currency: investmentCurrency, locale: intl.locale })}
        </InvestmentInfoRow>
        <Spacer height="regular" />

        <InvestmentInfoRow
          title="property.relatedProjectInfo.interestAmount"
          titleColor="subtle"
          icon={<TrendingUp color={theme.colorTokens.icon.subtle} hoverColor={theme.colorTokens.icon.subtle} />}
        >
          {currencyFormat({ input: interestAmount.value, currency: interestAmount.currency, locale: intl.locale })}
        </InvestmentInfoRow>
        <Spacer height="regular" />

        <InvestmentInfoRow
          title="property.relatedProjectInfo.remainingTime"
          titleColor="subtle"
          icon={<Clock color={theme.colorTokens.icon.subtle} hoverColor={theme.colorTokens.icon.subtle} />}
        >
          {!investmentTermEnd && <InvestmentLengthInMonths months={investmentLengthInMonths} userLocale={userLocale} />}
          {investmentTermEnd &&
            (investmentTermEnd < Date.now() ? (
              <>-</>
            ) : (
              <RemainingTime date={investmentTermEnd} userLocale={userLocale} />
            ))}
        </InvestmentInfoRow>
      </FlexColumn>
      <Spacer width="massive" />
      <RightColumn>
        <ProjectImageWrapper>
          {isProjectVip && (
            <VipPillWrapper>
              <VipPill size="small" />
            </VipPillWrapper>
          )}
          <ProjectImage
            src={createImageResizeUrl({
              originalUrl: photo.url,
              width: imageSize.width,
              height: imageSize.height,
            })}
            alt={name}
          />
        </ProjectImageWrapper>
      </RightColumn>
    </Card>
  );
};

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Row = styled(FlexRow)`
  width: 420px;
`;

export const RightColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const ProjectImageWrapper = styled.div`
  position: relative;
`;

const ProjectImage = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.large};
  min-width: 316px;
  height: auto;
`;

const VipPillWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spacing.medium};
  left: ${({ theme }) => theme.spacing.medium};
`;
export default withUserDetailsAndLevels(RelatedProjectsCard);

/* eslint-disable @nx/enforce-module-boundaries */
import { useIntl } from 'react-intl';

import { InterestChangeObjectType } from '../../../../api-sdk/src/lib/core/__generated__/sdk';
import { formatDateTime } from '../../../../common-utils/src/lib/date/dateUtils';
import { percentFormat } from '../../../../common-utils/src/lib/number';

export const useProlongedBannerLangValues = (
  interestRates: Omit<InterestChangeObjectType, 'id'>[],
  langValues: {
    b: (chunks: string) => JSX.Element;
    activity: (chunks: string) => JSX.Element;
    repaymentSchedule: (chunks: string) => JSX.Element;
  }
):
  | {
      id: string;
      values: Record<string, React.ReactNode>;
    }
  | undefined => {
  const { locale } = useIntl();

  if (interestRates == null || interestRates.length === 0) {
    return;
  }

  if (!isFirstProlongation(interestRates)) {
    return {
      id: 'property.detail.prolongation.repeated',
      // @ts-expect-error passing a function instead of ReactNode
      values: {
        ...langValues,
        prolongedUntil: formatDateTime({ date: interestRates[0].changeTo, locale }),
      },
    };
  }

  if (hasInterestRateIncrease(interestRates[0])) {
    return {
      id: 'property.detail.prolongation.first.interest',
      // @ts-expect-error passing a function instead of ReactNode
      values: {
        ...langValues,
        prolongedUntil: formatDateTime({ date: interestRates[0].changeTo, locale }),
        interestRate: percentFormat({ input: interestRates[0].interestRateIncrease, locale }),
      },
    };
  }

  return {
    id: 'property.detail.prolongation.first.noInterest',
    // @ts-expect-error passing a function instead of ReactNode
    values: {
      ...langValues,
      prolongedUntil: formatDateTime({ date: interestRates[0].changeTo, locale }),
    },
  };
};

function isFirstProlongation(interestRates: Omit<InterestChangeObjectType, 'id'>[]): boolean {
  return interestRates.length === 1;
}

function hasInterestRateIncrease(interestRates: Omit<InterestChangeObjectType, 'id'>): boolean {
  return interestRates.interestRateIncrease != null && interestRates.interestRateIncrease > 0;
}

import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { UpdatePreferredLocaleRequest } from './model';

export async function updatePreferredLocale(body: UpdatePreferredLocaleRequest): Promise<void> {
  return apiPost<void, UpdatePreferredLocaleRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.UpdatePreferredLocale,
    body,
    true
  );
}

import { UserPreference } from './__generated__/sdk';

export function filterOnlySupportedUserPreferences(userPreferences: UserPreference[]): UserPreference[] {
  const preferenceCategoryNameWhiteList = [
    'NewsAndPersonalisedOffers',
    'NewInvestmentOpportunities',
    'SummaryReports',
    'ProfitNotifications',
  ];
  return userPreferences.filter(({ preferenceCategoryName }) =>
    preferenceCategoryNameWhiteList.includes(preferenceCategoryName)
  );
}

export function sortPreferences(userPreferences: UserPreference[]): UserPreference[] {
  return userPreferences.sort((a, b) => {
    const preferenceCategoryNameOrder = [
      'NewsAndPersonalisedOffers',
      'NewInvestmentOpportunities',
      'SummaryReports',
      'ProfitNotifications',
    ];
    return (
      preferenceCategoryNameOrder.indexOf(a.preferenceCategoryName) -
      preferenceCategoryNameOrder.indexOf(b.preferenceCategoryName)
    );
  });
}

export function filterOnlySupportedEmailPreferences(userPreferences: UserPreference[]): UserPreference[] {
  return userPreferences.filter(({ preferenceCategoryName }) => preferenceCategoryName !== 'ProfitNotifications');
}

export function filterOnlySupportedSmsPreferences(userPreferences: UserPreference[]): UserPreference[] {
  return userPreferences.filter(({ preferenceCategoryName }) => preferenceCategoryName === 'NewsAndPersonalisedOffers');
}

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./globals.d.ts" />
import * as Sentry from '@sentry/react';

import { createLoggerImpl } from './core';
import type { Logger } from './types';

const isDev = process.env.REACT_APP_ENVIRONMENT !== 'prod';

export const createLogger = (): Logger => {
  const logger = createLoggerImpl(isDev, Sentry, (Logger) => {
    window.addEventListener('error', (event) => {
      Logger.fatal('💥', 'Global error', event.error ?? event.message);
    });

    window.addEventListener('unhandledrejection', (event) => {
      Logger.fatal('💥', 'Unhandled Promise rejection', event.reason);
    });
  });

  return logger;
};

/* eslint-disable @nx/enforce-module-boundaries */
import Big from 'big.js';

import { valueToPercent } from '@investown/fe/common-utils';

import { UserDetailsDTO, UserLevelType } from '../../../../api-sdk/src/lib/users/model';
import * as CoreSDK from '../../../../api-sdk/src/lib/core/__generated__/sdk';

export type PropertiesTagColor = 'primary' | 'alert' | 'success' | 'warning';

export enum PropertyEndedInvestmentRoundStatus {
  PaidOff = 'PaidOff',
  PaymentOfPrincipal = 'PaymentOfPrincipal',
}

export function getEndedInvestmentRoundStatus(
  status: CoreSDK.PropertyInvestmentRoundStatus,
  isInvestmentRoundEnded?: boolean,
  isLegacyEnded?: boolean
): PropertyEndedInvestmentRoundStatus | undefined {
  if (isInvestmentRoundEnded && status === CoreSDK.PropertyInvestmentRoundStatus.Funded) {
    return PropertyEndedInvestmentRoundStatus.PaymentOfPrincipal;
  }
  if (isInvestmentRoundEnded && !isLegacyEnded && status === CoreSDK.PropertyInvestmentRoundStatus.Unknown) {
    return PropertyEndedInvestmentRoundStatus.PaymentOfPrincipal;
  }
  if (
    status === CoreSDK.PropertyInvestmentRoundStatus.Ended ||
    (isLegacyEnded && status === CoreSDK.PropertyInvestmentRoundStatus.Unknown)
  ) {
    return PropertyEndedInvestmentRoundStatus.PaidOff;
  }
  return undefined;
}

export function getPropertyInvestmentRoundStatusTranslationKey({
  status,
  repaymentStatus,
  isInvestmentRoundEnded,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  repaymentStatus?: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  isInvestmentRoundEnded?: boolean;
}): string {
  if (
    repaymentStatus &&
    repaymentStatus !== CoreSDK.PropertyInvestmentRoundRepaymentStatus.Unknown &&
    !isInvestmentRoundEnded
  ) {
    return `property.currentInvestmentRound.repaymentStatus.${repaymentStatus}`;
  }
  return `property.currentInvestmentRound.status.${status}`;
}

interface PercentageAmountAvailableForInvestmentParams {
  investmentAmountAvailable: number;
  investmentAmountTotal: number;
  fraction: number;
}

export const percentageAmountAvailableForInvestment = ({
  investmentAmountAvailable,
  investmentAmountTotal,
  fraction,
}: PercentageAmountAvailableForInvestmentParams): number => {
  return valueToPercent({
    value: investmentAmountAvailable,
    total: investmentAmountTotal,
    fraction,
    roundingMode: Big.roundUp,
  });
};

export type PropertiesQueryItem = CoreSDK.PageablePropertiesQuery['items'][number];
export type PropertyInvestmentRound = Exclude<
  CoreSDK.PageablePropertiesQuery['items'][number]['currentInvestmentRound'],
  null | undefined
>;
export type SecondaryMarketOffer = Exclude<CoreSDK.SecondaryMarketOffer, null | undefined>;

export type SecondaryMarketBid = CoreSDK.SecondaryMarketBidsQuery['secondaryMarketBids'][number];

export const isUserPremium = (userDetails: UserDetailsDTO | undefined): boolean =>
  userDetails?.level.type === UserLevelType.Premium;

export const isPropertyPremium = (
  propertyInvestmentRound:
    | Pick<PropertyInvestmentRound | CoreSDK.InvestmentRoundSecondaryMarketInfo, 'level'>
    | undefined
    | null
): boolean => {
  if (!propertyInvestmentRound) {
    return false;
  }
  return propertyInvestmentRound.level === CoreSDK.PropertyInvestmentRoundLevel.Premium;
};

export const isProjectVip = (
  propertyInvestmentRound:
    | Pick<PropertyInvestmentRound | CoreSDK.InvestmentRoundSecondaryMarketInfo, 'level'>
    | undefined
    | null
): boolean => {
  if (!propertyInvestmentRound) {
    return false;
  }
  return propertyInvestmentRound.level === CoreSDK.PropertyInvestmentRoundLevel.Vip;
};

export const isProjectVipByLevel = (
  level: CoreSDK.PropertyInvestmentRoundLevel | CoreSDK.SecondaryMarketItemLevel | undefined
): boolean => {
  if (!level) {
    return false;
  }
  return level === CoreSDK.PropertyInvestmentRoundLevel.Vip;
};

export const isProjectLegacy = (investmentRoundType: CoreSDK.PropertyInvestmentRoundType): boolean =>
  investmentRoundType === CoreSDK.PropertyInvestmentRoundType.Legacy;

export const isPropertyLocked = (
  propertyInvestmentRound: CoreSDK.PropertyInvestmentRoundLevel | CoreSDK.SecondaryMarketItemLevel,
  premiumUser: boolean | undefined
): boolean => {
  if (
    !premiumUser &&
    (propertyInvestmentRound === CoreSDK.PropertyInvestmentRoundLevel.Premium ||
      propertyInvestmentRound === CoreSDK.SecondaryMarketItemLevel.Premium)
  ) {
    return true;
  }
  return false;
};

export const isSecondaryMarketOfferPremium = (level: CoreSDK.SecondaryMarketItemLevel): boolean => {
  if (!level) {
    return false;
  }
  return level === CoreSDK.SecondaryMarketItemLevel.Premium;
};

export const getPropertyBackgroundColor = (
  theme: { color: { [key: string]: string } },
  propertyIsPremium: boolean,
  userIsPremium?: boolean
): string => {
  if (!userIsPremium && propertyIsPremium) {
    return theme.color.bodyBackground;
  }

  if (userIsPremium && propertyIsPremium) {
    return theme.color.warningFaded;
  }

  return theme.color.contentBackground;
};

export const getPremiumLink = (locale: string | null): string =>
  `https://investown.${locale === 'cs' ? 'cz' : 'com'}/investown-premium`;

export const getMembershipLevelsLink = (locale: string | null): string => {
  switch (locale) {
    case 'cs':
      return 'https://www.investown.cz/investown-clenstvi';
    default:
      return 'https://www.investown.com/investown-membership';
  }
};

export const isCrowdfundingInvestmentRound = (propertyInvestmentRound: PropertyInvestmentRound): boolean =>
  propertyInvestmentRound.type === CoreSDK.PropertyInvestmentRoundType.Crowdfunding;

export const isPropertyHighlighted = (propertyInvestmentRound: PropertyInvestmentRound): boolean =>
  propertyInvestmentRound.status === CoreSDK.PropertyInvestmentRoundStatus.Open;

export function calculateRemainingYield(
  repaymentStatistics: CoreSDK.InvestedPropertyRoundRepaymentStatisticsQuery | undefined,
  percentOffered: number | undefined
): { yield: number; percent: number | undefined } | undefined {
  if (!repaymentStatistics || !repaymentStatistics.investedPropertyRoundRepaymentStatistics || !percentOffered)
    return { yield: 0, percent: 0 };

  const { expectedYield, currentYield } = repaymentStatistics.investedPropertyRoundRepaymentStatistics;

  const expectedYieldBig = new Big(expectedYield);
  const currentYieldBig = new Big(currentYield);
  const percentOfferedBig = new Big(percentOffered);

  const percentTotal = expectedYieldBig.gt(0)
    ? Math.round(currentYieldBig.div(expectedYieldBig).times(100).toNumber())
    : undefined;

  const yieldBig = expectedYieldBig.minus(currentYieldBig).times(percentOfferedBig.div(100));

  return { yield: yieldBig.toNumber(), percent: percentTotal };
}

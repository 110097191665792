/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazy } from '@loadable/component';
import styled from 'styled-components';

import MobileOverlayScreen from './MobileOverlayScreen';
import RedirectRoute from './RedirectRoute';

import {
  PATH_ABOUT_MARKETPLACE,
  PATH_CHANGE_PASSWORD,
  PATH_DASHBOARD,
  PATH_ERROR_404,
  PATH_FORGOTTEN_PASSWORD,
  PATH_FRAMEWORK_AGREEMENT_PREVIEW,
  PATH_INVITE_FRIEND,
  PATH_PREMIUM,
  PATH_PROPERTIES,
  PATH_PROPERTY,
  PATH_RESET_PASSWORD,
  PATH_SIGN_IN,
  PATH_SIGN_IN_MFA,
  PATH_INDIVIDUAL_SIGN_UP_CREDENTIALS,
  PATH_INDIVIDUAL_SIGN_UP_EMAIL,
  PATH_INDIVIDUAL_SIGN_UP_PASSWORD,
  PATH_USER_VERIFICATION,
  PATH_WALLET,
  PATH_LEGAL_ENTITY_SIGN_UP_PASSWORD,
  PATH_LEGAL_ENTITY_SIGN_UP_CONFIRM_COMPANY_INFORMATIONS,
  PATH_LEGAL_ENTITY_SIGN_UP_EMAIL,
  PATH_LEGAL_ENTITY_SIGN_UP_IDENTIFICATION_NUMBER,
  PATH_LEGAL_ENTITY_SIGN_UP_EXECUTIVE,
  PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS,
  PATH_APP,
  PATH_REFERRAL_CONNECTION,
  PATH_REFERRAL_DOWNLOAD,
  PATH_ABILITY_TO_BEAR_LOSS,
  PATH_INVESTOR_QUESTIONNAIRE,
  PATH_INVESTOR_QUESTIONNAIRE_ADDITION,
  PATH_SECONDARY_MARKET_BIDS,
  PATH_INVESTED_PROPERTIES,
  PATH_MOBILE,
  PATH_USER,
  PATH_USER_DOCUMENTS,
  PATH_USER_REFERRAL,
  PATH_USER_MFA,
  PATH_USER_NOTIFICATIONS,
  PATH_USER_LANGUAGES,
  PATH_USER_PASSWORD_CHANGE,
  PATH_USER_MEMBERSHIP_LEVELS,
  PATH_USER_SUPPORT,
  PATH_AUTOINVEST,
  PATH_AUTOINVEST_STRATEGY_CONFIGURATION,
  PATH_AUTOINVEST_SUCCESS,
  PATH_AUTOINVEST_STANDING_ORDER,
  PATH_AUTOINVEST_INTRO,
  PATH_AUTOINVEST_EDIT_STRATEGY,
  PATH_AUTOINVEST_CALCULATOR,
} from 'routes/routesPaths';
import { withSuspense } from 'util/withSuspense';
import StyledPrivateRoute from 'routes/StyledPrivateRoute';
import SignedOutRoute from 'routes/SignedOutRoute';
import { RouteConfig, RouteType } from 'routes/routeInterfaces';

const Dashboard = lazy(() => import(/* webpackChunkName: "DashboardRoute" */ './Main/Dashboard'));
const Portfolio = lazy(() => import(/* webpackChunkName: "PortfolioRoute" */ './Main/Portfolio'));
const SecondaryMarketBids = lazy(
  () => import(/* webpackChunkName: "SecondaryMarketBidsRoute" */ './Main/SecondaryMarketBids')
);
const Wallet = lazy(() => import(/* webpackChunkName: "WalletRoute" */ './Main/Wallet'));
const MobileApp = lazy(() => import(/* webpackChunkName: "AppRoute" */ './Main/MobileApp'));
const IndividualEmail = lazy(
  () => import(/* webpackChunkName: "IndividualSignUpEmailRoute" */ './SignUp/Individual/Email')
);

const ReferralConnect = lazy(() => import(/* webpackChunkName: "ReferralConnect" */ './Referral'));
const ReferralDownloadApp = lazy(() => import(/* webpackChunkName: "ReferralDownloadApp" */ './Referral/DownloadApp'));
const LegalEntityEmail = lazy(
  () => import(/* webpackChunkName: "LegalEntitySignUpEmailRoute" */ './SignUp/LegalEntity/Email')
);
const ForgottenPassword = lazy(
  () => import(/* webpackChunkName: "ForgottenPasswordEmailRoute" */ './ForgottenPassword/Email')
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "ForgottenPasswordResetPasswordRoute" */ './ForgottenPassword/ResetPassword')
);
const ChangePassword = lazy(() => import(/* webpackChunkName: "ChangePasswordRoute" */ './ChangePassword'));
const IndividualCredentials = lazy(
  () => import(/* webpackChunkName: "IndividualSignUpCredentialsRoute" */ './SignUp/Individual/Credentials')
);
const IndividualPassword = lazy(
  () => import(/* webpackChunkName: "IndividualSignUpPasswordRoute" */ './SignUp/Individual/Password')
);
const LegalEntityIdentificationNumber = lazy(
  () =>
    import(
      /* webpackChunkName: "LegalEntitySignUpIdentificationNumberRoute" */ './SignUp/LegalEntity/IdentificationNumber'
    )
);
const LegalEntityConfirmCompanyInformations = lazy(
  () =>
    import(
      /* webpackChunkName: "LegalEntityConfirmCompanyInformationsRoute" */ './SignUp/LegalEntity/ConfirmCompanyInformations'
    )
);
const LegalEntityExecutive = lazy(
  () => import(/* webpackChunkName: "LegalEntitySignUpExecutiveRoute" */ './SignUp/LegalEntity/Executive')
);
const LegalEntityPassword = lazy(
  () => import(/* webpackChunkName: "LegalEntitySignUpPasswordRoute" */ './SignUp/LegalEntity/Password')
);
const LegalEntityRequiredDocuments = lazy(
  () => import(/* webpackChunkName: "LegalEntityRequiredDocuments" */ './User/RequiredDocuments')
);
const SignIn = lazy(() => import(/* webpackChunkName: "SignInRoute" */ './SignIn'));
const SignInMfaCode = lazy(() => import(/* webpackChunkName: "SignInMfaCodeRoute" */ './SignIn/MfaCode'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFoundRoute" */ './NotFound'));
const UserVerification = lazy(() => import(/* webpackChunkName: "UserVerification" */ './User/Verification'));
const AbilityToBearLoss = lazy(() => import(/* webpackChunkName: "AbilityToBearLoss" */ './User/AbilityToBearLoss'));
const InvestorQuestionnaire = lazy(
  () => import(/* webpackChunkName: "AbilityToBearLoss" */ './User/InvestorQuestionnaire')
);
const InvestorQuestionnaireAddition = lazy(
  () => import(/* webpackChunkName: "AbilityToBearLoss" */ './User/InvestorQuestionnaireAddition')
);
const Property = lazy(() => import(/* webpackChunkName: "Property" */ './Property'));
const FrameworkAgreementPreview = lazy(
  () => import(/* webpackChunkName: "FrameworkAgreementPreview" */ './User/FrameworkAgreementPreview')
);
const Premium = lazy(() => import(/* webpackChunkName: "Premium" */ './Premium'));
const AboutMarketplace = lazy(() => import(/* webpackChunkName: "Premium" */ './AboutMarketplace'));
const User = lazy(() => import(/* webpackChunkName: "User" */ './User/User'));
const Autoinvest = lazy(() => import(/* webpackChunkName: "Autoinvest" */ './Autoinvest'));
const AutoinvestSuccess = lazy(() => import(/* webpackChunkName: "AutoinvestSuccess" */ './AutoinvestSuccess'));
const AutoinvestStrategyConfiguration = lazy(
  () => import(/* webpackChunkName: "AutoinvestStrategyConfiguration" */ './AutoinvestStrategyConfiguration')
);
const AutoinvestStandingOrder = lazy(
  () => import(/* webpackChunkName: "AutoinvestStandingOrder" */ './AutoinvestStandingOrder')
);
const AutoinvestIntro = lazy(() => import(/* webpackChunkName: "AutoinvestIntro" */ './AutoinvestIntro'));
const AutoinvestEditStrategy = lazy(
  () => import(/* webpackChunkName: "AutoinvestEditStrategy" */ './AutoinvestEditStrategy')
);
const AutoinvestCalculator = lazy(
  () => import(/* webpackChunkName: "AutoinvestEditStrategy" */ './AutoinvestCalculator')
);

/** PRIVATE routes for signed in user */
export const privateRoutesConfig: RouteConfig[] = [
  { path: PATH_CHANGE_PASSWORD, hideNavbar: true, component: ChangePassword, exact: true },
  { path: PATH_DASHBOARD, component: Dashboard, exact: true },
  { path: PATH_INVESTED_PROPERTIES, component: Portfolio, exact: false },
  { path: PATH_PROPERTIES, component: Dashboard, exact: false },
  { path: PATH_SECONDARY_MARKET_BIDS, component: SecondaryMarketBids, exact: false },
  { path: PATH_USER, component: User, exact: true },
  { path: PATH_USER_DOCUMENTS, component: User, exact: true },
  { path: PATH_USER_REFERRAL, component: User, exact: true },
  {
    path: PATH_INVITE_FRIEND,
    exact: true,
    component: () => <RedirectRoute redirectFrom={PATH_INVITE_FRIEND} redirectTo={PATH_USER_REFERRAL} />,
  },
  { path: PATH_USER_MEMBERSHIP_LEVELS, component: User, exact: true },
  { path: PATH_USER_SUPPORT, component: User, exact: true },
  { path: PATH_USER_MFA, component: User, exact: true },
  { path: PATH_USER_NOTIFICATIONS, component: User, exact: true },
  { path: PATH_USER_LANGUAGES, component: User, exact: true },
  { path: PATH_USER_PASSWORD_CHANGE, component: User, exact: true },
  { path: PATH_USER_VERIFICATION, component: UserVerification, hideNavbar: true, exact: false },
  { path: PATH_ABILITY_TO_BEAR_LOSS, component: AbilityToBearLoss, hideNavbar: true, exact: true },
  { path: PATH_INVESTOR_QUESTIONNAIRE, component: InvestorQuestionnaire, hideNavbar: true, exact: true },
  {
    path: PATH_INVESTOR_QUESTIONNAIRE_ADDITION,
    component: InvestorQuestionnaireAddition,
    hideNavbar: true,
    exact: true,
  },
  { path: PATH_WALLET, component: Wallet, exact: true },
  { path: PATH_AUTOINVEST, component: Autoinvest, exact: true },
  { path: PATH_AUTOINVEST_CALCULATOR, component: AutoinvestCalculator, exact: true },
  { path: PATH_AUTOINVEST_SUCCESS, component: AutoinvestSuccess, exact: true },
  { path: PATH_AUTOINVEST_STRATEGY_CONFIGURATION, component: AutoinvestStrategyConfiguration, exact: true },
  { path: PATH_AUTOINVEST_EDIT_STRATEGY, component: AutoinvestEditStrategy, exact: true },
  { path: PATH_AUTOINVEST_STANDING_ORDER, component: AutoinvestStandingOrder, exact: true },
  { path: PATH_AUTOINVEST_INTRO, component: AutoinvestIntro, exact: true },
  { path: PATH_PROPERTY, component: Property, exact: true },
  { path: PATH_FRAMEWORK_AGREEMENT_PREVIEW, component: FrameworkAgreementPreview, hideNavbar: true, exact: true },
  { path: PATH_PREMIUM, component: Premium, exact: true, fullScreen: true },
  { path: PATH_ABOUT_MARKETPLACE, component: AboutMarketplace, exact: true, fullScreen: true },
  {
    path: PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS,
    component: LegalEntityRequiredDocuments,
    exact: true,
    fullScreen: true,
    hideNavbar: true,
  },
  { path: PATH_APP, component: MobileApp, exact: true },
].map((config) => ({ ...config, type: 'Private' as RouteType }));

/** PUBLIC routes for SIGNED OUT user - user is redirected to app if already signed in */
export const publicSignedOutRoutesConfig: RouteConfig[] = [
  { path: PATH_FORGOTTEN_PASSWORD, component: ForgottenPassword, exact: true },
  { path: PATH_RESET_PASSWORD, component: ResetPassword, exact: true },
  { path: PATH_SIGN_IN, exact: true, component: SignIn },
  { path: PATH_SIGN_IN_MFA, exact: true, component: SignInMfaCode },
  { path: PATH_INDIVIDUAL_SIGN_UP_CREDENTIALS, component: IndividualCredentials, exact: true },
  { path: PATH_INDIVIDUAL_SIGN_UP_PASSWORD, component: IndividualPassword, exact: true },
  { path: PATH_INDIVIDUAL_SIGN_UP_EMAIL, component: IndividualEmail, exact: true },
  { path: PATH_LEGAL_ENTITY_SIGN_UP_IDENTIFICATION_NUMBER, component: LegalEntityIdentificationNumber, exact: true },
  {
    path: PATH_LEGAL_ENTITY_SIGN_UP_CONFIRM_COMPANY_INFORMATIONS,
    component: LegalEntityConfirmCompanyInformations,
    exact: true,
  },
  { path: PATH_LEGAL_ENTITY_SIGN_UP_EXECUTIVE, component: LegalEntityExecutive, exact: true },
  { path: PATH_LEGAL_ENTITY_SIGN_UP_PASSWORD, component: LegalEntityPassword, exact: true },
  { path: PATH_LEGAL_ENTITY_SIGN_UP_EMAIL, component: LegalEntityEmail, exact: true },
].map((config) => ({ ...config, hideNavbar: true, type: 'SignedOut' as RouteType }));

export const publicRoutesConfig: RouteConfig[] = [
  { path: PATH_REFERRAL_CONNECTION, component: ReferralConnect, exact: true },
  { path: PATH_REFERRAL_DOWNLOAD, component: ReferralDownloadApp, exact: true },
  { path: PATH_MOBILE, component: MobileOverlayScreen, exact: true },
].map((config) => ({ ...config, hideNavbar: true, type: 'Public' as RouteType }));

/** inspired by https://reacttraining.com/react-router/web/example/sidebar */
export const routesConfig: RouteConfig[] = [
  ...privateRoutesConfig,
  ...publicSignedOutRoutesConfig,
  ...publicRoutesConfig,
  // 404
  { path: [PATH_ERROR_404, '*'], component: withSuspense(NotFound), type: 'Public' as RouteType, hideNavbar: true },
];

// noinspection JSCheckFunctionSignatures
const App: React.FC = () => (
  <Wrapper>
    <Switch>
      {routesConfig.map((route) => {
        switch (route.type) {
          case 'Private':
            return <StyledPrivateRoute {...route} key={route.path as string} />;
          case 'SignedOut':
            return <SignedOutRoute {...route} key={route.path as string} />;
          default:
            return <Route {...route} key={route.path as string} />;
        }
      })}
    </Switch>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.topbar.height};
`;

export default App;

export function omitNullAndUndefinedValues<T extends object>(obj: T): Partial<T> {
  if (!obj) return {};

  return Object.entries(obj).reduce<Partial<T>>((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        (acc as Partial<T>)[key as keyof T] = value
          .map((item) => (typeof item === 'object' && item !== null ? omitNullAndUndefinedValues(item) : item))
          .filter((item) => item !== null && item !== undefined) as unknown as T[keyof T];
      } else if (typeof value === 'object' && value !== null) {
        (acc as Partial<T>)[key as keyof T] = omitNullAndUndefinedValues(value) as T[keyof T];
      } else {
        (acc as Partial<T>)[key as keyof T] = value;
      }
    }
    return acc;
  }, {} as Partial<T>);
}

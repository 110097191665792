import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { IntercomProvider } from 'react-use-intercom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { queryClient } from '@investown/fe/api-sdk';
import { ErrorBoundary, AnalyticsProvider } from '@investown/fe/common-utils';

import { theme } from 'styles/theme';
import { history, store } from 'appRedux/store';
import AppContainer from 'containers/App/index';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import FeatureFlagsProvider from 'featureFlags/FeatureFlagsProvider';
import ErrorPage from 'components/Errors/ErrorPage';
import analyticsService from 'analytics';
import { getConfigCatKey, getRecaptchaKey } from 'bootstrap';
import { WindowWithIntercom } from 'util/intercomInitialize';
import PageViewsLogger from 'analytics/gtm/PageViewsLogger';
import PathnameLogger from 'util/routerUtils';

const App: FC = () => {
  analyticsService.init();

  return (
    <ErrorBoundary fallbackComponent={<ErrorPage />}>
      <AnalyticsProvider analyticsService={analyticsService}>
        <IntercomProvider appId={(window as WindowWithIntercom).intercomSettings?.app_id} autoBoot>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <FeatureFlagsProvider sdkKey={getConfigCatKey()}>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore fix by updating react-router-dom package */}
                  <Router history={history}>
                    <Switch>
                      <GoogleReCaptchaProvider reCaptchaKey={getRecaptchaKey()}>
                        <PageViewsLogger />
                        <PathnameLogger />
                        <Route path="/" component={AppContainer} />
                      </GoogleReCaptchaProvider>
                    </Switch>
                  </Router>
                </QueryClientProvider>
              </FeatureFlagsProvider>
            </Provider>
          </ThemeProvider>
        </IntercomProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  );
};

export default App;

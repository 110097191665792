import { LocalStorageKeys } from 'constants/LocalStorageKeys';

// Individual
export function getEmail(): string | null {
  return localStorage.getItem(LocalStorageKeys.SignUpEmail);
}

export function setEmail(email: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpEmail, email);
}

export function setFirstName(firstName: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpFirstName, firstName);
}

export function setReferralCode(referralCode: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpReferralCode, referralCode);
}

export function getFirstName(): string | undefined {
  return localStorage.getItem(LocalStorageKeys.SignUpFirstName)!;
}

export function setLastName(lastName: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLastName, lastName);
}

export function getLastName(): string | undefined {
  return localStorage.getItem(LocalStorageKeys.SignUpLastName)!;
}

export function getReferralCode(): string | null {
  return localStorage.getItem(LocalStorageKeys.SignUpReferralCode);
}

// Legal Entity
export function getLegalEntityEmail(): string | null {
  return localStorage.getItem(LocalStorageKeys.SignUpLegalEntityEmail);
}

export function setLegalEntityEmail(email: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLegalEntityEmail, email);
}

export function setLegalEntityIdentificationNumber(identificationNumber: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLegalEntityIdentificationNumber, identificationNumber);
}

export function getLegalEntityIdentificationNumber(): string | undefined {
  return localStorage.getItem(LocalStorageKeys.SignUpLegalEntityIdentificationNumber)!;
}

export function setLegalEntityFirstName(firstName: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLegalEntityFirstName, firstName);
}

export function getLegalEntityFirstName(): string | undefined {
  return localStorage.getItem(LocalStorageKeys.SignUpLegalEntityFirstName)!;
}

export function setLegalEntityLastName(lastName: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLegalEntityLastName, lastName);
}

export function getLegalEntityLastName(): string | undefined {
  return localStorage.getItem(LocalStorageKeys.SignUpLegalEntityLastName)!;
}

export function setLegalEntityReferralCode(referralCode: string): void {
  localStorage.setItem(LocalStorageKeys.SignUpLegalEntityReferralCode, referralCode);
}

export function getLegalEntityReferralCode(): string | null {
  return localStorage.getItem(LocalStorageKeys.SignUpLegalEntityReferralCode);
}

export function resetValues(): void {
  localStorage.removeItem(LocalStorageKeys.SignUpEmail);
  localStorage.removeItem(LocalStorageKeys.SignUpFirstName);
  localStorage.removeItem(LocalStorageKeys.SignUpLastName);
  localStorage.removeItem(LocalStorageKeys.SignUpReferralCode);
  localStorage.removeItem(LocalStorageKeys.SignUpLegalEntityEmail);
  localStorage.removeItem(LocalStorageKeys.SignUpLegalEntityIdentificationNumber);
  localStorage.removeItem(LocalStorageKeys.SignUpLegalEntityLastName);
  localStorage.removeItem(LocalStorageKeys.SignUpLegalEntityFirstName);
  localStorage.removeItem(LocalStorageKeys.SignUpLegalEntityReferralCode);
}

import { apiGet } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { GetUserReferralConditionsApiResponse } from './model';

export async function getUserReferralConditionsApi(): Promise<GetUserReferralConditionsApiResponse> {
  return apiGet<GetUserReferralConditionsApiResponse>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.GetUserReferralConditions,
    false
  );
}

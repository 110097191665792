import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import Big from 'big.js';
import { useFeatureFlag } from 'configcat-react';

import { CoreSDK, isProjectExpired } from '@investown/fe/api-sdk';
import { currencyFormat, percentFormat } from '@investown/fe/common-utils';
import {
  isProjectVip,
  isPropertyPremium,
  percentageAmountAvailableForInvestment,
} from '@investown/fe/ui-utils/properties';

import Lang from 'util/IntlMessages';
import Spacing from 'components/Spacing';
import Paragraph from 'components/Typography/Paragraph';
import ProgressBar from 'components/V2/ProgressBar/ProgressBar';
import Typography from 'components/V2/Typography/Typography';
import withUserDetailsAndLevels, { WithUserDetailsAndLevelsProps } from 'util/withUserDetailsAndLevels';
import RatingLabel from 'components/V2/RatingLabel/RatingLabel';
import Tooltip from 'components/V2/Tooltip/Tooltip';
import VipPill from 'components/V2/VipPill/VipPill';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import PerAnnumBonusYield from 'components/V2/PerAnnum/PerAnnumBonusYield';
import Spacer from 'components/V2/Spacer/Spacer';
import InformationCircle from 'components/V2/InformationCircle/InformationCircle';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';

type Props = {
  investmentRound: CoreSDK.PropertyQuery['property']['investmentRound'];
  propertyName: string;
} & WithUserDetailsAndLevelsProps;

const InvestmentDetailRoundInfo: React.FC<Props> = (props) => {
  const intl: IntlShape = useIntl();
  const { investmentRound, propertyName } = props;

  const { investmentAmountAvailable, investmentCurrency, investmentAmountTotal, endPercentageBonus, adjustedYield } =
    investmentRound;

  const preciseAvailableForInvestmentPercentage = percentageAmountAvailableForInvestment({
    investmentAmountAvailable,
    investmentAmountTotal,
    fraction: 2,
  });

  const availableForInvestmentPercentage = percentageAmountAvailableForInvestment({
    investmentAmountAvailable,
    investmentAmountTotal,
    fraction: 0,
  });

  const availableForInvestmentPercentageBig = new Big(availableForInvestmentPercentage);
  const availableForInvestmentPercent = new Big(100).minus(availableForInvestmentPercentageBig).div(100);

  const isExpired = isProjectExpired(investmentRound.status);

  const { value: showYieldRangeEnabled, loading: showYieldRangeLoading } = useFeatureFlag(
    FeatureFlags.ShowYieldRangeWeb,
    FallbackFeatureFlagValues[FeatureFlags.ShowYieldRangeWeb]
  );

  const showYieldRangeLoadedAndEnabled = !showYieldRangeLoading && showYieldRangeEnabled;

  return (
    <>
      <Spacer height="extraLarge" />
      <Spacing $size="extraLarge">
        <FlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.FlexStart}>
          <StyledFlexRow
            marginTop="4px"
            alignHorizontal={AlignDirections.FlexStart}
            alignVertical={AlignDirections.Baseline}
          >
            <Tooltip
              isDisabled={showYieldRangeLoadedAndEnabled}
              textComponent={<Lang id="widgets.investmentRoundInfo.annualPercentageYield.tooltip" />}
            >
              {showYieldRangeLoadedAndEnabled ? (
                <PerAnnumBonusYield
                  bonusYieldEligible={investmentRound.bonusYieldEligible}
                  annualPercentageYield={investmentRound.annualPercentageYield}
                  layout="column"
                  valueFont="labelLGSemiBold"
                  valueColor="strong"
                  suffixFont="labelXSRegular"
                  suffixColor="subtle"
                  prefixFont="labelLGRegular"
                  strikeThroughPrefixFont="bodyXSRegular"
                  strikeThroughFont="labelXSSemiBold"
                  strikeThroughSuffixFont="bodyXXSRegular"
                  isWithYieldRangeShortText={false}
                />
              ) : (
                <PerAnnumBonusYield
                  bonusYieldEligible={investmentRound.bonusYieldEligible}
                  annualPercentageYield={investmentRound.annualPercentageYield}
                  layout="row"
                  valueFont="bodyLGSemiBold"
                  valueColor="strong"
                  suffixFont="labelSMRegular"
                  suffixColor="subtle"
                />
              )}
            </Tooltip>
          </StyledFlexRow>
          <PillsWrapper>
            {isProjectVip(investmentRound) && (
              <>
                <Tooltip textComponent={<Lang id="property.vipPill.tooltip" />}>
                  <VipPill size="small" />
                </Tooltip>
                <Spacer width="8" />
              </>
            )}
            <RatingLabel riskCategory={investmentRound.riskCategory} size="small" />
          </PillsWrapper>
        </FlexRow>
        <Spacer height="large" />
        <Typography variant="labelLGRegular" color="strong">
          {propertyName}
        </Typography>
        {isPropertyPremium(investmentRound) && endPercentageBonus > 0 && (
          <>
            <Spacer height="large" />
            <Row>
              <InvestmentValueDescription>
                <Lang id="widgets.investmentRoundInfo.adjustedYield" />
                <InformationCircle title={<Lang id="widgets.investmentRoundInfo.adjustedYield.tooltip" />} />
              </InvestmentValueDescription>
              <Paragraph fontWeight="medium" color="primary" fontSize="small">
                {percentFormat({ input: adjustedYield, locale: intl.locale })} <Lang id="global.perAnnum" />
              </Paragraph>
            </Row>
          </>
        )}
        <Spacer height="huge" />
        <ProgressBar percent={100 - preciseAvailableForInvestmentPercentage} />
        <Spacer height="medium" />
        <AfterProgressBarInfo>
          {investmentAmountAvailable > 0 && !isExpired && (
            <FlexRow alignHorizontal={AlignDirections.SpaceBetween}>
              <FlexRow alignVertical={AlignDirections.Baseline} alignHorizontal={AlignDirections.FlexStart}>
                <Typography variant="labelSMMedium" color="strong">
                  {percentFormat({
                    input: availableForInvestmentPercent.toString(),
                    locale: intl.locale,
                  })}
                </Typography>
                <Spacer width="small" />
                <Typography variant="labelXSRegular" color="subtle">
                  <Lang id="propertyList.invested" />
                </Typography>
              </FlexRow>
              <FlexRow alignVertical={AlignDirections.Baseline} alignHorizontal={AlignDirections.FlexEnd}>
                <Typography variant="labelXSRegular" color="subtle">
                  <Lang id="propertyList.investmentAmountAvailable" />
                </Typography>
                <Spacer width="small" />
                <Typography variant="labelSMMedium" color="strong">
                  {currencyFormat({
                    input: investmentAmountAvailable,
                    currency: investmentCurrency,
                    locale: intl.locale,
                  })}
                </Typography>
              </FlexRow>
            </FlexRow>
          )}
        </AfterProgressBarInfo>
      </Spacing>
    </>
  );
};

const AfterProgressBarInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.huge};

  .ant-tag {
    margin: 0;
  }
`;

const Row = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

const InvestmentValueDescription = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.textColor};
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.small};
`;

const PillsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -44px;
`;

const StyledFlexRow = styled(FlexRow)<{ marginTop?: string }>`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
`;

export default withUserDetailsAndLevels(InvestmentDetailRoundInfo);

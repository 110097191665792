import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { WalletWithdrawalBody } from './model';

export enum WithdrawMessage {
  SmsCodeRequested = 'sms_code_for_withdrawal_requested',
}

interface WithdrawResponse {
  message: WithdrawMessage.SmsCodeRequested;
  requestId: string;
}

export async function requestWithdrawal({
  bankAccountId,
  amount,
  currency,
  reason,
  note,
}: WalletWithdrawalBody): Promise<void | WithdrawResponse> {
  return apiPost<WithdrawResponse | undefined, WalletWithdrawalBody>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.WithdrawFromWallet,
    { bankAccountId, amount, currency, reason, note },
    true
  );
}

import * as yup from 'yup';

import { maxDecimalDigits } from './yupCustomMethods';

yup.addMethod<yup.NumberSchema>(yup.number, 'maxDecimalDigits', maxDecimalDigits);

export const positiveMonetaryAmountSchema = yup
  .number()
  // Transform commas to dots, e.g. "10,3" to "10.3"
  .transform((_value, originalValue) =>
    typeof originalValue === 'string' ? Number(originalValue.replace(/,/g, '.').replace(/\s/g, '')) : originalValue
  )
  .typeError('common.validation.required')
  .positive('common.validation.positive')
  .maxDecimalDigits(2, 'common.validation.maxDecimalDigits');

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { AnalyticsAction, AnalyticsService, Bloomreach, omitNullAndUndefinedValues } from '@investown/fe/common-utils';

import { ExponeaWebType } from './types';
import { createInvestmentViewPayload } from './bloomreachEvents';

import {
  PATH_AUTOINVEST,
  PATH_AUTOINVEST_CALCULATOR,
  PATH_AUTOINVEST_EDIT_STRATEGY,
  PATH_AUTOINVEST_INTRO,
  PATH_AUTOINVEST_STANDING_ORDER,
  PATH_AUTOINVEST_STRATEGY_CONFIGURATION,
  PATH_AUTOINVEST_SUCCESS,
  PATH_DASHBOARD,
  PATH_FULL_PROPERTIES,
  PATH_INVESTED_PROPERTIES,
  PATH_OPEN_PROPERTIES,
  PATH_PROPERTIES,
  PATH_SECONDARY_MARKET,
  PATH_USER,
  PATH_USER_MEMBERSHIP_LEVELS,
  PATH_USER_NOTIFICATIONS,
  PATH_USER_REFERRAL,
  PATH_WALLET,
} from 'routes/routesPaths';

const exponea = (window as any).exponea as ExponeaWebType;

export const createBloomreachWebAnalyticsService = (): AnalyticsService => {
  let userProperties: Record<string, any> = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isUserIdentified: boolean;

  return {
    init: () => {
      exponea.start();
    },
    setUser(payload) {
      // To set up custom IDs, they need to be defined in the Bloomreach Engagement project
      // (Administration -> Projects -> Your project -> Identifiers)
      // https://documentation.bloomreach.com/engagement/docs/tracking#custom-ids
      const { user_id, email, ...userData } = Bloomreach.createUserIdentifiersPayload(payload);
      exponea.identify(
        { user_id, email: payload.email },
        userData,
        () => {
          isUserIdentified = true;
          if (Object.keys(userProperties).length > 0) {
            exponea.update(userProperties);
            userProperties = {};
          }
        },
        (error) => {
          console.error(`Bloomreach: Error setting user`, error);
        }
      );
    },
    resetUser: () => {
      exponea.anonymize();
      isUserIdentified = false;
    },
    trackScreenView: (payload): void => {
      const path = payload.path || window.location.pathname;
      const screenPathsWhitelist = [
        PATH_DASHBOARD,
        PATH_WALLET,
        PATH_INVESTED_PROPERTIES,
        PATH_USER,
        PATH_USER_NOTIFICATIONS,
        PATH_USER_REFERRAL,
        PATH_AUTOINVEST,
        PATH_AUTOINVEST_CALCULATOR,
        PATH_AUTOINVEST_INTRO,
        PATH_AUTOINVEST_STRATEGY_CONFIGURATION,
        PATH_AUTOINVEST_STANDING_ORDER,
        PATH_AUTOINVEST_SUCCESS,
        PATH_AUTOINVEST_EDIT_STRATEGY,
        PATH_USER_MEMBERSHIP_LEVELS,
        PATH_SECONDARY_MARKET,
        PATH_FULL_PROPERTIES,
        PATH_OPEN_PROPERTIES,
        PATH_PROPERTIES,
      ];

      if (screenPathsWhitelist.includes(path) || path.startsWith('/property/')) {
        exponea.track(
          Bloomreach.Events[AnalyticsAction.SCREEN_VIEW],
          { path },
          undefined,
          getErrorLogger(`Error tracking event ${AnalyticsAction.SCREEN_VIEW}`)
        );
      }
    },
    trackEvent: (action): void => {
      switch (action.type) {
        case AnalyticsAction.INVEST_OVERVIEW: {
          const payload = createInvestmentViewPayload(action);

          exponea.track(
            Bloomreach.Events[AnalyticsAction.INVEST_OVERVIEW],
            payload,
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.INVITE_FRIENDS: {
          const payload = {
            ...Bloomreach.createInviteFriendsPayload(action),
            userDetails: action.payload.userDetails || {},
          };

          exponea.track(
            Bloomreach.Events[AnalyticsAction.INVITE_FRIENDS],
            omitNullAndUndefinedValues(payload),
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.INVEST: {
          exponea.track(
            Bloomreach.Events[AnalyticsAction.INVEST],
            Bloomreach.createInvestmentPayload(action),
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.ONBOARDING_STEP: {
          exponea.track(
            Bloomreach.Events[AnalyticsAction.ONBOARDING_STEP],
            action.payload,
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.FAST_INVESTMENT_INITIALIZATION: {
          exponea.track(
            Bloomreach.Events[AnalyticsAction.FAST_INVESTMENT_INITIALIZATION],
            {},
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        case AnalyticsAction.PROJECT_DETAIL_TAB_OPENED: {
          exponea.track(
            Bloomreach.Events[AnalyticsAction.PROJECT_DETAIL_TAB_OPENED],
            action.payload,
            undefined,
            getErrorLogger(`Error tracking event ${action.type}`)
          );
          break;
        }
        default:
      }
    },
  };
};

function getErrorLogger(message: string): (error: any) => void {
  return function logError(error: any): void {
    console.error(message, error);
  };
}

export const getOperatingSystem = (): string => {
  let os = 'Unknown OS';

  if (navigator.userAgent.includes('Win')) os = 'Windows';
  else if (navigator.userAgent.includes('Mac')) os = 'MacOS';
  else if (navigator.userAgent.includes('Linux')) os = 'Linux';
  else if (navigator.userAgent.includes('Android')) os = 'Android';
  else if (navigator.userAgent.includes('like Mac')) os = 'iOS';

  return os;
};

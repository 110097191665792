import React from 'react';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { IntlShape, useIntl, FormattedPlural } from 'react-intl';

import { CoreSDK } from '@investown/fe/api-sdk';
import {
  PropertyEndedInvestmentRoundStatus,
  getEndedInvestmentRoundStatus,
  getPropertyInvestmentRoundStatusTranslationKey,
} from '@investown/fe/ui-utils/properties';
import { formatDateTime, isMoreThanTwoMonthsBeforeNow } from '@investown/fe/common-utils';

import Lang from 'util/IntlMessages';
import StatusLabel, { StatusLabelColor } from 'components/V2/StatusLabel/StatusLabel';
import TrendingUp from 'components/V2/Icons/StatusLabel/TrendingUp';
import Minus from 'components/V2/Icons/StatusLabel/Minus';
import HouseCheck from 'components/V2/Icons/StatusLabel/HouseCheck';
import Spacer from 'components/V2/Spacer/Spacer';
import Libra from 'components/V2/Icons/StatusLabel/Libra';
import SandWatch from 'components/V2/Icons/StatusLabel/SandWatch';
import Clock from 'components/V2/Icons/StatusLabel/Clock';

export interface StatusLabelModalData {
  title: string;
  description: string;
}

function RoundStatusWithTooltip({
  status,
  oldestUnpaidInstallmentDate,
  oldestUnpaidInstallmentDateWithGracePeriod,
  repaymentStatus,
  investmentTermEnd,
  legacyEndedAt,
  isThin,
  isWithTooltip = false,
  onClick,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  oldestUnpaidInstallmentDate: string;
  oldestUnpaidInstallmentDateWithGracePeriod: string;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  investmentTermEnd?: number | null;
  legacyEndedAt?: number | null;
  isThin?: boolean;
  isWithTooltip?: boolean;
  onClick?: (modalData: StatusLabelModalData) => void;
}) {
  const intl = useIntl();
  const now = new Date();
  const isInvestmentRoundEnded = !!investmentTermEnd && investmentTermEnd < now.getTime();
  const isLegacyEnded = !!legacyEndedAt && legacyEndedAt < now.getTime();
  const investmentRoundEndedStatus = getEndedInvestmentRoundStatus(status, isInvestmentRoundEnded, isLegacyEnded);
  const tagColorWithIcon = getTagColorWithIcon({ status, repaymentStatus, isLegacyEnded });
  let titleId = getPropertyInvestmentRoundStatusTranslationKey({ status, repaymentStatus, isInvestmentRoundEnded });

  if (investmentRoundEndedStatus) {
    titleId = `${titleId}.${investmentRoundEndedStatus}`;
  }
  const tooltipId = `${titleId}.tooltip`;

  if (tagColorWithIcon) {
    return (
      <>
        <StatusLabel
          icon={tagColorWithIcon.icon}
          color={tagColorWithIcon.color}
          isThin={isThin}
          textLeft={<Lang id={titleId} defaultMessage={status} />}
          textRight={repaymentPartOfLabel({
            status,
            repaymentStatus,
            oldestUnpaidInstallmentDate,
            oldestUnpaidInstallmentDateWithGracePeriod,
            investmentRoundEndedStatus,
            intl,
          })}
          description={isWithTooltip && <Lang id={tooltipId} />}
          onClick={() => onClick && onClick({ title: titleId, description: tooltipId })}
        />
        {!isThin && <Spacer height="large" />}
      </>
    );
  }
  return null;
}

function repaymentPartOfLabel({
  status,
  repaymentStatus,
  oldestUnpaidInstallmentDate,
  oldestUnpaidInstallmentDateWithGracePeriod,
  investmentRoundEndedStatus,
  intl,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  oldestUnpaidInstallmentDate?: string;
  oldestUnpaidInstallmentDateWithGracePeriod?: string;
  investmentRoundEndedStatus?: PropertyEndedInvestmentRoundStatus | undefined;
  intl: IntlShape;
}) {
  if (oldestUnpaidInstallmentDate && oldestUnpaidInstallmentDateWithGracePeriod) {
    const oldestUnpaidInstallment = new Date(oldestUnpaidInstallmentDate);
    const oldestUnpaidInstallmentWithGracePeriod = new Date(oldestUnpaidInstallmentDateWithGracePeriod);
    const daysOverdue = differenceInDays(new Date(), oldestUnpaidInstallment);

    if (
      status === CoreSDK.PropertyInvestmentRoundStatus.Ended ||
      status === CoreSDK.PropertyInvestmentRoundStatus.NotFunded ||
      ((status === CoreSDK.PropertyInvestmentRoundStatus.Unknown ||
        status === CoreSDK.PropertyInvestmentRoundStatus.Funded) &&
        investmentRoundEndedStatus === PropertyEndedInvestmentRoundStatus.PaymentOfPrincipal)
    ) {
      return null;
    }

    switch (repaymentStatus) {
      case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection:
      case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed:
        if (isMoreThanTwoMonthsBeforeNow(oldestUnpaidInstallmentWithGracePeriod.getTime())) {
          const months = differenceInMonths(new Date(), oldestUnpaidInstallment);

          return (
            <FormattedPlural
              one={intl.formatMessage({ id: 'property.currentInvestmentRound.monthsOverdue.one' }, { months })}
              two={intl.formatMessage({ id: 'property.currentInvestmentRound.monthsOverdue.two' }, { months })}
              few={intl.formatMessage({ id: 'property.currentInvestmentRound.monthsOverdue.few' }, { months })}
              other={intl.formatMessage({ id: 'property.currentInvestmentRound.monthsOverdue.other' }, { months })}
              value={months}
            />
          );
        }

        return (
          <FormattedPlural
            one={intl.formatMessage({ id: 'property.currentInvestmentRound.daysOverdue.one' }, { days: daysOverdue })}
            two={intl.formatMessage({ id: 'property.currentInvestmentRound.daysOverdue.two' }, { days: daysOverdue })}
            few={intl.formatMessage({ id: 'property.currentInvestmentRound.daysOverdue.few' }, { days: daysOverdue })}
            other={intl.formatMessage(
              { id: 'property.currentInvestmentRound.daysOverdue.other' },
              { days: daysOverdue }
            )}
            value={daysOverdue}
          />
        );
      default:
        return (
          <Lang
            id="property.currentInvestmentRound.nextPayout"
            values={{
              date: formatDateTime({
                date: oldestUnpaidInstallmentWithGracePeriod,
                locale: intl.locale,
              }),
            }}
          />
        );
    }
  }
  return null;
}

function getTagColorWithIcon({
  status,
  repaymentStatus,
  isLegacyEnded,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  isLegacyEnded?: boolean;
}): { icon: React.ReactNode; color: StatusLabelColor } | undefined {
  switch (status) {
    case CoreSDK.PropertyInvestmentRoundStatus.FullyInvested:
      return { icon: <SandWatch color="primary" />, color: 'primary' };
    case CoreSDK.PropertyInvestmentRoundStatus.NotFunded:
      return { icon: <Minus fillColor="transparent" hoverColor="default" />, color: 'default' };
    case CoreSDK.PropertyInvestmentRoundStatus.Ended:
      return { icon: <HouseCheck fillColor="transparent" hoverColor="default" />, color: 'default' };
    case CoreSDK.PropertyInvestmentRoundStatus.Funded:
    case CoreSDK.PropertyInvestmentRoundStatus.Unknown:
      if (isLegacyEnded) {
        return { icon: <HouseCheck fillColor="transparent" hoverColor="default" />, color: 'default' };
      }
      switch (repaymentStatus) {
        case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed:
          return { icon: <Clock color="warning" />, color: 'warning' };
        case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection:
          return { icon: <Libra color="semiWarning" />, color: 'error' };
        default:
          return { icon: <TrendingUp fillColor="transparent" hoverColor="success" />, color: 'success' };
      }
  }
}

export default RoundStatusWithTooltip;

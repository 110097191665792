/* eslint-disable @nx/enforce-module-boundaries */
import { UserTimestampsResponse } from '../../../../api-sdk/src/lib/users/model';

export function isAbilityToBearLossQuestionnaireExpired(userTimestamps: UserTimestampsResponse | undefined): boolean {
  const now = new Date();

  if (!userTimestamps) {
    return true;
  }

  const { endedAt } = userTimestamps.abilityToBearLossQuestionnaire;

  return endedAt === null || (endedAt !== null && new Date(endedAt).getTime() < now.getTime());
}

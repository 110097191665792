import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { currencyFormat, percentFormat } from '@investown/fe/common-utils';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';
import Spacer from 'components/V2/Spacer/Spacer';
import Lang from 'util/IntlMessages';
import InvestmentInfo from 'components/V2/InvestmentInfo/InvestmentInfo';
import InvestmentInfoRow from 'components/V2/InvestmentInfo/InvestmentInfoRow';
import { useInvestmentRoundFees } from 'containers/Property/helpers';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';
import { getExternalURL } from 'constants/ExternalUrl';
import Typography from 'components/V2/Typography/Typography';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import ErrorEmptyStates from 'constants/ErrorEmptyStates';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

interface RevenuesAndFeesModalProps extends ModalProps {
  investmentRoundId: string;
  investmentCurrency: string;
}

function LinkString(chunks: string) {
  return (
    <a data-testid="revenues-and-fees-modal-link" href={getExternalURL('Risk')} target="_blank">
      {chunks}
    </a>
  );
}

const RevenuesAndFeesModal: FC<RevenuesAndFeesModalProps> = ({
  visible,
  header,
  onClose,
  investmentRoundId,
  investmentCurrency,
}) => {
  const intl = useIntl();
  const {
    data: investmentRoundFees,
    isLoading: investmentRoundFeesLoading,
    isError: investmentRoundFeesError,
  } = useInvestmentRoundFees({
    investmentRoundId,
  });

  const { entryFee, monthlyYieldPlatformFee, discountedInterestRate } = investmentRoundFees?.investmentRoundFees || {};
  const { settled, planned, overdue } = investmentRoundFees?.investmentRoundFees.interestSumValues || {};

  let totalInterest: number | undefined;
  let interestPaid: number | undefined;
  let interestRemainsToBePaid: number | undefined;

  if (settled !== undefined && planned !== undefined && overdue !== undefined) {
    totalInterest = settled.value + planned.value + overdue.value;
    interestPaid = settled.value;
    interestRemainsToBePaid = planned.value + overdue.value;
  }

  const showDivider =
    !!entryFee ||
    !!monthlyYieldPlatformFee ||
    !!discountedInterestRate ||
    !!totalInterest ||
    !!interestPaid ||
    !!interestRemainsToBePaid;

  if (investmentRoundFeesLoading) {
    return (
      <Modal header={header} visible={visible} onClose={onClose}>
        <LoadingWidget />
      </Modal>
    );
  }

  if (investmentRoundFeesError) {
    return (
      <Modal header={header} visible={visible} onClose={onClose}>
        <ErrorEmptyState content={ErrorEmptyStates.errorFetchData} />
      </Modal>
    );
  }

  return (
    <Modal header={header} visible={visible} onClose={onClose}>
      <InvestmentInfo>
        {totalInterest !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.totalInterest.title"
              tooltip="property.revenuesAndFees.item.totalInterest.tooltip"
            >
              {currencyFormat({ input: totalInterest, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {interestPaid !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.interestPaid.title"
              tooltip="property.revenuesAndFees.item.interestPaid.tooltip"
            >
              {currencyFormat({ input: interestPaid, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {interestRemainsToBePaid !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.interestRemainsToBePaid.title"
              tooltip="property.revenuesAndFees.item.interestRemainsToBePaid.tooltip"
            >
              {currencyFormat({ input: interestRemainsToBePaid, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {entryFee !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.brokerageFee.title"
              tooltip="property.revenuesAndFees.item.brokerageFee.tooltip"
            >
              {currencyFormat({ input: entryFee, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {monthlyYieldPlatformFee !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.adminFee.title"
              tooltip="property.revenuesAndFees.item.adminFee.tooltip"
            >
              {currencyFormat({ input: monthlyYieldPlatformFee, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {discountedInterestRate !== undefined && (
          <>
            <InvestmentInfoRow
              title="property.revenuesAndFees.item.discountedInterestRate.title"
              tooltip="property.revenuesAndFees.item.discountedInterestRate.tooltip"
            >
              <FlexRow alignVertical={AlignDirections.Center}>
                {percentFormat({
                  input: discountedInterestRate,
                  locale: intl.locale,
                })}
                <Spacer width="small" />
                <Lang id="global.perAnnum" />
              </FlexRow>
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {showDivider && (
          <>
            <DividerHorizontal />
            <Spacer height="regular" />
          </>
        )}

        <DisclaimerWrapper>
          <Typography variant="bodyBASERegular" color="medium">
            <Lang id="property.revenuesAndFees.disclaimer" values={{ a: LinkString }} />
          </Typography>
        </DisclaimerWrapper>
      </InvestmentInfo>
    </Modal>
  );
};

const DisclaimerWrapper = styled.div`
  max-width: 380px;
`;

export default RevenuesAndFeesModal;

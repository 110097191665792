export enum FeatureFlags {
  ShowYieldRangeWeb = 'showYieldRangeWeb',
  ShowSmsConsentWeb = 'showSmsConsentWeb',
  ShowSearchBarWeb = 'showSearchBarWeb',
}

export const FallbackFeatureFlagValues = {
  [FeatureFlags.ShowYieldRangeWeb]: false,
  [FeatureFlags.ShowSmsConsentWeb]: false,
  [FeatureFlags.ShowSearchBarWeb]: false,
};

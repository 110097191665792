import React from 'react';

import EmptyImage from 'assets/images/errors/empty.png';
import { ReactComponent as ErrorSVG } from 'assets/images/errors/error.svg';

const errorEmptyStates = {
  emptyPortfolioList: {
    testID: 'emptyPortfolioList',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.portfolio.title',
    description: 'empty.portfolio.description',
    buttonText: 'empty.portfolio.showProperties',
    titleFont: 'displayXSMedium' as const,
    descriptionFont: 'bodyLGRegular' as const,
    width: 594,
    buttonWidth: 320,
  },
  emptyPortfolioListAndNoMoney: {
    testID: 'emptyPortfolioListAndNoMoney',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.portfolio.title',
    description: 'empty.portfolio.description.noMoney',
    buttonText: 'empty.portfolio.addMoney',
    titleFont: 'displayXSMedium' as const,
    descriptionFont: 'bodyLGRegular' as const,
    width: 594,
    buttonWidth: 320,
  },
  emptyPortfolioListAndMadeInvestment: {
    testID: 'emptyPortfolioListAndMadeInvestment',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.portfolio.title.madeInvestment',
    description: 'empty.portfolio.description',
    buttonText: 'empty.portfolio.showProperties',
    titleFont: 'displayXSMedium' as const,
    descriptionFont: 'bodyLGRegular' as const,
    width: 594,
    buttonWidth: 320,
  },
  emptyPortfolioSection: {
    testID: 'emptyPortfolioSection',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.portfolio.section.title',
    titleFont: 'displayXSMedium' as const,
    width: 400,
  },
  emptyAutoinvestPortfolioSection: {
    testID: 'emptyAutoinvestPortfolioSection',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.portfolio.section.autoinvest.title',
    description: 'empty.portfolio.section.autoinvest.description',
    titleFont: 'displayXSMedium' as const,
    width: 400,
  },
  emptyPortfolioTotalProfit: {
    testID: 'emptyPortfolioTotalProfit',
    title: 'empty.portfolio.totalProfit.title',
    titleFont: 'displayXSMedium' as const,
    description: 'empty.portfolio.totalProfit.description',
    descriptionFont: 'bodyLGRegular' as const,
    descriptionColor: 'medium' as const,
    width: 652,
  },
  emptyPortfolioAverageYield: {
    testID: 'emptyPortfolioAverageYield',
    title: 'empty.portfolio.AverageYield.title',
    titleFont: 'displayXSMedium' as const,
    description: 'empty.portfolio.AverageYield.description',
    descriptionFont: 'bodyLGRegular' as const,
    descriptionColor: 'medium' as const,
    width: 652,
  },
  emptyPropertyList: {
    testID: 'emptyPropertyList',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.propertyList.title',
    description: 'empty.propertyList.description',
  },
  emptyPropertyListWithSearch: {
    testID: 'emptyPropertyListWithSearch',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.propertyListWithSearch.title',
    description: 'empty.propertyListWithSearch.description',
  },
  emptyPropertyActivity: {
    testID: 'emptyPropertyActivity',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.property.activity.title',
    description: 'empty.property.activity.description',
  },
  emptySecondaryBidsList: {
    testID: 'emptySecondaryBidsList',
    title: 'empty.secondaryBidsList.title',
    description: 'empty.secondaryBidsList.description',
  },
  errorFetchData: {
    testID: 'errorFetchData',
    image: React.createElement(ErrorSVG),
    title: 'errors.fetchData.title',
    description: 'errors.fetchData.description',
  },
  errorPropertyDetail: {
    testID: 'errorPropertyDetail',
    title: 'errors.fetchData.title',
    image: React.createElement(ErrorSVG),
    description: 'errors.fetchData.description',
  },
  errorPropertyRow: {
    testID: 'errorPropertyRow',
    description: 'errors.fetchData.description',
  },
  errorOfferInvestmentOnMarketplace: {
    testID: 'errorOfferInvestmentOnMarketplace',
    title: 'errors.offerInvestmentOnMarketplace.title',
    image: React.createElement(ErrorSVG),
    description: 'errors.offerInvestmentOnMarketplace.description',
    titleFont: 'body2XLMedium' as const,
    descriptionFont: 'labelLGRegular' as const,
  },
  errorBidOnMarketplace: {
    testID: 'errorBidOnMarketplace',
    title: 'errors.bidOnMarketplace.title',
    image: React.createElement(ErrorSVG),
    description: 'errors.bidOnMarketplace.description',
    titleFont: 'body2XLMedium' as const,
    descriptionFont: 'labelLGRegular' as const,
  },
  errorMembershipLevels: {
    testID: 'errorMembershipLevels',
    title: 'errors.errorMembershipLevels.title',
    image: React.createElement(ErrorSVG),
    description: 'errors.errorMembershipLevels.description',
  },
  errorLoadingChart: {
    testID: 'errorLoadingChart',
    title: 'global.error.occurred',
    image: React.createElement(ErrorSVG),
    description: 'global.error.tryLater',
  },
  autoinvestConfigError: {
    testID: 'autoinvestConfigError',
    image: React.createElement(ErrorSVG),
    title: 'global.error.occurred',
    description: 'autoinvest.error.description',
    buttonText: 'autoinvest.error.button',
  },
  autoinvestEditStrategyError: {
    testID: 'autoinvestEditStrategyError',
    image: React.createElement(ErrorSVG),
    title: 'global.error.occurred',
    description: 'autoinvest.editStrategy.error.description',
    buttonText: 'autoinvest.error.button',
  },
  autoinvestLoadingStrategyError: {
    testID: 'autoinvestLoadingStrategyError',
    image: React.createElement(ErrorSVG),
    title: 'global.error.occurred',
    description: 'autoinvest.editStrategy.loading.error.description',
    buttonText: 'autoinvest.error.button',
  },
  propertyHasNoRepaymentSchedule: {
    testID: 'propertyHasNoRepaymentSchedule',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.property.repaymentSchedule.title',
    description: 'empty.property.repaymentSchedule.description',
  },
  autoinvestTransactionsError: {
    testID: 'autoinvestTransactionsError',
    image: React.createElement(ErrorSVG),
    title: 'autoinvest.dashboard.transactions.error',
    description: 'autoinvest.dashboard.transactions.error.description',
  },
  autoinvestTransactionsEmpty: {
    testID: 'autoinvestTransactionsEmpty',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'autoinvest.dashboard.transactions.empty',
    description: 'autoinvest.dashboard.transactions.empty.description',
  },
  userHasNotMadeInvestmentSecondaryPropertyList: {
    testID: 'userHasNotMadeInvestmentSecondaryPropertyList',
    image: <img width={350} src={EmptyImage} alt="empty" />,
    title: 'empty.userHasNotMadeInvestmentSecondaryPropertyList.title',
    description: 'empty.userHasNotMadeInvestmentSecondaryPropertyList.description',
    width: 482,
  },
};

export default errorEmptyStates;

export interface FullAddressObject {
  streetAndNumber: string;
  address: string;
  street: string;
  city: string;
  zip: string;
  country: string;
}

export interface AddressSuggestion {
  name: string;
  label: string;
  position: {
    lon: number;
    lat: number;
  };
  bbox: [number, number, number, number];
  type: RegionalType;
  location: string;
  regionalStructure: RegionalStructureItem[];
  zip?: string;
}

export interface RegionalStructureItem {
  name: string;
  type: RegionalType;
  isoCode: string;
}

export enum RegionalType {
  Regional = 'regional',
  Country = 'regional.country',
  Region = 'regional.region',
  Municipality = 'regional.municipality',
  MunicipalityPart = 'regional.municipality_part',
  Street = 'regional.street',
  Address = 'regional.address',
  POI = 'poi',
  Coordinate = 'coordinate',
}

interface Props {
  query: string;
  lang: string;
  mapyCZApiKey: string;
}

export const fetchMapyCzSuggestions = async <T>({ query, lang, mapyCZApiKey }: Props): Promise<T[]> => {
  try {
    const response = await fetch(
      `https://api.mapy.cz/v1/suggest?lang=${lang}&limit=5&type=regional.address&apikey=${mapyCZApiKey}&query=${query}`
    );
    const data = await response.json();

    if (data && data.items && data.items.length) {
      return data.items.map((item: AddressSuggestion) => {
        const addressInfo = item.regionalStructure.reduce(
          (acc, region) => {
            switch (region.type) {
              case RegionalType.Address:
                acc.address = region.name;
                break;
              case RegionalType.Street:
                acc.street = region.name;
                break;
              case RegionalType.Municipality:
                acc.city = region.name;
                break;
              case RegionalType.Country:
                acc.country = region.isoCode;
                break;
            }
            return acc;
          },
          { address: '', street: '', city: '', country: '' }
        );

        if (!addressInfo.street) {
          addressInfo.street = addressInfo.city;
        }

        return {
          label: `${item.name}, ${addressInfo.city}, ${addressInfo.country}`,
          value: item.name,
          data: {
            address: addressInfo.address,
            street: addressInfo.street,
            city: addressInfo.city,
            zip: item.zip || '',
            country: addressInfo.country,
          },
        };
      });
    }
  } catch (error) {
    console.error('Failed to fetch address suggestions from Mapy.cz', error);
  }
  return [];
};

import { differenceInYears, subMonths, format } from 'date-fns';

import { intlLocaleCrossPlatform } from '../string/stringUtils';

export function isUserAdult(usersBirthDate: Date): boolean {
  return calculateAgeFromDate(usersBirthDate) >= 18;
}

export function calculateAgeFromDate(date: Date, currentDate = new Date()): number {
  return differenceInYears(currentDate, date);
}

export function getBirthDateFromBirthNumber(czechBirthNumber: string): Date {
  const year = resolveBirthYear(czechBirthNumber);
  const month = resolveBirthMonth(czechBirthNumber);
  const day = resolveBirthDayInMonth(czechBirthNumber);

  return new Date(year, month - 1, day);
}

export function resolveBirthYear(czechBirthNumber: string): number {
  const yearPart = parseInt(czechBirthNumber.substr(0, 2), 10);
  const bNSuffixLength = czechBirthNumber.length === 9 ? 3 : 4;
  const centuryBaseYear = resolveCenturyBaseYear(yearPart, bNSuffixLength);

  return centuryBaseYear + yearPart;
}

function resolveCenturyBaseYear(yearPart: number, bNSuffixLength: 3 | 4): number {
  /* 53 is boundary year within birth number.
     Together with length of birth number suffix it determines
     the century of the resolved birth date. */
  if (bNSuffixLength === 3) {
    return yearPart > 53 ? 1800 : 1900;
  }
  // birth number suffix is 4 characters long
  return yearPart > 53 ? 1900 : 2000;
}

export function resolveBirthMonth(czechBirthNumber: string): number {
  const femaleMonthIncrement = 50;
  const incrementWhenAllBnsWithinDayExhausted = 20;
  let month = parseInt(czechBirthNumber.substr(2, 2), 10);
  if (month > femaleMonthIncrement) {
    month -= femaleMonthIncrement;
  }
  if (month > incrementWhenAllBnsWithinDayExhausted) {
    month -= incrementWhenAllBnsWithinDayExhausted;
  }
  return month;
}

export function resolveBirthDayInMonth(czechBirthNumber: string): number {
  return parseInt(czechBirthNumber.substr(4, 2), 10);
}

export function dateToDateString(input: Date): string {
  return input.toISOString().split('T')[0];
}

export function getDayDurationInMs(): number {
  return 24 * 60 * 60 * 1000;
}

export function addMissingMonths<T>({
  data,
  requiredLength,
  getMonthNumber,
  getYear,
  createEmptyDataItem,
}: {
  data: T[];
  requiredLength: number;
  getMonthNumber: (item: T) => number;
  getYear: (item: T) => number;
  createEmptyDataItem: (params: { month: number; year: number }) => T;
}): T[] {
  const missingMonthsCount = requiredLength - data.length;
  const firstAvailableMonthData = data[0];
  const missingMonths = Array.from({ length: missingMonthsCount }, (_, i) => {
    const missingDate = subMonths(
      new Date(getYear(firstAvailableMonthData), getMonthNumber(firstAvailableMonthData), 1),
      missingMonthsCount - i
    );

    return createEmptyDataItem({ month: missingDate.getMonth(), year: missingDate.getFullYear() });
  });
  return [...missingMonths, ...data];
}

export enum DateTimeFormatCZ {
  dMyyyy = 'd. M. yyyy',
  dMyyyyHHmm = 'd. M. yyyy HH:mm',
  dM = 'd. M.',
  dMHHmm = 'd. M. HH:mm',
  yyyyMMdd = 'yyyy-MM-dd',
}

export enum DateTimeFormatEN {
  dMyyyy = 'd/M/yyyy',
  dMyyyyHHmm = 'd/M/yyyy HH:mm',
  dM = 'd/M',
  dMHHmm = 'd/M HH:mm',
  yyyyMMdd = 'yyyy-MM-dd',
}

export interface DateTimeFormat {
  date: string | number | Date;
  dateFormat?: DateTimeFormatCZ | string;
  locale: string;
}

const formatMap: Record<DateTimeFormatCZ, DateTimeFormatEN> = {
  [DateTimeFormatCZ.dMyyyy]: DateTimeFormatEN.dMyyyy,
  [DateTimeFormatCZ.dMyyyyHHmm]: DateTimeFormatEN.dMyyyyHHmm,
  [DateTimeFormatCZ.dM]: DateTimeFormatEN.dM,
  [DateTimeFormatCZ.dMHHmm]: DateTimeFormatEN.dMHHmm,
  [DateTimeFormatCZ.yyyyMMdd]: DateTimeFormatEN.yyyyMMdd,
};

export function formatDateTime({ date, dateFormat = DateTimeFormatCZ.dMyyyy, locale }: DateTimeFormat): string {
  let parsedDate: Date;

  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(date)) {
    // If date is in ISO format, remove timezone information
    parsedDate = new Date(date.split('T')[0]);
  } else {
    parsedDate = new Date(date);
  }
  const intlLocale = intlLocaleCrossPlatform(locale);
  const selectedFormat =
    Object.values(DateTimeFormatCZ).includes(dateFormat as DateTimeFormatCZ) && intlLocale === 'en'
      ? formatMap[dateFormat as DateTimeFormatCZ]
      : dateFormat;

  return format(parsedDate, selectedFormat);
}

export function isMoreThanTwoMonthsFromNow(timestamp: number): boolean | undefined {
  return isMoreThanMonthsFromNow({ timestamp, months: 2 });
}

export function isMoreThanTwoMonthsBeforeNow(timestamp: number): boolean | undefined {
  return isMoreThanMonthsFromNow({ timestamp, months: 2, checkIn: 'past' });
}

export function isMoreThanMonthsFromNow({
  timestamp,
  months = 2,
  checkIn = 'future',
}: {
  timestamp: number;
  months?: number;
  checkIn?: 'future' | 'past';
}): boolean | undefined {
  if (typeof timestamp !== 'number' || timestamp <= 0 || !Number.isInteger(timestamp)) {
    console.error('Invalid Unix timestamp format. It should be a positive integer.');
    return;
  }

  const now = new Date();

  if (checkIn === 'past') {
    const pastDate = new Date(now.setMonth(now.getMonth() - months));
    return new Date(timestamp) < pastDate;
  }

  const futureDate = new Date(now.setMonth(now.getMonth() + months));
  return new Date(timestamp) > futureDate;
}

/* eslint-disable @nx/enforce-module-boundaries */
import {
  PropertyInvestmentRoundObjectType,
  SecondaryMarketOffer,
} from '../../../../api-sdk/src/lib/core/__generated__/sdk';

export function getMarketplaceOfferMinInvestmentAmount(
  offerId: string,
  investmentRound: PropertyInvestmentRoundObjectType
): number {
  if (!investmentRound) {
    return 0;
  }
  const marketplaceOffer = getMarketplaceOfferInfo(offerId, investmentRound) || { minInvestment: 1 };

  return marketplaceOffer.minInvestment;
}

export function getMarketplaceOfferInfo(
  offerId: string,
  investmentRound: PropertyInvestmentRoundObjectType
): SecondaryMarketOffer | undefined {
  return investmentRound.activeSecondaryMarketOffers.find((offer) => offer.id === offerId);
}

export function getActiveMarketplaceOffersMinInvestmentAmount(
  activeSecondaryMarketOffers?: SecondaryMarketOffer[]
): number {
  const marketOffers = activeSecondaryMarketOffers || [{ minInvestment: 1 }];
  const marketOffersNonZero = marketOffers.filter((o) => o.minInvestment > 0);
  const minInvestmentAmount =
    marketOffersNonZero.length > 0 ? Math.min(...marketOffersNonZero.map((o) => o.minInvestment)) : 0;

  return minInvestmentAmount;
}

import { createAction } from 'typesafe-actions';

import {
  CLEAR_FAST_INVESTMENT_DATA,
  FAST_INVESTMENT_INITIALIZATION,
  FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION,
  SECONDARY_MARKET_BID_FROM_DASHBOARD_WAS_MADE,
} from './constants';

export const investedAction = createAction('INVESTED')<{
  amount: number;
  currency: string;
  transactionId: string;
  propertySlug: string;
  investedAt: number;
}>();

export const investmentWithdrawnAction = createAction('INVESTMENT_WITHDRAWN')<void>();

export const investmentErrorAction = createAction('INVESTMENT_ERROR')<void>();

export const investmentPurchaseAction = createAction('INVESTMENT_PURCHASE')<{
  amount: number;
  currency: string;
  transactionId: string;
  propertySlug: string;
}>();

export const fastInvestmentInitialization = createAction(FAST_INVESTMENT_INITIALIZATION)<{
  slug: string;
  name: string;
}>();

export const fastSecondaryMarketInvestmentInitialization = createAction(
  FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION
)<{
  investmentRoundId: string;
  isProjectWithOldContract: boolean;
}>();

export const clearFastInvestmentData = createAction(CLEAR_FAST_INVESTMENT_DATA)();
export const secondaryMarketBidFromDashboardWasMade = createAction(SECONDARY_MARKET_BID_FROM_DASHBOARD_WAS_MADE)();

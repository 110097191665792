import * as CryptoJS from 'crypto-js';

import { OnboardingActionPayload, OnboardingStep } from './types';

export const hashSHA256 = (input: string): string => {
  const hash = CryptoJS.SHA256(input);
  return hash.toString(CryptoJS.enc.Hex);
};

export function getPayloadForOnboardingStepAction(onboardingStep: OnboardingStep): OnboardingActionPayload {
  switch (onboardingStep) {
    case OnboardingStep.REGISTRATION_EMAIL:
      return { step_order: 1, step_name: OnboardingStep.REGISTRATION_EMAIL };
    case OnboardingStep.REGISTRATION_CREDENTIALS:
      return { step_order: 2, step_name: OnboardingStep.REGISTRATION_CREDENTIALS };
    case OnboardingStep.REGISTRATION_PASSWORD:
      return { step_order: 3, step_name: OnboardingStep.REGISTRATION_PASSWORD };
    case OnboardingStep.AML_ADDRESS:
      return { step_order: 4, step_name: OnboardingStep.AML_ADDRESS };
    case OnboardingStep.AML_PERSONAL_IDENTIFIER:
      return { step_order: 5, step_name: OnboardingStep.AML_PERSONAL_IDENTIFIER };
    case OnboardingStep.AML_PHONE_NUMBER:
      return { step_order: 6, step_name: OnboardingStep.AML_PHONE_NUMBER };
    case OnboardingStep.AML_PHONE_VERIFICATION:
      return { step_order: 7, step_name: OnboardingStep.AML_PHONE_VERIFICATION };
    case OnboardingStep.AML_MONEY_ORIGIN:
      return { step_order: 8, step_name: OnboardingStep.AML_MONEY_ORIGIN };
    case OnboardingStep.AML_PEP:
      return { step_order: 9, step_name: OnboardingStep.AML_PEP };
    case OnboardingStep.AML_VERIFF:
      return { step_order: 10, step_name: OnboardingStep.AML_VERIFF };
    case OnboardingStep.AML_INVESTOR_QUESTIONNAIRE:
      return {
        step_order: 11,
        step_name: OnboardingStep.AML_INVESTOR_QUESTIONNAIRE,
      };
    case OnboardingStep.AML_INVESTOR_QUESTIONNAIRE_PART_TWO:
      return {
        step_order: 12,
        step_name: OnboardingStep.AML_INVESTOR_QUESTIONNAIRE_PART_TWO,
      };
    case OnboardingStep.ABILITY_TO_BEAR_LOSS_QUESTIONNAIRE:
      return {
        step_order: 13,
        step_name: OnboardingStep.ABILITY_TO_BEAR_LOSS_QUESTIONNAIRE,
      };
  }
}

/* eslint-disable @nx/enforce-module-boundaries */
import { createAction } from 'typesafe-actions';

import {
  AbilityToBearLossQuestionnaireSubmitResponseData,
  InvestorQuestionnaireTypes,
  CoreSDK,
} from '@investown/fe/api-sdk';

import { AnalyticsAction as ANALYTICS } from './analyticsActions';
import {
  ErrorPayload,
  InvestEventPayload,
  InviteFriendsEventPayload,
  RegistrationStepEventPayload,
  LoginEventPayload,
  InvestOverviewPayload,
  ViewItemEventPayload,
  ScreenAppViewEventPayload,
  ProjectDetailTabOpenedEventPayload,
  OnboardingActionPayload,
  SearchEventPayload,
} from './types';

export const signUpStartAction = createAction(ANALYTICS.SIGN_UP_START)();

export const errorAction = createAction(ANALYTICS.ERROR)<ErrorPayload>();
export const inviteFriendsAction = createAction(ANALYTICS.INVITE_FRIENDS)<InviteFriendsEventPayload>();
export const investAction = createAction(ANALYTICS.INVEST)<InvestEventPayload>();
export const refundAction = createAction(ANALYTICS.REFUND)<InvestEventPayload>();
export const investOverviewAction = createAction(ANALYTICS.INVEST_OVERVIEW)<InvestOverviewPayload>();
export const fastInvestmentInitializationAction = createAction(ANALYTICS.FAST_INVESTMENT_INITIALIZATION)();
export const fastSecondaryMarketInvestmentInitializationAction = createAction(
  ANALYTICS.FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION
)();
export const leadAction = createAction(ANALYTICS.LEAD)();
export const loginAction = createAction(ANALYTICS.LOGIN)<LoginEventPayload>();
export const passwordResetAction = createAction(ANALYTICS.PASSWORD_RESET)();
export const projectDetailTabOpenedAction = createAction(
  ANALYTICS.PROJECT_DETAIL_TAB_OPENED
)<ProjectDetailTabOpenedEventPayload>();
export const viewItemAction = createAction(ANALYTICS.VIEW_ITEM)<ViewItemEventPayload>();
export const screenAppViewAction = createAction(ANALYTICS.SCREEN_APP_VIEW)<ScreenAppViewEventPayload>();
export const referralIconClickedAction = createAction(ANALYTICS.REFERRAL_ICON_CLICKED)();

export const onboardingStepAction = createAction(ANALYTICS.ONBOARDING_STEP)<OnboardingActionPayload>();

/** Autoinvest actions */
export const autoinvestStepAction = createAction(ANALYTICS.AUTOINVEST_STEP)<{
  name: string;
  data?: Record<string, unknown>;
}>();
export const autoinvestBankDetailsCopiedAction = createAction(ANALYTICS.AUTOINVEST_BANK_DETAILS_COPIED)();
export const autoinvestActivatedAction = createAction(ANALYTICS.AUTOINVEST_ACTIVATED)<
  CoreSDK.CreateAutoinvestStrategyMutation['createAutoinvestStrategy']['parameters']
>();
export const autoinvestDeactivatedAction = createAction(ANALYTICS.AUTOINVEST_DEACTIVATED)();
export const autoinvestParametersChangedAction = createAction(ANALYTICS.AUTOINVEST_PARAMETERS_CHANGED)<
  CoreSDK.CreateAutoinvestStrategyMutation['createAutoinvestStrategy']['parameters']
>();
export const withdrawFunds = createAction(ANALYTICS.WITHDRAW_FUNDS)<{ amount: number; currency: string }>();
export const searchAction = createAction(ANALYTICS.SEARCH)<SearchEventPayload>();

/** @deprecated section of actions for better cleanup */
/** @deprecated */
export const verificationStartAction = createAction(ANALYTICS.VERIFICATION_START)();
/** @deprecated */
export const verificationFirstPhaseCompleteAction = createAction(ANALYTICS.VERIFICATION_FIRST_PHASE_COMPLETE)();
/** @deprecated */
export const verificationSecondPhaseStartAction = createAction(ANALYTICS.VERIFICATION_SECOND_PHASE_START)();
/** @deprecated */
export const verificationSecondPhaseCompletedAction = createAction(ANALYTICS.VERIFICATION_SECOND_PHASE_COMPLETED)();
/** @deprecated */
export const abilityToBearLossQuestionnaireCompleteAction = createAction(ANALYTICS.ABILITY_TO_BEAR_LOSS_COMPLETE)<
  AbilityToBearLossQuestionnaireSubmitResponseData | { response: 'OK' }
>();
/** @deprecated */
export const registrationStepAction = createAction(ANALYTICS.REGISTRATION_STEP)<RegistrationStepEventPayload>();
/** @deprecated */
export const verificationStepAction = createAction(ANALYTICS.VERIFICATION_STEP)<RegistrationStepEventPayload>();
/** @deprecated */
export const verificationQuestionnaireStartAction = createAction(ANALYTICS.VERIFICATION_QUESTIONNAIRE_START)();
/** @deprecated */
export const verificationQuestionnaireCompleteAction = createAction(ANALYTICS.VERIFICATION_QUESTIONNAIRE_COMPLETE)<{
  suitabilityResult: InvestorQuestionnaireTypes.InvestorQuestionnaireSubmitResult;
}>();
/** @deprecated */
export const abilityToBearLossQuestionnaireStartAction = createAction(ANALYTICS.ABILITY_TO_BEAR_LOSS_START)();

export enum LocalStorageKeys {
  ShowUserVerificationAfterSignup = 'showUserVerificationAfterSignup',
  SignUpEmail = 'signUpEmail',
  SignUpFirstName = 'signUpFirstName',
  SignUpLastName = 'signUpLastName',
  SignUpReferralCode = 'signUpReferralCode',
  SignUpLegalEntityEmail = 'signUpLegalEntityEmail',
  SignUpLegalEntityIdentificationNumber = 'signUpLegalEntityIdentificationNumber',
  SignUpLegalEntityFirstName = 'signUpLegalEntityFirstName',
  SignUpLegalEntityLastName = 'signUpLegalEntityLastName',
  SignUpLegalEntityReferralCode = 'signUpLegalEntityReferralCode',
  MobileScreenClosedTime = 'mobileScreenClosedTime',
}

import { WalletHistoryQuery } from './__generated__/sdk';
import { WalletTransactionType } from './__generated__/types';

export const PORTFOLIO_TRANSACTION_TYPES = [
  WalletTransactionType.InvestmentPrimaryBuy,
  WalletTransactionType.InvestmentSecondaryBuy,
  WalletTransactionType.InvestmentMixedBuy,
  WalletTransactionType.Refund,
  WalletTransactionType.PrincipalReturn,
  WalletTransactionType.WithdrawalFromInvestment,
  WalletTransactionType.PurchaseOfferedInvestment,
  WalletTransactionType.PartialPrincipalReturn,
];

export function getTransactionAbsoluteAmount(transaction: WalletHistoryQuery['items'][0]): number {
  // End of CF round is minus in portfolio
  // Withdrawal from investment is minus in portfolio
  // Refund is minus in portfolio
  if (
    transaction.type === WalletTransactionType.PartialPrincipalReturn ||
    transaction.type === WalletTransactionType.WithdrawalFromInvestment ||
    transaction.type === WalletTransactionType.Refund
  ) {
    return -Math.abs(transaction.amount);
  }

  // Investment is plus in portfolio
  if (
    (transaction.type === WalletTransactionType.InvestmentPrimaryBuy ||
      transaction.type === WalletTransactionType.InvestmentSecondaryBuy ||
      transaction.type === WalletTransactionType.InvestmentMixedBuy) &&
    transaction.amount < 0
  ) {
    return Math.abs(transaction.amount);
  }

  // Sell is minus in portfolio
  if (
    (transaction.type === WalletTransactionType.InvestmentPrimaryBuy ||
      transaction.type === WalletTransactionType.InvestmentSecondaryBuy ||
      transaction.type === WalletTransactionType.InvestmentMixedBuy) &&
    transaction.amount > 0
  ) {
    return -Math.abs(transaction.amount);
  }

  return transaction.amount;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, Reducer } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { DeepReadonly } from 'utility-types';

import Settings from 'appRedux/settings/reducer';
import auth, { AuthState } from 'appRedux/auth/reducer';
import investment, { InvestmentState } from 'appRedux/investment/reducer';

const { routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const rootReducer = (): Reducer<RootState> =>
  combineReducers<RootState>({
    router: routerReducer,
    auth,
    investment,
    settings: Settings,
  });

export type RootState = DeepReadonly<{
  router: any;
  auth: AuthState;
  investment: InvestmentState;
  settings: any; // wieldy legacy
}>;

export default rootReducer;

// valid bank codes -  1.4.2020
export const defaultBankConnections = [
  '0100',
  '0300',
  '0600',
  '0710',
  '0800',
  '2010',
  '2020',
  '2030',
  '2060',
  '2070',
  '2100',
  '2200',
  '2220',
  '2240',
  '2250',
  '2260',
  '2275',
  '2600',
  '2700',
  '3030',
  '3050',
  '3060',
  '3500',
  '4000',
  '4300',
  '5500',
  '5800',
  '6000',
  '6100',
  '6200',
  '6210',
  '6300',
  '6700',
  '6800',
  '7910',
  '7940',
  '7950',
  '7960',
  '7970',
  '7980',
  '7990',
  '8030',
  '8040',
  '8060',
  '8090',
  '8150',
  '8190',
  '8198',
  '8199',
  '8200',
  '8215',
  '8220',
  '8225',
  '8230',
  '8240',
  '8250',
  '8255',
  '8260',
  '8265',
  '8270',
  '8272',
  '8280',
  '8283',
  '8291',
  '8292',
  '8293',
  '8294',
  '8296',
];

export enum RemoteConfigParameter {
  /**
   * Blog url for actions made on delayed project
   */
  DelayedProjectStepsUrl = 'delayed_project_steps_url',
  /**
   * Blog url about how is Investown securing the projects
   */
  ProjectSecurityBlogPostUrl = 'project_security_blog_post_url',
  /**
   * Blog url about how is Investown proceeds with problematic projects
   */
  ProjectCollectionProcessUrl = 'project_collection_process_url',
  /**
   * Latest build which is supported by the backend APIs. If lower build is installed, it should be force-updated.
   */
  LatestSupportedBuild = 'latest_supported_build',
  /**
   * Maintenance mode
   */
  MaintenanceMode = 'maintenance_mode',
  /**
   * Bank connection codes
   */
  BankConnections = 'bank_connections',
  /**
   * Nominal annual interest rate (decimal) E.g. 0.085 for 8,5%
   */
  ProfitCalculatorAnnualRateDecimal = 'profit_calculator_annual_rate_decimal',
  /**
   * Config for show historical performance banner
   */
  HistoricalPerformanceBanner = 'historical_performance_banner',
  /**
   * Config for security info banner
   */
  SecurityInfoBanner = 'security_info_banner',
  /**
   * Config for premium widget
   */
  PremiumLandingPageUrl = 'premium_landing_page_url',
  /**
   * Config for Google places API key
   */
  GooglePlacesApiKey = 'google_places_api_key',
  /**
   * Refetch intervals for specific queries
   */
  RefetchIntervals = 'refetch_intervals',
  /**
   * Settings of the force update screen
   */
  ForceUpdateSettings = 'force_update_settings',
  /**
   * Refetch intervals for specific queries
   */
  SuitabilityQuestionnaireRepeatPeriod = 'suitability_questionnaire_repeat_period',
  /**
   * Config for risk measurement link
   */
  RiskMeasurementUrl = 'risk_measurement_url',
  /**
   * Animation thresh holds on investment bottom sheet
   */
  InvestmentAnimationThresholds = 'investment_animation_thresholds',
  /**
   * Config for premium widget
   */
  MembershipLevelsLandingPageUrl = 'membership_levels_landing_page_url',
  /**
   * Options for money withdrawal questionnaire dropdown
   */
  WithdrawQuestionnaireReasons = 'withdraw_questionnaire_reasons',
  /**
   * Rate used to calculate fee from investment sell on secondary market
   */
  EarlySaleInvestmentFeeRate = 'early_sale_investment_fee_rate',
  /**
   * How to create standing order
   */
  StandingOrderHowToUrl = 'standing_order_how_to_url',
  /**
   * Value is used to calculate monthly value for standing order for Autoinvest
   */
  AverageNewProjectsPerMonth = 'average_new_projects_per_month',
  /**
   * Minimum fee for investment sell on secondary market
   * Be careful whe using, value has no fallback
   */
  EarlySaleInvestmentMinFee = 'early_sale_investment_min_fee',
  /**
   * Investment sell on secondary market valid interval in days
   */
  EarlySaleInvestmentValidIntervalInDays = 'early_sale_investment_valid_interval_in_days',
  /**
   * Number of users on the platform
   */
  NumberOfUsers = 'number_of_users',
  /**
   * Interval of sending money after withdrawal
   */
  WithdrawalMoneySendInterval = 'withdrawal_money_send_interval',
  /**
   * Fee for more withdrawals in 24h
   * Be careful whe using, value has no fallback
   */
  WithdrawalFee = 'withdrawal_fee',
  /**
   * Fees for withdrawal on foreign account
   * Be careful whe using, value has no fallback
   */
  WithdrawalForeignAccountFees = 'withdrawal_foreign_account_fees',
  /**
   * Should show home referral button variant for A/B testing
   */
  ShowHomeReferralButtonVariant = 'show_home_referral_button_variant',
  /**
   * A/B testing
   * Hide the withdraw funds button under context menu next to the Available Resources info
   */
  HideWithdrawFundsButton = 'hide_withdraw_funds_button',
  /**
   * Blog url about Investown membership
   */
  MembershipLevelsLandingPageUrlWithTopMenu = 'membership_levels_landing_page_url_with_top_menu',
  /**
   * Interval timer in which we are refetching user login session using refresh token
   */
  AutoRefreshUserSessionInMiliseconds = 'auto_refresh_user_session_in_miliseconds',
  /**
   * Yield value used for referral copy
   */
  InvestmentYield = 'investment_yield',
}

export const remoteConfigDefaults = {
  [RemoteConfigParameter.LatestSupportedBuild]: 0, // 0 means "not available"
  [RemoteConfigParameter.ForceUpdateSettings]: JSON.stringify({
    latestSupportedBuild: 0,
    customBuildText: {
      cz: '',
      en: '',
    },
  }),
  [RemoteConfigParameter.MaintenanceMode]: false, // means show app correctly,
  [RemoteConfigParameter.BankConnections]: JSON.stringify(defaultBankConnections),
  [RemoteConfigParameter.ProfitCalculatorAnnualRateDecimal]: 0.085,
  [RemoteConfigParameter.HistoricalPerformanceBanner]: JSON.stringify({
    show: true,
    url: {
      cz: 'https://www.investown.cz/vysledky-klientu-app',
      en: 'https://www.investown.cz/vysledky-klientu-app-en',
    },
  }),
  [RemoteConfigParameter.DelayedProjectStepsUrl]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/pozdni-splatka-uroku',
      en: 'https://www.investown.cz/pozdni-splatka-uroku',
    },
  }),
  [RemoteConfigParameter.ProjectSecurityBlogPostUrl]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/post/3-zpusoby-jak-investown-chrani-investory-pro-pripad-ze-partner-prestane-splacet',
      en: 'https://www.investown.cz/post/3-zpusoby-jak-investown-chrani-investory-pro-pripad-ze-partner-prestane-splacet',
    },
  }),
  [RemoteConfigParameter.ProjectCollectionProcessUrl]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/jak-postupujeme-pri-vymahani-problemovych-projektu',
      en: 'https://www.investown.cz/jak-postupujeme-pri-vymahani-problemovych-projektu',
    },
  }),
  [RemoteConfigParameter.SecurityInfoBanner]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/analyza-nemovitosti',
      en: 'https://www.investown.com/investment-security-app',
    },
  }),
  [RemoteConfigParameter.PremiumLandingPageUrl]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/investown-premium',
      en: 'https://www.investown.com/investown-premium',
    },
  }),
  [RemoteConfigParameter.RefetchIntervals]: JSON.stringify({
    homeWallet: 30000,
    homeOpenProperties: 5000,
    homeMarketplaceProperties: 5000,
    homeFullProperties: 5000,
    projectDetailOpenProperty: 5000,
    projectDetailNotOpenProperty: 30000,
    projectDetailMarketplaceOffers: 5000,
  }),
  [RemoteConfigParameter.RiskMeasurementUrl]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/risk',
      en: 'https://www.investown.com/risk',
    },
  }),
  [RemoteConfigParameter.StandingOrderHowToUrl]: JSON.stringify({
    url: {
      cs: 'https://www.investown.cz/jak-zadat-trvaly-prikaz',
      en: 'https://www.investown.com/how-to-set-up-a-standing-orde',
    },
  }),
  [RemoteConfigParameter.InvestmentAnimationThresholds]: JSON.stringify({
    checkOrderStatusAfterMs: 10000,
    backToInvestmentNotStartedAfterMs: 3500,
  }),
  [RemoteConfigParameter.WithdrawQuestionnaireReasons]: JSON.stringify({
    reasons: [
      { id: 'unexpected_expense', key: 'option1' },
      { id: 'planned_expenditure', key: 'option2' },
      { id: 'more_profitable_investments_elsewhere', key: 'option3' },
      { id: 'not_interested_in_current_investment_offer', key: 'option4' },
      { id: 'testing_withdrawal_feature', key: 'option5' },
      { id: 'other', key: 'option6' },
      { id: 'prefer_not_to_say', key: 'option7' },
    ],
  }),
  [RemoteConfigParameter.EarlySaleInvestmentFeeRate]: 0.009,
  [RemoteConfigParameter.AverageNewProjectsPerMonth]: 4,
  // @deprecated use RemoteConfigParameter.InvestmentYield
  [RemoteConfigParameter.EarlySaleInvestmentMinFee]: 0.01,
  [RemoteConfigParameter.EarlySaleInvestmentValidIntervalInDays]: JSON.stringify({
    interval: {
      cs: '1 den',
      en: '1 day',
    },
  }),
  [RemoteConfigParameter.NumberOfUsers]: 105000,
  [RemoteConfigParameter.WithdrawalMoneySendInterval]: JSON.stringify({
    interval: {
      cs: '5 dní',
      en: '5 days',
    },
  }),
  [RemoteConfigParameter.ShowHomeReferralButtonVariant]: false,
  [RemoteConfigParameter.HideWithdrawFundsButton]: false,
  [RemoteConfigParameter.MembershipLevelsLandingPageUrlWithTopMenu]: JSON.stringify({
    url: {
      cz: 'https://www.investown.cz/investown-clenstvi-app-web',
      en: 'https://www.investown.cz/investown-membership-app-web',
    },
  }),
  [RemoteConfigParameter.AutoRefreshUserSessionInMiliseconds]: 60000,
  [RemoteConfigParameter.InvestmentYield]: 0.1021,
};

import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';

type ShowModalArgs = {
  title: string;
  description: ReactNode;
};

export type InfoModalHandle = {
  showInfoModal: (args: ShowModalArgs) => void;
  closeInfoModal: () => void;
};

const InfoModal = forwardRef<InfoModalHandle>((props: Omit<ModalProps, 'visible' | 'onClose'>, ref) => {
  const [visible, setVisible] = useState(false);
  const [modalTtitle, setModalTitle] = useState<string>('');
  const [modalDescription, setModalDescription] = useState<ReactNode>();
  const closeInfoModal = useCallback(() => setVisible(false), []);

  useImperativeHandle(
    ref,
    () => {
      return {
        showInfoModal: (args: ShowModalArgs) => {
          setVisible(true);
          setModalTitle(args.title);
          setModalDescription(args.description);
        },
        closeInfoModal,
      };
    },
    [closeInfoModal]
  );

  return (
    <Modal maxWidth={700} {...props} onClose={closeInfoModal} header={modalTtitle} visible={visible}>
      <DescriptionWrapper>{modalDescription}</DescriptionWrapper>
    </Modal>
  );
});

const DescriptionWrapper = styled.div`
  white-space: pre-line;
`;

export default InfoModal;

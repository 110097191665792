/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import {
  ApiError,
  ApiErrorCode,
  bidIntoSecondaryMarketOffer,
  CoreSDK,
  getProperty,
  getWallet,
  QueryKeys,
} from '@investown/fe/api-sdk';
import {
  currencyFormat,
  deFormattedStringToFloat,
  generateUUID,
  getMarketplaceOfferMinInvestmentAmount,
  getSellerNameForSecondaryMarketOffers,
  LegalDocuments,
  numberWithSpaceSeparator,
  RemoteConfigParameter,
  translateCurrency,
  translateInvestmentError,
} from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import BidPlacedSuccessfully from './SecondaryMarketOffersBidSuccess';

import Lang from 'util/IntlMessages';
import CloseOutlineIcon from 'components/Icons/CloseOutlineIcon';
import Typography from 'components/V2/Typography/Typography';
import AvailableMoney from 'components/V2/AvailableMoney/AvailableMoney';
import Spacer from 'components/V2/Spacer/Spacer';
import {
  enoughAvailableBalanceHF,
  maxDecimalDigitsHF,
  maxInvestmentValueHF,
  minPropertyInvestmentValueHF,
  sanitizedValue,
} from 'constants/ValidationRules';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import TooltipInfo from 'components/V2/Icons/TooltipInfo';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import ChevronBack from 'components/Icons/ChevronBack';
import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import Button from 'components/V2/Button';
import UnstyledButton from 'components/V2/Button/UnstyledButton';
import { handleValueFormating } from 'components/V2/Input/helpers';
import Input from 'components/V2/Input/Input';
import { getJSONValue } from 'util/firebase';
import { getAppLanguageLocalVariable } from 'lngProvider';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';

interface Props {
  investmentRoundId: string;
  secondaryMarketItem: CoreSDK.SecondaryMarketItem;
  onLoading: (value: boolean) => void;
  isInvestDisabled: boolean;
  requestRefetchOnBid: () => void;
  onCloseDetail: () => void;
  onCloseModal: () => void;
  isProjectWithOldContract?: boolean;
}

function LinkString(chunks: string, link: string, testID: string) {
  return (
    <a data-testid={testID} href={link} target="_blank">
      {chunks}
    </a>
  );
}

const SecondaryMarketOffersModalItemDetail: FC<Props> = ({
  investmentRoundId,
  secondaryMarketItem,
  onLoading,
  isInvestDisabled,
  requestRefetchOnBid,
  onCloseDetail,
  onCloseModal,
  isProjectWithOldContract,
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [forcedValue, setForcedValue] = useState<string | undefined>(undefined);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const wallet = useQuery(QueryKeys.Wallet, getWallet, {
    refetchOnMount: 'always',
  });
  const {
    mutate: bidOffer,
    isLoading: bidIsLoading,
    isError: bidIsError,
    isSuccess: bidIsSuccess,
  } = useMutation(bidIntoSecondaryMarketOffer);
  const propertyData = useQuery<CoreSDK.PropertyQuery, Error, CoreSDK.PropertyQuery, [string, { slug: string }]>(
    [QueryKeys.Property, { slug: secondaryMarketItem.property.slug }],
    ({ queryKey: [, variables] }) => getProperty(variables.slug),
    {
      refetchOnMount: 'always',
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '',
    },
  });

  const minBidInvestmentAmount = useMemo(
    () =>
      getMarketplaceOfferMinInvestmentAmount(
        secondaryMarketItem.id,
        propertyData.data?.property?.investmentRound as CoreSDK.PropertyInvestmentRoundObjectType
      ),
    [secondaryMarketItem.id, propertyData.data]
  );

  const { interval: earlySaleInvestmentValidIntervalInDays } = getJSONValue<{ interval: Record<string, string> }>(
    RemoteConfigParameter.EarlySaleInvestmentValidIntervalInDays
  );

  useEffect(() => {
    setValue('amount', String(minBidInvestmentAmount));
    setTimeout(() => {
      setFocus('amount');
    }, 1000);
  }, [setValue, setFocus, minBidInvestmentAmount]);

  const onSubmit = useCallback<SubmitHandler<{ amount: string }>>(
    (data, event) => {
      if (event) {
        event.preventDefault();
      }
      onLoading(true);
      bidOffer(
        {
          projectId: investmentRoundId,
          secondaryMarketOfferId: secondaryMarketItem.id,
          transactionId: generateUUID(),
          bidId: generateUUID(),
          bidAmount: {
            value: deFormattedStringToFloat(getValues('amount'), intl),
            currency: secondaryMarketItem.investmentCurrency as string,
          },
        },
        {
          onSuccess: () => {
            onLoading(false);
            wallet.refetch();
            requestRefetchOnBid();
          },
          onError: (error) => {
            onLoading(false);
            Logger.error('💥', 'Error while bidding on secondary market offer', error);
            if (error instanceof ApiError) {
              if (error.code === ApiErrorCode.AmountNotAvailableError) {
                setApiError(intl.formatMessage({ id: 'errors.investment.AmountNotAvailableError' }));
              } else if (error.code) {
                setApiError(translateInvestmentError(intl, error.code));
              } else {
                setApiError(intl.formatMessage({ id: 'errors.unknown' }));
              }
              requestRefetchOnBid();
            } else {
              console.error(error);
            }
          },
        }
      );
    },
    [
      onLoading,
      bidOffer,
      investmentRoundId,
      secondaryMarketItem.id,
      secondaryMarketItem.investmentCurrency,
      getValues,
      wallet,
      requestRefetchOnBid,
      intl,
    ]
  );

  const hasEnoughFunds = React.useMemo(() => {
    if (wallet.data === undefined) {
      return false;
    }

    return wallet.data.Wallet.availableBalance >= minBidInvestmentAmount;
  }, [minBidInvestmentAmount, wallet.data]);

  const sellerName = getSellerNameForSecondaryMarketOffers(secondaryMarketItem.seller);

  const Header = () => (
    <DetailHeader>
      <HeaderLeft>
        {!bidIsSuccess && (
          <>
            <BackButton onClick={onCloseDetail} data-testid={TestIds.CloseSecondaryMarketBidDetail}>
              <ChevronBack color={theme.colorTokens.icon.medium} width="16px" />
            </BackButton>
            <Spacer width="12" />
          </>
        )}
        <Typography variant="displayXSMedium" color="strong">
          {sellerName}
        </Typography>
      </HeaderLeft>
      <CloseButton onClick={onCloseModal} data-testid={TestIds.CloseModal}>
        <CloseOutlineIcon color={theme.colorTokens.icon.medium} />
      </CloseButton>
    </DetailHeader>
  );

  if (wallet.isLoading || wallet.isIdle || propertyData.isLoading || propertyData.isIdle) {
    return <LoadingSkeleton />;
  }
  if (wallet.isError || bidIsError || apiError || propertyData.isError) {
    return (
      <>
        <Header />
        <ErrorEmptyState content={errorEmptyStates.errorBidOnMarketplace} />
      </>
    );
  }

  const onInvestAll = () => {
    setForcedValue(
      handleValueFormating({
        value: String(
          Math.min(wallet.data.Wallet.availableBalance ?? 0, secondaryMarketItem.investmentAmountAvailable)
        ),
        type: 'number',
        intl,
      })
    );
    setValue(
      'amount',
      handleValueFormating({
        value: String(
          Math.min(wallet.data.Wallet.availableBalance ?? 0, secondaryMarketItem.investmentAmountAvailable)
        ),
        type: 'number',
        intl,
      }) as string
    );
    trigger();
    setFocus('amount');
  };

  return (
    <Wrapper>
      {bidIsSuccess ? (
        <Row>
          <BidPlacedSuccessfully closeModal={onCloseModal} />
        </Row>
      ) : (
        <InvestmentFormHolder onSubmit={handleSubmit(onSubmit)}>
          <Spacer height="massive" />
          <Row alignHorizontal={AlignDirections.SpaceBetween}>
            <LeftSide>
              <ProjectName>
                <Typography variant="labelBASERegular" color="subtle">
                  {secondaryMarketItem.property.name}
                </Typography>
              </ProjectName>
            </LeftSide>
            <RightSide>
              <Typography variant="labelBASEMedium" color="strong">
                {currencyFormat({
                  input: secondaryMarketItem.investmentAmountAvailable,
                  currency: secondaryMarketItem.investmentCurrency,
                  locale: intl.locale,
                })}
              </Typography>
            </RightSide>
          </Row>
          <Spacer height="massive" />
          <Row>
            <Column>
              <StyledAvailableMoney
                fullWidth
                amount={wallet.data.Wallet.availableBalance}
                currency={secondaryMarketItem.investmentCurrency}
                availableBalance={wallet.data.Wallet.availableBalance}
                maxInvestment={secondaryMarketItem.investmentAmountAvailable}
                investBalance={
                  getValues('amount') !== undefined ? deFormattedStringToFloat(getValues('amount'), intl) : undefined
                }
                onClick={onInvestAll}
              />
              <Spacer height="extraLarge" />
              <FormItemWrapper>
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value) {
                          return minPropertyInvestmentValueHF(
                            intl,
                            minBidInvestmentAmount,
                            secondaryMarketItem.investmentCurrency
                          )(0);
                        }
                      },
                      minValue: (value) => {
                        if (sanitizedValue(value)) {
                          return minPropertyInvestmentValueHF(
                            intl,
                            minBidInvestmentAmount,
                            secondaryMarketItem.investmentCurrency
                          )(deFormattedStringToFloat(value, intl));
                        }
                      },
                      availableBalance: (value) => {
                        if (sanitizedValue(value)) {
                          return enoughAvailableBalanceHF({
                            intl,
                            userAvailableBalance: wallet.data.Wallet.availableBalance || 0,
                          })(deFormattedStringToFloat(value, intl));
                        }
                      },
                      maxValue: (value) => {
                        if (sanitizedValue(value)) {
                          return maxInvestmentValueHF({
                            intl,
                            max: secondaryMarketItem.investmentAmountAvailable,
                            limitPerUser: secondaryMarketItem.investmentAmountAvailable,
                            alreadyInvested: 0,
                            currency: secondaryMarketItem.investmentCurrency,
                            errorId: 'property.investmentPopUp.maxInvestmentAmount.secondaryMarketBid',
                          })(deFormattedStringToFloat(value, intl));
                        }
                      },
                      maxDecimalDigits: (value) => {
                        if (sanitizedValue(value)) {
                          return maxDecimalDigitsHF(intl, 2)(deFormattedStringToFloat(value, intl));
                        }
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Input
                        {...field}
                        id="amount"
                        name={intl.formatMessage({ id: 'property.investmentPopUp.amount' })}
                        data-testid={TestIds.AmountToInvest}
                        unit={translateCurrency(intl, secondaryMarketItem.investmentCurrency)}
                        defaultValue={numberWithSpaceSeparator(minBidInvestmentAmount, intl.locale)}
                        forceValue={forcedValue}
                        placeholder="0"
                        type="number"
                        inputMode="decimal"
                        important
                        min={minBidInvestmentAmount}
                        step="any"
                        autoComplete="nope"
                        onChange={(e) => {
                          const formattedValue = handleValueFormating({ value: e.target.value, type: 'number', intl });
                          field.onChange(formattedValue ?? '');
                          if (formattedValue) {
                            setForcedValue(undefined);
                          }
                          trigger();
                        }}
                        hasError={!!fieldState.error}
                        errorText={fieldState.error?.message}
                      />
                    </>
                  )}
                />
              </FormItemWrapper>
              {errors.amount ? <Spacer height="large" /> : <Spacer height="extraLarge" />}
              <InfoLabel
                icon={
                  <TooltipInfo
                    color={theme.colorTokens.icon.brand}
                    hoverColor={theme.colorTokens.icon.brand}
                    fillColor="transparent"
                    width="24px"
                    heightInherit
                  />
                }
                backgroundColor={theme.colorTokens.surface.brandFaded25}
                text={
                  <Typography variant="labelBASERegular" color="strong">
                    <Lang
                      id="secondaryMarketOfferModal.buy.disclaimer"
                      values={{ validInterval: earlySaleInvestmentValidIntervalInDays[getAppLanguageLocalVariable()] }}
                    />
                  </Typography>
                }
              />
              {isProjectWithOldContract && (
                <OldProjectDisclaimer>
                  <Spacer height="extraLarge" />
                  <Typography variant="bodyXSRegular" color="subtle">
                    <Lang
                      id="property.investmentPopUp.contractDisclaimer"
                      values={{
                        a: LinkString,
                        vopLink: (chunks: string) =>
                          LinkString(chunks, LegalDocuments.TermsAndConditions, 'terms-link'),
                        oupLink: (chunks: string) =>
                          LinkString(chunks, LegalDocuments.GeneralLoanConditions, 'loan-terms-link'),
                      }}
                    />
                  </Typography>
                </OldProjectDisclaimer>
              )}
              <Spacer height="huge" />
              <FormItemWrapper>
                <StyledButton
                  data-testid={TestIds.SubmitSecondaryMarketBid}
                  type="submit"
                  color="primary"
                  disabled={
                    bidIsLoading || isInvestDisabled || !hasEnoughFunds || !getValues('amount') || !!errors.amount
                  }
                  loading={bidIsLoading}
                >
                  <Lang id="property.investmentPopUp.confirmButton" />
                </StyledButton>
              </FormItemWrapper>
            </Column>
          </Row>
          <Spacer height="massive" />
        </InvestmentFormHolder>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
`;
const Row = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const Column = styled(FlexColumn)`
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
`;

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.extraLarge} ${({ theme }) => theme.spacing.massive};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.stroke.medium};
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.large};

  span {
    border-radius: 50%;
    background: ${({ theme }) => theme.colorTokens.surface.subtle};
    padding: ${({ theme }) => theme.spacing.medium};
  }
`;

const BackButton = styled(UnstyledButton)`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ProjectName = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledAvailableMoney = styled(AvailableMoney)`
  max-width: 100%;
`;

const InvestmentFormHolder = styled.form`
  width: 100%;
`;

const FormItemWrapper = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const OldProjectDisclaimer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
`;

export default SecondaryMarketOffersModalItemDetail;

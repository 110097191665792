/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { TypographyName } from 'styles/theme';

export interface GroupedOptions {
  [key: string]: {
    groupLabel: string;
    options: DropdownOption[];
  };
}

export interface DropdownOption<T = unknown> {
  value: string | number;
  label: string;
  key?: string;
  data?: T;
}

interface DropdownProps<T = unknown> {
  id: string;
  name: string;
  options: GroupedOptions | DropdownOption<T>[];
  placeholder?: string;
  type: 'primary' | 'subtle' | 'subtle-slim';
  defaultSearchValue?: string;
  defaultValue?: DropdownOption<T>;
  defaultActiveFirstOption?: boolean;
  onChange: (option: DropdownOption<T>) => void;
  isWithArrow?: boolean;
  isAutocomplete?: boolean;
  autocompleteValue?: DropdownOption<T>;
  onSearch?: (search: string) => void;
  hasError?: boolean;
  errorText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  dropdownListMaxHeight?: number;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps<any>>(
  (
    {
      id,
      name,
      options,
      placeholder,
      type,
      defaultSearchValue,
      defaultValue,
      defaultActiveFirstOption = false,
      onChange,
      isWithArrow = true,
      isAutocomplete = false,
      autocompleteValue,
      onSearch,
      hasError = false,
      errorText,
      autoFocus,
      disabled = false,
      dropdownListMaxHeight = 245, // 5 items + vertical paddings
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<DropdownOption<any> | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<GroupedOptions | DropdownOption[]>(options);
    const [defaultSearchQuery, setDefaultSearchQuery] = useState<string | undefined>(undefined);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownHeaderRef = useRef<HTMLDivElement>(null);
    const autocompleteInputRef = useRef<HTMLInputElement>(null);
    const isInitialRender = useRef(true);
    const [dropdownListPosition, setPosition] = useState<{ top: number; left: number; width: number }>({
      top: 0,
      left: 0,
      width: 0,
    });

    const handleToggle = () => {
      if (!disabled) {
        setIsOpen(!isOpen);

        if (!isOpen && isAutocomplete && autocompleteInputRef.current) {
          autocompleteInputRef.current.focus();
        }
      }
    };

    // Dynamic positioning of DropdownList what is rendered via ReactDOM.createPortal to prevent overflow issues.
    const updatePosition = () => {
      if (dropdownHeaderRef.current) {
        const rect = dropdownHeaderRef.current.getBoundingClientRect();
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    useEffect(() => {
      if (isOpen) {
        updatePosition();
      }
    }, [isOpen, filteredOptions, searchQuery]);

    const handleSelect = (option: DropdownOption) => {
      if (!disabled) {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      // Ignore click inside dropdown or DropdownList (rendered in the end of the body)
      if (dropdownRef.current && dropdownRef.current.contains(event.target as Node)) {
        return;
      }

      const dropdownListNode = document.getElementById(`${id}-dropdown-list`);
      if (dropdownListNode && dropdownListNode.contains(event.target as Node)) {
        return;
      }

      setIsOpen(false);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!dropdownRef.current?.contains(event.relatedTarget as Node)) {
        setIsOpen(false);
      }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault();
    };

    const getListTypographyVariant = useCallback(
      (isSelected: boolean): TypographyName => {
        switch (type) {
          case 'subtle-slim':
            return isSelected ? 'bodySMSemiBold' : 'bodySMRegular';
          case 'primary':
          case 'subtle':
          default:
            return isSelected ? 'bodyLGSemiBold' : 'bodyLGRegular';
        }
      },
      [type]
    );

    const renderOptions = (options: DropdownOption[]) =>
      options.map((option, index) => {
        const isSelected = selectedOption?.value === option.value;
        return (
          <DropdownItem
            key={option.key ?? option.value}
            data-testid={`dropdown-${id}-value-${index}`}
            type={type}
            onMouseDown={handleMouseDown}
            onClick={() => handleSelect(option)}
            selected={isSelected}
            disabled={disabled}
          >
            <Typography variant={getListTypographyVariant(isSelected)} color={isSelected ? 'strong' : 'medium'}>
              {option.label}
            </Typography>
          </DropdownItem>
        );
      });

    const renderGroupedOptions = (groupedOptions: GroupedOptions) =>
      Object.entries(groupedOptions).map(([key, group]) => (
        <React.Fragment key={key}>
          <GroupLabel>{group.groupLabel}</GroupLabel>
          {group.options.map((option, index) => {
            const isSelected = selectedOption?.value === option.value;
            return (
              <DropdownItem
                key={option.value}
                data-testid={`dropdown-${id}-value-${key}-${index}`}
                type={type}
                onMouseDown={handleMouseDown}
                onClick={() => handleSelect(option)}
                selected={isSelected}
                disabled={disabled}
              >
                <Typography variant={getListTypographyVariant(isSelected)} color={isSelected ? 'strong' : 'medium'}>
                  {option.label}
                </Typography>
              </DropdownItem>
            );
          })}
        </React.Fragment>
      ));

    useEffect(() => {
      // Set values only on first render
      if (isInitialRender.current) {
        // Set default value if it's provided
        if (!autocompleteValue && defaultValue) {
          setSelectedOption(defaultValue);
        } else if (defaultActiveFirstOption) {
          // Set first item as default is input is of type DropdownOption[]
          if (Array.isArray(options)) {
            if (options.length > 0) {
              setSelectedOption(options[0]);
            }
          } else {
            // Set first item as default is input is of type GroupedOptions
            const firstGroup = Object.values(options)[0];
            if (firstGroup?.options?.length > 0) {
              setSelectedOption(firstGroup.options[0]); // Set first items from first group
            }
          }
        }
        isInitialRender.current = false;
      }
      // Update value according to autocompleteValue
      if (autocompleteValue) {
        setSelectedOption(autocompleteValue);
        setSearchQuery(autocompleteValue.label);
      }
    }, [defaultValue, defaultActiveFirstOption, options, autocompleteValue]);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // handleClickOutside is a stable function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isAutocomplete && autoFocus) {
        handleToggle();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoFocus, isAutocomplete]);

    useEffect(() => {
      setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
      if (defaultSearchValue) {
        setDefaultSearchQuery(defaultSearchValue);
      }
    }, [defaultSearchValue]);

    // Handle input change for autocomplete
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setSearchQuery(newValue);
      setDefaultSearchQuery(undefined);
      if (onSearch) {
        onSearch(newValue);
      }
    };

    return (
      <DropdownContainer
        ref={dropdownRef}
        type={type}
        data-testid={`dropdown-${id}`}
        className={`investown-dropdown ${isOpen ? 'opened' : 'closed'}`}
      >
        <DropdownHeader
          ref={dropdownHeaderRef}
          onClick={handleToggle}
          type={type}
          isOpen={isOpen}
          hasError={hasError}
          isAutocomplete={isAutocomplete}
          disabled={disabled}
        >
          <LeftSide isAutocomplete={isAutocomplete}>
            {type === 'primary' && (
              <>
                <PlaceholderText type={type} isSelected={!!selectedOption} variant="labelSMRegular" color="medium">
                  {name}
                </PlaceholderText>
                <Spacer height="8" />
              </>
            )}
            {isAutocomplete ? (
              <AutocompleteInput
                ref={autocompleteInputRef}
                data-testid={id}
                name={id}
                type="text"
                value={searchQuery || defaultSearchQuery}
                onChange={handleInputChange}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onBlur={handleBlur}
                disabled={disabled}
              />
            ) : (
              <Typography
                variant={type === 'subtle-slim' ? 'bodySMRegular' : 'bodyLGMedium'}
                color={isOpen ? 'disabled' : 'strong'}
              >
                {selectedOption?.label || placeholder}
              </Typography>
            )}
          </LeftSide>
          {isWithArrow && <Arrow isOpen={isOpen} type={type} />}
        </DropdownHeader>
        {isOpen &&
          filteredOptions &&
          ((Array.isArray(filteredOptions) && filteredOptions.length > 0) || // Flat array of options
            (!Array.isArray(filteredOptions) && Object.keys(filteredOptions).length > 0)) && // Grouped options
          ReactDOM.createPortal(
            <DropdownList
              id={`${id}-dropdown-list`}
              type={type}
              dropdownListMaxHeight={dropdownListMaxHeight}
              position={{
                top: dropdownListPosition.top,
                left: dropdownListPosition.left,
                width: dropdownListPosition.width,
              }}
            >
              {Array.isArray(filteredOptions) ? renderOptions(filteredOptions) : renderGroupedOptions(filteredOptions)}
            </DropdownList>,
            document.body
          )}

        {hasError ? (
          <>
            <Spacer height="small" />
            <Typography testID={`${id}-input-error-message`} variant="labelXSRegular" color="error">
              {errorText}
            </Typography>
          </>
        ) : null}
        {name && (
          <input
            type="hidden"
            name={`${name}-value`}
            value={isAutocomplete ? selectedOption?.label ?? '' : selectedOption?.value ?? ''}
            data-testid={`dropdown-${id}-selected-value`}
          />
        )}
      </DropdownContainer>
    );
  }
);

interface StyledProps {
  type: 'primary' | 'subtle' | 'subtle-slim';
  isOpen?: boolean;
  selected?: boolean;
  isSelected?: boolean;
  hasError?: boolean;
  isAutocomplete?: boolean;
  disabled?: boolean;
  dropdownListMaxHeight?: number;
  position?: { top: number; left: number; width: number };
}

const DropdownContainer = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  ${({ type }) => {
    switch (type) {
      case 'primary':
        return css`
          max-height: 186px;
          max-width: 462px;
        `;
      case 'subtle':
        return css`
          max-height: 60px;
          max-width: 400px;
        `;
      case 'subtle-slim':
        return css`
          max-height: 45px;
          max-width: 400px;
        `;
    }
  }}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      background-color: ${theme.colorTokens.surface.subtle};
    `}
`;

const DropdownHeader = styled.div<StyledProps>`
  padding: ${({ theme, type, isAutocomplete }) => {
    switch (type) {
      case 'primary':
        return isAutocomplete ? `14px ${theme.spacing.large}` : `10px ${theme.spacing.extraLarge}`;
      case 'subtle':
        return `15.5px ${theme.spacing.extraLarge}`;
      case 'subtle-slim':
        return '10px';
      default:
        return `10px ${theme.spacing.large}`;
    }
  }};
  ${({ type, isAutocomplete }) =>
    isAutocomplete &&
    type === 'primary' &&
    css`
      max-height: 72px;
    `};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme, type, hasError }) =>
    type === 'primary'
      ? css`
          border: 1px solid ${hasError ? theme.colorTokens.utility.error.strong : theme.colorTokens.stroke.subtle};
          background-color: ${theme.colorTokens.surface.subtle};
        `
      : css`
          border: 1px solid ${hasError ? theme.colorTokens.utility.error.strong : theme.colorTokens.stroke.medium};
          background-color: ${theme.colorTokens.surface.background};
        `};
  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      border-color: ${theme.colorTokens.surface.brand};
      outline: ${theme.colorTokens.surface.brandFaded100} solid 4px;
    `}
  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      background-color: ${theme.colorTokens.surface.subtle};
    `}
`;

const LeftSide = styled.div<{ isAutocomplete: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ isAutocomplete }) =>
    isAutocomplete &&
    css`
      width: 100%;
    `}
`;

const PlaceholderText = styled(Typography)<StyledProps>`
  ${({ isSelected }) =>
    !isSelected &&
    css`
      display: none;
    `}
`;

const DropdownList = styled.ul<StyledProps>`
  list-style: none;
  margin-top: 4px;
  position: absolute;
  width: 100%;
  z-index: 333;
  top: ${({ position }) => position?.top}px;
  left: ${({ position }) => position?.left}px;
  width: ${({ position }) => position?.width}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  max-height: ${({ dropdownListMaxHeight }) => dropdownListMaxHeight}px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.1);
  ${({ type, theme }) =>
    type === 'primary'
      ? css`
          padding: 10px 0;
          background-color: ${({ theme }) => theme.colorTokens.surface.subtle};
        `
      : css`
          padding: 8px 0;
          background-color: ${({ theme }) => theme.colorTokens.surface.background};
          border: 1px solid ${theme.colorTokens.stroke.medium};
        `};
`;

const GroupLabel = styled.div`
  padding: 8px 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorTokens.text.medium};
  background-color: ${({ theme }) => theme.colorTokens.surface.subtle};
`;

const DropdownItem = styled.li<StyledProps>`
  padding: ${({ theme, type }) =>
    type === 'subtle-slim' ? `${theme.spacing.small} 10px` : `${theme.spacing.medium} ${theme.spacing.extraLarge}`};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
  &:hover {
    ${({ theme, type, disabled }) =>
      !disabled &&
      css`
        background-color: ${type === 'primary'
          ? theme.colorTokens.surface.brandFaded100
          : theme.colorTokens.surface.brandFaded50};
      `}
  }
`;

const Arrow = styled.div<StyledProps>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid black;
  transition: transform 0.2s ease;
  margin: 8px;
  transform-origin: center;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const AutocompleteInput = styled.input`
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.typography.bodyBASERegular.size};
  line-height: ${({ theme }) => theme.typography.bodyBASERegular.lineHeight};
  font-weight: ${({ theme }) => theme.typography.bodyBASERegular.weight};
  color: ${({ theme }) => theme.colorTokens.text.strong};
  &::placeholder {
    color: ${({ theme }) => theme.colorTokens.text.subtle};
  }
`;

export default Dropdown;

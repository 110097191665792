import React, { FC, useCallback, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';
import { useFeatureFlag } from 'configcat-react';

import {
  CoreSDK,
  PropertyInvestmentRoundContractVersion,
  queryClient,
  QueryKeys,
  refetchIntervals,
  UserDetailsDTO,
  UserVerificationDataResponse,
  UserVerificationStatus,
} from '@investown/fe/api-sdk';
import { isProjectLegacy, isProjectVip } from '@investown/fe/ui-utils/properties';
import { getNewContractAvailability, isProjectWithContractVersion } from '@investown/fe/common-utils';

import ProgressInfo from '../ProgressInfo/ProgressInfo';
import { useInvestedPropertyRoundRepaymentStatistics } from '../helpers';

import InvestButtonWidget from './InvestButtonWidget';
import SecondaryMarketOffersSummary from './SecondaryMarketOffersSummary';
import SidebarLoadingSkeleton from './SidebarLoadingSkeleton';
import RoundStatusWithTooltip, { StatusLabelModalData } from './RoundStatusWithTooltip';
import MyInvestmentWidget from './MyInvestmentWidget';
import MySecondaryOffersWidget from './MySecondaryOffersWidget';
import VipGateButton from './InvestButtonWidget/VipGateButton';
import ProlongedBanner from './ProlongedBanner';

import Onboarding from 'containers/Widgets/Onboarding';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import ErrorEmptyStates from 'constants/ErrorEmptyStates';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';
import LayoutContainerContent from 'components/V2/LayoutContainerContent/LayoutContainerContent';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import RatingLabel from 'components/V2/RatingLabel/RatingLabel';
import Spacer from 'components/V2/Spacer/Spacer';
import Lang from 'util/IntlMessages';
import InvestmentInfoWidget from 'containers/Widgets/InvestmentInfoWidget/InvestmentInfoWidget';
import useRepaymentSchedule from 'hooks/useRepaymentSchedule';
import Button from 'components/Button';
import Tooltip from 'components/V2/Tooltip/Tooltip';
import PerAnnumBonusYield from 'components/V2/PerAnnum/PerAnnumBonusYield';
import withUserDetailsAndLevels, { WithUserDetailsAndLevelsProps } from 'util/withUserDetailsAndLevels';
import VipPill from 'components/V2/VipPill/VipPill';
import { useTotalUserLevelBonusYield } from 'hooks/useTotalUserLevelBonusYield';
import { OnboardingCallbackFnParams } from 'containers/Widgets/Onboarding/helpers';
import NewContractModal from 'modals/NewContract/NewContractModal';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';
import StatusLabelModal from 'modals/Property/StatusLabelModal/StatusLabelModal';
import InfoModal, { InfoModalHandle } from 'modals/Property/InfoModal/InfoModal';

type InvestmentDetailProps = {
  userDetails: UserDetailsDTO;
  userVerification: UserVerificationDataResponse;
  propertyQueryResult: CoreSDK.PropertyQuery;
  investmentRoundSecondaryMarketInfoQuery: UseQueryResult<CoreSDK.InvestmentRoundSecondaryMarketInfoQuery, Error>;
  onInvestmentButtonClick: () => void;
  onInvestmentOfferDropdownButtonClick: () => void;
  onShowSecondaryOffersButtonClick: () => void;
  onWithdrawFromInvestmentDropdownButtonClick: () => void;
  onRevenuesAndFeesButtonClick: () => void;
  onCancelAllSecondaryMarketOffersClick: () => void;
  shouldRefetchSecondaryMarkerOffers?: boolean;
} & WithUserDetailsAndLevelsProps;

const Sidebar: FC<InvestmentDetailProps> = ({
  userDetails,
  userVerification,
  propertyQueryResult,
  investmentRoundSecondaryMarketInfoQuery,
  onInvestmentButtonClick,
  onInvestmentOfferDropdownButtonClick,
  onShowSecondaryOffersButtonClick,
  onWithdrawFromInvestmentDropdownButtonClick,
  onRevenuesAndFeesButtonClick,
  onCancelAllSecondaryMarketOffersClick,
  shouldRefetchSecondaryMarkerOffers,
  bonusYieldForUser,
  hasVipProjectsAccess,
}) => {
  const infoModalRef = useRef<InfoModalHandle>(null);
  const {
    data: repaymentData,
    isLoading: repaymentIsLoading,
    isError: repaymentIsError,
  } = useRepaymentSchedule(propertyQueryResult.property.investmentRound.id);

  const canHaveRepaymentCalendar =
    propertyQueryResult &&
    [CoreSDK.PropertyInvestmentRoundStatus.Funded, CoreSDK.PropertyInvestmentRoundStatus.Unknown].includes(
      propertyQueryResult.property.investmentRound.status
    );
  const {
    data: repaymentStatistics,
    isError: repaymentStatisticsIsError,
    isLoading: repaymentStatisticsIsLoading,
  } = useInvestedPropertyRoundRepaymentStatistics({
    propertyInvestmentRoundId: propertyQueryResult.property.investmentRound.id,
    isEnabled: canHaveRepaymentCalendar,
  });

  const {
    data: totalUserLevelBonusYieldData,
    isLoading: totalUserLevelBonusYieldIsLoading,
    isError: totalUserLevelBonusYieldIsError,
    isIdle: totalUserLevelBonusYieldIsIdle,
  } = useTotalUserLevelBonusYield(propertyQueryResult.property.investmentRound.id);

  const showNewContractDocumentation = getNewContractAvailability({
    userDetailsData: userDetails.data,
    userVerificationData: userVerification,
  });
  const isProjectWithNewContract = isProjectWithContractVersion(
    propertyQueryResult.property.investmentRound.contractVersionNumber,
    PropertyInvestmentRoundContractVersion.WithConfirmedContract
  );

  const { value: showYieldRangeEnabled, loading: showYieldRangeLoading } = useFeatureFlag(
    FeatureFlags.ShowYieldRangeWeb,
    FallbackFeatureFlagValues[FeatureFlags.ShowYieldRangeWeb]
  );

  const showYieldRangeLoadedAndEnabled = !showYieldRangeLoading && showYieldRangeEnabled;

  const { investmentRound } = propertyQueryResult.property;

  const isProlonged = useCallback((invRound: typeof investmentRound) => {
    return invRound.interestRateChanges != null && invRound.interestRateChanges.length > 0;
  }, []);

  const isLoading = repaymentIsLoading || repaymentStatisticsIsLoading;
  const isError = repaymentIsError || repaymentStatisticsIsError;
  const hasData = userDetails && userVerification && propertyQueryResult && repaymentData;
  const [newContractModalData, setNewContractModalData] = useState<OnboardingCallbackFnParams | undefined>(undefined);
  const [statusLabelModalData, setStatusLabelModalData] = useState<StatusLabelModalData | undefined>(undefined);

  const handleOnboardingCallback = (params: OnboardingCallbackFnParams) => {
    setNewContractModalData(params);
  };

  if (isLoading || totalUserLevelBonusYieldIsLoading) {
    return <SidebarLoadingSkeleton />;
  }
  if (isError || (!isLoading && !hasData) || totalUserLevelBonusYieldIsError || totalUserLevelBonusYieldIsIdle) {
    return <ErrorEmptyState content={ErrorEmptyStates.errorPropertyDetail} />;
  }

  let bonusYieldPaidOutValue: number | undefined;
  if (
    !investmentRound.bonusYieldEligible ||
    ((!totalUserLevelBonusYieldData || totalUserLevelBonusYieldData.TotalUserLevelBonusYield.amount === 0) &&
      !bonusYieldForUser)
  ) {
    bonusYieldPaidOutValue = undefined;
  } else {
    bonusYieldPaidOutValue = totalUserLevelBonusYieldData.TotalUserLevelBonusYield.amount;
  }

  const primaryInvestmentAvailable = (
    investmentRoundObject: Pick<
      CoreSDK.PropertyInvestmentRoundObjectType,
      'type' | 'investmentAmountAvailable' | 'status'
    >
  ): boolean => {
    if (investmentRoundObject.type === CoreSDK.PropertyInvestmentRoundType.Legacy) {
      return investmentRoundObject.investmentAmountAvailable > 0;
    }
    if (investmentRoundObject.type === CoreSDK.PropertyInvestmentRoundType.Crowdfunding) {
      return investmentRoundObject.status === CoreSDK.PropertyInvestmentRoundStatus.Open;
    }

    return false;
  };

  return (
    <>
      <LayoutContainerContent isColumn>
        {/* Yield and Risk Rating */}
        <FlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.FlexStart}>
          <StyledFlexRow
            marginTop="4px"
            alignHorizontal={AlignDirections.FlexStart}
            alignVertical={AlignDirections.Baseline}
          >
            <Tooltip
              isDisabled={showYieldRangeLoadedAndEnabled}
              textComponent={<Lang id="widgets.investmentRoundInfo.annualPercentageYield.tooltip" />}
            >
              {showYieldRangeLoadedAndEnabled ? (
                <PerAnnumBonusYield
                  bonusYieldEligible={investmentRound.bonusYieldEligible}
                  annualPercentageYield={investmentRound.annualPercentageYield}
                  layout="column"
                  valueFont="labelLGSemiBold"
                  valueColor="strong"
                  suffixFont="labelXSRegular"
                  suffixColor="subtle"
                  prefixFont="labelLGRegular"
                  strikeThroughPrefixFont="bodyXSRegular"
                  strikeThroughFont="labelXSSemiBold"
                  strikeThroughSuffixFont="bodyXXSRegular"
                  isWithYieldRangeShortText={false}
                />
              ) : (
                <PerAnnumBonusYield
                  bonusYieldEligible={investmentRound.bonusYieldEligible}
                  annualPercentageYield={investmentRound.annualPercentageYield}
                  layout="row"
                  valueFont="displaySMSemiBold"
                  suffixFont="labelSMRegular"
                />
              )}
            </Tooltip>
          </StyledFlexRow>
          <PillsWrapper marginTop="0">
            {isProjectVip(investmentRound) && (
              <>
                <Tooltip textComponent={<Lang id="property.vipPill.tooltip" />}>
                  <VipPill size="large" />
                </Tooltip>
                <Spacer width="8" />
              </>
            )}
            <RatingLabel riskCategory={investmentRound.riskCategory} />
          </PillsWrapper>
        </FlexRow>
        <MyInvestmentWidget
          status={investmentRound.status}
          type={investmentRound.type}
          investmentTermEnd={investmentRound.investmentTermEnd}
          currentUsersTotalInvestment={investmentRound.currentUsersTotalInvestment}
          investmentCurrency={investmentRound.investmentCurrency}
          currentYield={repaymentStatistics?.investedPropertyRoundRepaymentStatistics.currentYield}
          expectedYield={repaymentStatistics?.investedPropertyRoundRepaymentStatistics.expectedYield}
          onInvestmentOfferDropdownButtonClick={onInvestmentOfferDropdownButtonClick}
          onWithdrawFromInvestmentDropdownButtonClick={onWithdrawFromInvestmentDropdownButtonClick}
          bonusYieldPaidOut={bonusYieldPaidOutValue}
        />
        <MySecondaryOffersWidget
          investmentRoundId={investmentRound.id}
          onCancelAllSecondaryMarketOffersClick={onCancelAllSecondaryMarketOffersClick}
          shouldRefetchSecondaryMarkerOffers={shouldRefetchSecondaryMarkerOffers}
        />
      </LayoutContainerContent>
      <DividerHorizontal color="medium" />
      <LayoutContainerContent isColumn>
        {/* Status label */}
        <RoundStatusWithTooltip
          oldestUnpaidInstallmentDate={repaymentData!.repaymentSchedule.oldestUnpaidInstallmentDate}
          oldestUnpaidInstallmentDateWithGracePeriod={
            repaymentData!.repaymentSchedule.oldestUnpaidInstallmentDateWithGracePeriod
          }
          repaymentStatus={investmentRound.repaymentStatus}
          status={investmentRound.status}
          investmentTermEnd={investmentRound.investmentTermEnd}
          legacyEndedAt={investmentRound.legacyEndedAt}
          isWithTooltip
          onClick={(modalData) => setStatusLabelModalData(modalData)}
        />
        {investmentRound.status === CoreSDK.PropertyInvestmentRoundStatus.Funded && isProlonged(investmentRound) ? (
          <>
            <Spacer height="16" />
            <ProlongedBanner
              interestRates={investmentRound.interestRateChanges}
              onClick={(args) => infoModalRef.current?.showInfoModal(args)}
            />
            <InfoModal ref={infoModalRef} />
          </>
        ) : null}
        <Spacer height="16" />
        <ProgressInfo
          investmentRoundStatus={investmentRound.status}
          investmentAmountAvailable={investmentRound.investmentAmountAvailable}
          investmentAmountTotal={investmentRound.investmentAmountTotal}
          investmentRoundCurrency={investmentRound.investmentCurrency}
          numberOfInvestors={investmentRound.numberOfInvestors}
        />
        {/* Onboarding banner */}
        {userDetails.data && userVerification && (
          <>
            <Onboarding
              userDetails={userDetails.data}
              userVerificationData={userVerification}
              isProjectWithOldContract={!isProjectWithNewContract}
              direction="column"
              isProjectBlockedForUser={!hasVipProjectsAccess && isProjectVip(investmentRound)}
              callbackFn={(params) => handleOnboardingCallback(params)}
            />
          </>
        )}
        {/* Project is VIP but user level has no access VIP projects */}
        {!hasVipProjectsAccess && isProjectVip(investmentRound) ? (
          <VipGateButton />
        ) : (
          <>
            {/* Invest button */}
            <InvestButtonWidget
              userDetails={userDetails.data}
              userVerificationData={userVerification}
              isPrimaryInvestmentAvailable={primaryInvestmentAvailable(investmentRound)}
              minInvestment={investmentRound.minInvestment}
              investmentCurrency={investmentRound.investmentCurrency}
              investmentAmountAvailable={investmentRound.investmentAmountAvailable}
              onInvestmentButtonClick={onInvestmentButtonClick}
              investmentDisabled={
                !userDetails.data || userDetails.data.verificationStatus !== UserVerificationStatus.Verified
              }
              shouldHide={showNewContractDocumentation && isProjectWithNewContract}
            />
            {/* Secondary market button */}
            {(!showNewContractDocumentation || !isProjectWithNewContract) &&
              investmentRound.id &&
              investmentRoundSecondaryMarketInfoQuery.data?.investmentRoundSecondaryMarketInfo &&
              userDetails.data &&
              userDetails.data.verificationStatus === UserVerificationStatus.Verified && (
                <>
                  <Spacer height="huge" />
                  <SecondaryMarketOffersSummary
                    secondaryMarketOffersInfo={
                      investmentRoundSecondaryMarketInfoQuery.data.investmentRoundSecondaryMarketInfo
                    }
                    onShowSecondaryOffersClick={onShowSecondaryOffersButtonClick}
                  />
                </>
              )}
          </>
        )}
      </LayoutContainerContent>
      <DividerHorizontal color="medium" />
      <LayoutContainerContent isColumn>
        <InvestmentInfoWidget property={propertyQueryResult.property as CoreSDK.PropertyObjectType} />
        {!isProjectLegacy(investmentRound.type) && (
          <StyledButton color="link" onClick={onRevenuesAndFeesButtonClick}>
            <Typography variant="labelBASEMedium" color="brand">
              <Lang id="property.revenuesAndFees.button" />
            </Typography>
          </StyledButton>
        )}
      </LayoutContainerContent>
      {newContractModalData && (
        <NewContractModal
          visible={!!newContractModalData}
          inflectedFirstName={newContractModalData.inflectedFirstName}
          isLegalEntity={newContractModalData.isLegalEntity}
          onClose={() => {
            setNewContractModalData(undefined);
            queryClient.invalidateQueries(QueryKeys.UserVerificationData);
          }}
        />
      )}
      {statusLabelModalData && (
        <StatusLabelModal
          visible={!!statusLabelModalData}
          header={statusLabelModalData.title}
          description={statusLabelModalData.description}
          onClose={() => setStatusLabelModalData(undefined)}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  justify-content: flex-start;
  padding: 0;
`;

const PillsWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  margin-top: ${({ theme, marginTop }) => marginTop ?? theme.spacing.small};
  margin-left: -${({ theme }) => theme.spacing.extraLarge};
`;

const StyledFlexRow = styled(FlexRow)<{ marginTop?: string }>`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
`;

export default withUserDetailsAndLevels(Sidebar, {
  refetchOnMount: 'always',
  refetchInterval: refetchIntervals.thirtySeconds,
});

// eslint-disable-next-line @nx/enforce-module-boundaries
import { getAccessToken } from '../../../../common-utils/src/lib/auth';
import { apiPost } from '../api';
import { ApiNames, ApiPath } from '../enums';

import { VerifySMSCodeRequest } from './model';

interface ConfirmCodeResponse {
  isValid: boolean;
  reason: 'expired' | 'invalid' | 'valid';
}

export async function verifySMSCode({ code }: { code: string }): Promise<ConfirmCodeResponse> {
  const accessToken = await getAccessToken();
  return apiPost<ConfirmCodeResponse, VerifySMSCodeRequest>(
    ApiNames.MonorepoApiEndpoint,
    ApiPath.VerifySMSCode,
    { accessToken, code },
    true
  );
}

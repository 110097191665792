/* eslint-disable @nx/enforce-module-boundaries */
import { PayloadAction } from 'typesafe-actions';

import { UserDetailsDTO } from '../../../../../api-sdk/src/lib/users/model';
import { AnalyticsAction } from '../analyticsActions';
import { InvestEventPayload, InviteFriendsEventPayload } from '../types';
import { InvestownActionCreators } from '../analyticsService';

export const Events = {
  [AnalyticsAction.INVEST_OVERVIEW]: 'view_investment',
  [AnalyticsAction.SCREEN_APP_VIEW]: 'screen_app_view',
  [AnalyticsAction.INVITE_FRIENDS]: 'invite_friends',
  [AnalyticsAction.INVEST]: 'invest',
  [AnalyticsAction.SCREEN_VIEW]: 'screen_view',
  [AnalyticsAction.ONBOARDING_STEP]: 'onboarding_step',
  [AnalyticsAction.FAST_INVESTMENT_INITIALIZATION]: 'fastinvest_homepage_btn_ctr',
  [AnalyticsAction.PROJECT_DETAIL_TAB_OPENED]: 'project_detail_tab_opened',
};

export function createUserIdentifiersPayload(userDetails: UserDetailsDTO): Record<string, string> {
  return {
    user_id: userDetails.intercomUserId,
    email: userDetails.email,
    intercomId: userDetails.intercomUserId,
    first_name: userDetails.firstName,
    last_name: userDetails.lastName,
    phone: userDetails.phoneNumber || '',
    customer_type: userDetails.companyId ? 'company' : 'person',
    membership_status: userDetails.level.currentLevel,
  };
}

export function createInvestmentPayload(
  action: PayloadAction<AnalyticsAction.INVEST, InvestEventPayload>
): Record<string, unknown> {
  return {
    value: action.payload.amount,
    currency: action.payload.currency,
    projectInvestmentRoundId: action.payload.project.investmentRound.id,
    projectName: action.payload.project.name,
    amount: action.payload.amount,
  };
}

export function createInviteFriendsPayload(
  event: ReturnType<InvestownActionCreators['inviteFriendsAction']>
): InviteFriendsEventPayload {
  return {
    last_purchase: event.payload.last_purchase,
    average_purchase: event.payload.average_purchase,
    purchases: event.payload.purchases,
    userDetails: event.payload.userDetails,
    resolution: event.payload.resolution,
    language: event.payload.language,
    os: 'web',
  };
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { ForwardedRef, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';

import { intlLocaleCrossPlatform } from '@investown/fe/common-utils';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/Spacer';
import InformationCircle from 'components/V2/InformationCircle/InformationCircle';

const NUMBER_MAX_LENGTH = 16; // formatted number input can have maximum 16 characters

type InputTypes = 'number' | 'password' | 'email' | 'text';
type InputVariant = 'default' | 'simple' | 'search';
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: InputTypes;
  variant?: InputVariant;
  name?: string;
  id: string;
  forceValue?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorText?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  important?: boolean;
  unit?: string;
  tooltipText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  sharpRadius?: 'left' | 'right';
  minWidth?: number;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      variant = 'default',
      name,
      id,
      forceValue,
      defaultValue,
      placeholder,
      disabled = false,
      hasError = false,
      errorText,
      leadingIcon,
      trailingIcon,
      important,
      unit,
      tooltipText,
      sharpRadius,
      minWidth,
      max,
      onChange,
      ...rest
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const intl = useIntl();
    const [formattedNumberValue, setFormattedNumberValue] = useState<string>();
    const unitSpacerId = `${id}-unit-spacer`;

    useEffect(() => {
      handleValueFormating(`${defaultValue}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleUnitWidthMapping = useCallback(
      (value: string) => {
        const unitSpacerHolder = document.getElementById(unitSpacerId);
        if (unitSpacerHolder) unitSpacerHolder.innerHTML = value || placeholder || '0';
      },
      [placeholder, unitSpacerId]
    );

    const handleValueFormating = useCallback(
      (value: string) => {
        if (type === 'number') {
          // If numeric input is empty, show placeholder and reset the value
          if (!value) {
            setFormattedNumberValue('');
            handleUnitWidthMapping(placeholder || '0');
            return;
          }

          // Remove non-numeric characters except for dots and commas
          let numericValue = value.replace(/[^0-9.,]/g, '');

          // Determine if the locale uses a comma as a decimal separator
          const isLocaleUsingComma = intlLocaleCrossPlatform(intl.locale) !== 'en';

          if (isLocaleUsingComma) {
            // For locales using commas as decimal separators (e.g., cs)
            // Replace any dots with commas
            numericValue = numericValue.replace(/\./g, ',');
          } else {
            // For English locale
            // Remove commas used as thousand separators
            numericValue = numericValue.replace(/,/g, '');
          }

          // Split the number into integer and decimal parts based on the current locale
          const [integerPart, decimalPart] = isLocaleUsingComma ? numericValue.split(',') : numericValue.split('.');

          // Format the integer part based on the locale
          const formattedInteger = new Intl.NumberFormat(intl.locale, {
            style: 'decimal',
            useGrouping: true, // Keep thousand separators
          }).format(parseFloat(integerPart));

          // Reconstruct the formatted value, preserving the decimal part with the correct separator
          const decimalSeparator = isLocaleUsingComma ? ',' : '.';
          const formattedValue =
            decimalPart !== undefined ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;

          // Limit to 2 decimal places, if necessary
          const limitedDecimalsValue = decimalPart
            ? formattedValue.slice(0, formattedValue.indexOf(decimalSeparator) + 3)
            : formattedValue;
          // Set the formatted number value
          if (!isNaN(parseFloat(limitedDecimalsValue))) {
            setFormattedNumberValue(limitedDecimalsValue);
            handleUnitWidthMapping(formattedValue);
          } else {
            setFormattedNumberValue('');
            handleUnitWidthMapping(placeholder || '0');
          }
        }
      },
      [handleUnitWidthMapping, intl.locale, type, placeholder]
    );

    useEffect(() => {
      if (forceValue !== undefined) {
        handleValueFormating(forceValue);
      }
    }, [forceValue, handleValueFormating]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      // Format the value with unit
      handleValueFormating(value || '');

      // Callback
      onChange?.(e);
    };

    return (
      <>
        <InputContainer
          $variant={variant}
          $disabled={disabled}
          $hasError={hasError}
          $important={important}
          $sharpRadius={sharpRadius}
          $minWidth={minWidth}
        >
          {leadingIcon ? (
            <>
              <LeadingTrailingIcon>{leadingIcon}</LeadingTrailingIcon>
              <Spacer width="regular" />
            </>
          ) : null}
          <InputColumn>
            {variant === 'default' && name ? (
              <LabelRow>
                <label htmlFor={id}>
                  <Typography variant="labelSMRegular" color="medium">
                    {name}
                  </Typography>
                </label>
                {tooltipText ? (
                  <>
                    <Spacer width="small" />
                    <InformationCircle title={tooltipText} placement="left" />
                  </>
                ) : null}
              </LabelRow>
            ) : null}
            <InputRow>
              <input
                data-testid={`${id}-input`}
                id={id}
                type={type === 'number' ? 'text' : type}
                name={id}
                inputMode={type === 'number' ? 'decimal' : 'text'}
                placeholder={placeholder}
                disabled={disabled}
                value={type === 'number' ? formattedNumberValue : undefined}
                ref={ref}
                maxLength={type === 'number' ? NUMBER_MAX_LENGTH : undefined}
                autoComplete={type === 'number' ? 'off' : undefined} // Disable autocomplete for number inputs
                {...rest}
                onInput={(e) => handleValueFormating(e.currentTarget.value)}
                onChange={handleChange}
              />
              <UnitHolder className="unit-holder">
                {unit ? (
                  <>
                    <UnitSpacer id={unitSpacerId}>{placeholder}</UnitSpacer>
                    <Spacer width="small" />
                    <Unit>{unit}</Unit>
                  </>
                ) : null}
              </UnitHolder>
            </InputRow>
          </InputColumn>
          {trailingIcon ? (
            <>
              <Spacer width="medium" />
              <LeadingTrailingIcon>{trailingIcon}</LeadingTrailingIcon>
            </>
          ) : null}
        </InputContainer>
        {hasError && errorText ? (
          <>
            <Spacer id={`${id}-input-error-spacer`} height="small" />
            <Typography testID={`${id}-input-error-message`} variant="labelXSRegular" color="error">
              {errorText}
            </Typography>
          </>
        ) : null}
      </>
    );
  }
);

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  svg {
    width: 16px;
  }
`;

const LeadingTrailingIcon = styled.div`
  display: flex;
  align-items: center;
`;
const InputColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  max-width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const UnitHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  position: absolute;
  z-index: 1;
`;

const UnitSpacer = styled.div`
  visibility: hidden;
  opacity: 0;
`;

const Unit = styled.div`
  display: flex;
  align-items: flex-end;
`;

const InputContainer = styled.div<{
  $variant?: InputVariant;
  $disabled?: boolean;
  $hasError?: boolean;
  $important?: boolean;
  $sharpRadius?: 'left' | 'right';
  $minWidth?: number;
}>`
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth}px;
    `};
  background: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'simple':
        return theme.colorTokens.surface.background;
      default:
        return theme.colorTokens.surface.subtle;
    }
  }};
  padding: ${({ theme, $important }) =>
    $important
      ? `${theme.spacing.large} ${theme.spacing.extraLarge}`
      : `${theme.spacing.regular} ${theme.spacing.large}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  ${({ $sharpRadius }) => {
    switch ($sharpRadius) {
      case 'left':
        return css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `;
      case 'right':
        return css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `;
      default:
        return css``;
    }
  }}
  display: flex;
  flex-direction: row;
  border: ${({ theme, $hasError, $variant }) => {
    if ($variant === 'search') {
      return 'none';
    }
    if ($hasError) {
      return `1px solid ${theme.colorTokens.utility.error.strong}`;
    }
    if ($variant === 'simple') {
      return `1px solid ${theme.colorTokens.surface.medium}`;
    }
    return '1px solid transparent';
  }};

  ${({ theme, $hasError, $variant }) => {
    if ($hasError) {
      return theme.colorTokens.utility.error.strong;
    }
    return $variant === 'simple' ? theme.colorTokens.surface.medium : 'transparent';
  }};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  ${({ $variant }) => {
    switch ($variant) {
      case 'search':
        return css`
          height: 40px;
        `;
      case 'simple':
        return css`
          min-height: 56px;
        `;
      default:
        return css``;
    }
  }}

  ${UnitSpacer} {
    font-size: ${({ theme, $important }) =>
      $important ? theme.typography.body2XLMedium.size : theme.typography.labelBASERegular.size};
    font-weight: ${({ theme, $important }) =>
      $important ? theme.typography.body2XLMedium.weight : theme.typography.labelBASERegular.weight};
    color: ${({ theme }) => theme.colorTokens.text.strong};
  }

  ${Unit} {
    font-size: ${({ theme, $important }) =>
      $important ? theme.typography.bodyLGRegular.size : theme.typography.bodyXSRegular.size};
    font-weight: ${({ theme }) => theme.typography.labelBASERegular.weight};
    line-height: ${({ $important }) => ($important ? '36.8px' : '21.2px')};
    color: ${({ theme, $important }) => ($important ? theme.colorTokens.text.strong : theme.colorTokens.text.medium)};
  }

  &:focus-within {
    ${({ $hasError, $variant, theme }) =>
      !$hasError &&
      $variant !== 'search' &&
      `
    border-color: ${theme.colorTokens.surface.brand};
    outline: ${theme.colorTokens.stroke.focus} solid 4px;
  `}
  }

  input,
  input:focus,
  input:active {
    border: none;
    background: transparent;
    outline: none;
    font-size: ${({ theme, $important }) =>
      $important ? theme.typography.body2XLMedium.size : theme.typography.labelBASERegular.size};
    font-weight: ${({ theme, $important }) =>
      $important ? theme.typography.body2XLMedium.weight : theme.typography.labelBASERegular.weight};
    line-height: ${({ theme, $important }) =>
      $important ? theme.typography.body2XLMedium.lineHeight : theme.typography.labelBASERegular.lineHeight};
    color: ${({ theme, $variant }) =>
      $variant === 'search' ? theme.colorTokens.text.medium : theme.colorTokens.text.strong};
    padding: 0;
    flex: 1;
    position: relative;
    z-index: 2;

    &::placeholder {
      color: ${({ theme, $variant }) =>
        $variant === 'search' ? theme.colorTokens.text.invertedSubtle : theme.colorTokens.text.invertedMedium};
    }
  }

  /* Hidding up/down arrows on number input */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default Input;

import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { RemoteConfigParameter } from '@investown/fe/common-utils';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';
import { getJSONValue } from 'util/firebase';
import { getAppLanguageLocalVariable } from 'lngProvider';

interface RevenuesAndFeesModalProps extends ModalProps {
  description: string;
}

function renderLink(key: 'activity' | 'repaymentSchedule' | 'blog', url?: string) {
  return (chunks: string) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (key !== 'activity' && key !== 'repaymentSchedule') {
        e.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    };

    return (
      <a data-testid={`status-label-modal-link-${key}`} href={url} onClick={handleClick}>
        {chunks}
      </a>
    );
  };
}

const StatusLabelModal: FC<RevenuesAndFeesModalProps> = ({ visible, header, onClose, description }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: header as string });
  const { url: projectCollectionProcessUrl }: { url: Record<string, string> } = getJSONValue(
    RemoteConfigParameter.ProjectCollectionProcessUrl
  );

  const projectCollectionProcessLink = projectCollectionProcessUrl[getAppLanguageLocalVariable()];

  return (
    <Modal maxWidth={700} header={title} visible={visible} onClose={onClose}>
      <DescriptionWrapper>
        <Typography variant="bodyBASERegular" color="medium">
          <Lang
            id={description}
            values={{
              activity: renderLink('activity', '#activity'),
              repaymentSchedule: renderLink('repaymentSchedule', '#repayment-schedule'),
              blog: renderLink('blog', projectCollectionProcessLink),
            }}
          />
        </Typography>
      </DescriptionWrapper>
    </Modal>
  );
};

const DescriptionWrapper = styled.div`
  white-space: pre-line;
`;

export default StatusLabelModal;

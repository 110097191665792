import React, { FC, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useQuery } from 'react-query';

import { TestIds } from '@investown/fe/test-utils/testIds';
import { CoreSDK, getLatestAutoinvestStrategy, QueryKeys, queryStaleTimes } from '@investown/fe/api-sdk';

import { MenuButton } from '../components/MenuButton';
import { fillColor, textColor } from '../../utils';
import AutoinvestGuideModal from '../AutoinvestGuideModal/AutoinvestGuideModal';

import {
  PATH_AUTOINVEST,
  PATH_AUTOINVEST_EDIT_STRATEGY,
  PATH_AUTOINVEST_INTRO,
  PATH_AUTOINVEST_STANDING_ORDER,
  PATH_AUTOINVEST_STRATEGY_CONFIGURATION,
  PATH_AUTOINVEST_SUCCESS,
} from 'routes/routesPaths';
import AutoinvestIcon from 'components/V2/Icons/Autoinvest';
import AutoinvestEnabledIcon from 'components/V2/Icons/AutoinvestEnabled';
import AutoinvestDisabledIcon from 'components/V2/Icons/AutoinvestDisabled';

const AutoinvestMenuIcon: FC = () => {
  const { pathname } = useLocation();
  const isActive = isAutoinvestActive(pathname);

  const autoinvestStrategy = useQuery(QueryKeys.LatestAutoinvestStrategy, getLatestAutoinvestStrategy, {
    staleTime: queryStaleTimes.fiveMinutes,
  });

  const getCorrectAutoinvestUrl = useCallback(() => {
    if (autoinvestStrategy.data?.getLatestAutoinvestStrategy == null) {
      return PATH_AUTOINVEST_INTRO;
    }
    return PATH_AUTOINVEST;
  }, [autoinvestStrategy.data?.getLatestAutoinvestStrategy]);

  return (
    <AutoinvestContainer>
      <AutoinvestButton to={getCorrectAutoinvestUrl()} $active={isActive} data-testid={TestIds.Autoinvest}>
        <AutoinvestActivityIndicator
          latestAutoinvestStrategy={autoinvestStrategy.data?.getLatestAutoinvestStrategy}
          isActive={isActive}
        />
      </AutoinvestButton>
      <AutoinvestGuideModal />
    </AutoinvestContainer>
  );
};

function AutoinvestActivityIndicator({
  latestAutoinvestStrategy,
  isActive,
}: {
  latestAutoinvestStrategy?: CoreSDK.AutoinvestStrategy | null;
  isActive: boolean;
}) {
  const theme = useContext(ThemeContext);

  if (!latestAutoinvestStrategy) {
    return <AutoinvestIcon fillColor={fillColor(isActive, theme)} color={textColor(isActive, theme)} />;
  }

  const { active } = latestAutoinvestStrategy;

  if (active) {
    return <StyledAutoinvestEnabledIcon fillColor={fillColor(isActive, theme)} color={textColor(isActive, theme)} />;
  }

  return <StyledAutoinvestDisabledIcon fillColor={fillColor(isActive, theme)} color={textColor(isActive, theme)} />;
}

function isAutoinvestActive(pathname: string) {
  return [
    PATH_AUTOINVEST,
    PATH_AUTOINVEST_INTRO,
    PATH_AUTOINVEST_STRATEGY_CONFIGURATION,
    PATH_AUTOINVEST_STANDING_ORDER,
    PATH_AUTOINVEST_SUCCESS,
    PATH_AUTOINVEST_EDIT_STRATEGY,
  ].includes(pathname);
}

const AutoinvestButton = styled(MenuButton)`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  height: 100%;
  position: relative;

  &:hover * {
    g > path {
      stroke: ${({ theme }) => theme.colorTokens.stroke.white};
    }
  }
`;

const AutoinvestContainer = styled.div`
  position: relative;
`;

const StyledAutoinvestEnabledIcon = styled(AutoinvestEnabledIcon)`
  g > path {
    fill: #41ce99;
    stroke: ${({ theme }) => theme.colorTokens.stroke.white};
  }
`;

const StyledAutoinvestDisabledIcon = styled(AutoinvestDisabledIcon)`
  g > path {
    fill: ${({ theme }) => theme.colorTokens.stroke.white};
    stroke: none !important;
  }
`;

export default AutoinvestMenuIcon;

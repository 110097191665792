// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CoreSDK from '../../../../../api-sdk/src/lib/core/__generated__/sdk';

import { IconName } from './icons';

export function getTransactionIcon({
  type,
  amount,
  reversed,
  isAutoinvest,
}: Pick<CoreSDK.WalletTransactionObjectType, 'type' | 'amount' | 'isAutoinvest'> & { reversed?: boolean }): IconName {
  switch (type) {
    case CoreSDK.WalletTransactionType.EarlySaleInvestmentFee:
    case CoreSDK.WalletTransactionType.WalletWithdrawalFee:
      return 'bill';
    case CoreSDK.WalletTransactionType.InvestmentPrimaryBuy: {
      if (isAutoinvest) {
        return 'autoinvest';
      }
      if (reversed) {
        return amount < 0 ? 'house-out' : 'house-plus';
      }
      return amount < 0 ? 'house-plus' : 'house-out';
    }
    case CoreSDK.WalletTransactionType.InvestmentMixedBuy:
    case CoreSDK.WalletTransactionType.InvestmentSecondaryBuy:
      if (reversed) {
        return amount < 0 ? 'house-out' : 'house-plus';
      }
      return amount < 0 ? 'house-plus' : 'house-out';

    case CoreSDK.WalletTransactionType.WalletDeposit:
    case CoreSDK.WalletTransactionType.InheritanceTransfer:
      return 'add';
    case CoreSDK.WalletTransactionType.WalletWithdrawal:
      return 'download';
    case CoreSDK.WalletTransactionType.LoanInterestPaymentUserIncome:
    case CoreSDK.WalletTransactionType.PropertyRentUserIncome:
    case CoreSDK.WalletTransactionType.PremiumBonusUserIncome:
    case CoreSDK.WalletTransactionType.LateInterestFeePaymentUserIncome:
      return 'trending-up';
    case CoreSDK.WalletTransactionType.PurchaseOfferedInvestment:
    case CoreSDK.WalletTransactionType.BidBlockingSecondaryMarket:
      return 'house-out';
    case CoreSDK.WalletTransactionType.WithdrawalFromInvestment:
    case CoreSDK.WalletTransactionType.BidBlockingSecondaryMarketRevert:
      return 'house-close';
    case CoreSDK.WalletTransactionType.Refund:
    case CoreSDK.WalletTransactionType.PrincipalReturn:
    case CoreSDK.WalletTransactionType.PartialPrincipalReturn:
      return 'revert';
    case CoreSDK.WalletTransactionType.ReferralBonusForRefereeUserIncome:
    case CoreSDK.WalletTransactionType.ReferralBonusForReferrerUserIncome:
    case CoreSDK.WalletTransactionType.ReactivationBonusUserIncome:
      return 'gift';
    case CoreSDK.WalletTransactionType.LevelBonusYieldUserIncome:
      return 'diamond';
    default:
      return 'euro';
  }
}

import { ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import {
  CLEAR_FAST_INVESTMENT_DATA,
  FAST_INVESTMENT_INITIALIZATION,
  FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION,
} from './constants';

import * as actions from 'appRedux/investment/actions';

export type InvestmentAction = ActionType<typeof actions>;

export interface FastInvestmentState {
  slug: string;
  name: string;
}

export interface FastSecondaryMarketInvestmentState {
  investmentRoundId: string;
  isProjectWithOldContract: boolean;
}

export interface InvestmentState {
  fastInvestment?: FastInvestmentState;
  fastSecondaryMarketInvestment?: FastSecondaryMarketInvestmentState;
}

const initialState: InvestmentState = {
  fastInvestment: undefined,
  fastSecondaryMarketInvestment: undefined,
};

const reducer: Reducer<InvestmentState, InvestmentAction> = (state = initialState, action) => {
  switch (action.type) {
    case FAST_INVESTMENT_INITIALIZATION:
      return {
        ...state,
        fastInvestment: action.payload,
      };

    case FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION:
      return {
        ...state,
        fastSecondaryMarketInvestment: action.payload,
      };

    case CLEAR_FAST_INVESTMENT_DATA:
      return {
        ...state,
        fastInvestment: undefined,
        fastSecondaryMarketInvestment: undefined,
      };

    default:
      return state;
  }
};

export default reducer;

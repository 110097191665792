import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type JustifyContentType =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
interface LayoutProps {
  children: ReactNode;
  justifyContent?: JustifyContentType;
  minWidth?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, justifyContent, minWidth }) => (
  <FullWidthLayout justifyContent={justifyContent} minWidth={minWidth} className="layout">
    {children}
  </FullWidthLayout>
);

const FullWidthLayout = styled.div<{ justifyContent?: JustifyContentType; minWidth?: string }>`
  display: flex;
  width: 100%;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: flex-start;
`;

export default Layout;

import { all, call, fork, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getUserDetails, queryClient, QueryKeys, UserDetailsDTO, UserVerificationStatus } from '@investown/fe/api-sdk';
import {
  createUserStatusGtmEventObject,
  googleTagManager,
  LocaleType,
  MfaStatusQueryKey,
  transformLangFromIETFLanguageTag,
} from '@investown/fe/common-utils';

import {
  AUTH_MFA_DISABLED_SUCCESS,
  AUTH_MFA_ENABLED_SUCCESS,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT_SUCCESS,
} from 'appRedux/auth/constants';
import * as authStorage from 'auth/authStorage';
import * as veriffUtils from 'components/VeriffOpenButton/utils';
import { getAppLanguageLocalVariable } from 'lngProvider';
import { switchLanguage } from 'appRedux/settings/actions';

export function* propagateUserDataToGtm(): SagaIterator {
  try {
    const userDetails: UserDetailsDTO = yield call(
      [queryClient, queryClient.fetchQuery],
      QueryKeys.UserDetails,
      getUserDetails
    );
    if (userDetails) {
      googleTagManager(
        createUserStatusGtmEventObject({
          userId: userDetails.intercomUserId, // using intercom id instead of the real one for better security
          verified: userDetails.verificationStatus === UserVerificationStatus.Verified,
          madeInvestment: userDetails.madeInvestment,
          madeDeposit: userDetails.madeDeposit,
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* clearCache(): SagaIterator {
  yield call([queryClient, queryClient.clear]);
  yield call([veriffUtils, veriffUtils.clearSessionUrl]);
}

function* resetMfaStatusCache(): SagaIterator {
  yield call([queryClient, queryClient.invalidateQueries], MfaStatusQueryKey);
}

function* onSignInSuccess(): SagaIterator {
  yield call(propagateUserDataToGtm);
  authStorage.resetValues();

  const userDetails: UserDetailsDTO = yield call(getUserDetails);
  const userPreferredLocale = transformLangFromIETFLanguageTag(userDetails.preferredLocale);
  const appLocale: LocaleType = yield call(getAppLanguageLocalVariable);

  if (userPreferredLocale !== appLocale) {
    // if locale from user profile does not match app locale, update it
    yield put(switchLanguage(userPreferredLocale));
  }
}

export function* watchOnSignInSuccess(): Generator<ForkEffect<never>, void> {
  yield takeEvery(AUTH_SIGN_IN_SUCCESS, onSignInSuccess);
}

export function* onSignOutSuccess(): Generator<ForkEffect<never>, void> {
  yield takeEvery(AUTH_SIGN_OUT_SUCCESS, clearCache);
}

export function* onMfaEnabledOrDisabledSuccess(): Generator<ForkEffect<never>, void> {
  yield takeEvery(AUTH_MFA_ENABLED_SUCCESS, resetMfaStatusCache);
  yield takeEvery(AUTH_MFA_DISABLED_SUCCESS, resetMfaStatusCache);
}

export default function* root(): SagaIterator {
  yield all([fork(watchOnSignInSuccess), fork(onSignOutSuccess), fork(onMfaEnabledOrDisabledSuccess)]);
}

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation for (partial) patching of the user global notification preferences */
  patchUserGlobalPreferences: UserPreferences;
  /** Mutation for (partial) patching of the user notification category preference */
  patchUserPreference: UserPreferences;
};

export type MutationPatchUserGlobalPreferencesArgs = {
  input: PatchUserGlobalPreferencesInput;
};

export type MutationPatchUserPreferenceArgs = {
  input: PatchUserPreferenceInput;
};

/** Input type for (partial) patching of the user global notification preferences */
export type PatchUserGlobalPreferencesInput = {
  /** If false, the user does not want to receive any EMAIL notifications. */
  emailNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  /** If false, the user does not want to or cannot receive any PUSH notifications. */
  pushNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  /** If false, the user does not want to receive any SMS notifications. */
  smsNotificationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type PatchUserPreferenceInput = {
  preferenceCategoryName: PreferenceCategoryName;
  email?: Maybe<Scalars['Boolean']>;
  push?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
};

export enum PreferenceCategoryName {
  InformationOnProjects = 'InformationOnProjects',
  InformationForUsers = 'InformationForUsers',
  NewProjectsMarketing = 'NewProjectsMarketing',
  MyProjectsUpdates = 'MyProjectsUpdates',
  Personalized = 'Personalized',
  NewsAndPersonalisedOffers = 'NewsAndPersonalisedOffers',
  NewInvestmentOpportunities = 'NewInvestmentOpportunities',
  SummaryReports = 'SummaryReports',
  ProfitNotifications = 'ProfitNotifications',
}

export type Query = {
  __typename?: 'Query';
  UserPreferences: UserPreferences;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  preferenceCategoryName: PreferenceCategoryName;
  email: Scalars['Boolean'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  /** If false, the user does not want to receive any EMAIL notifications. */
  emailNotificationsEnabled: Scalars['Boolean'];
  /** If false, the user does not want to or cannot receive any PUSH notifications. */
  pushNotificationsEnabled: Scalars['Boolean'];
  /** If false, the user does not want to receive any SMS notifications. */
  smsNotificationsEnabled: Scalars['Boolean'];
  categoryPreferences: Array<UserPreference>;
};

export type PatchUserGlobalPreferencesMutationVariables = Exact<{
  input: PatchUserGlobalPreferencesInput;
}>;

export type PatchUserGlobalPreferencesMutation = { __typename?: 'Mutation' } & {
  patchUserGlobalPreferences: { __typename?: 'UserPreferences' } & Pick<
    UserPreferences,
    'emailNotificationsEnabled' | 'pushNotificationsEnabled' | 'smsNotificationsEnabled'
  >;
};

export type PatchUserPreferenceMutationVariables = Exact<{
  input: PatchUserPreferenceInput;
}>;

export type PatchUserPreferenceMutation = { __typename?: 'Mutation' } & {
  patchUserPreference: { __typename?: 'UserPreferences' } & {
    categoryPreferences: Array<
      { __typename?: 'UserPreference' } & Pick<UserPreference, 'preferenceCategoryName' | 'email' | 'push' | 'sms'>
    >;
  };
};

export type GetUserPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPreferencesQuery = { __typename?: 'Query' } & {
  UserPreferences: { __typename?: 'UserPreferences' } & Pick<
    UserPreferences,
    'emailNotificationsEnabled' | 'pushNotificationsEnabled' | 'smsNotificationsEnabled'
  > & {
      categoryPreferences: Array<
        { __typename?: 'UserPreference' } & Pick<UserPreference, 'preferenceCategoryName' | 'email' | 'push' | 'sms'>
      >;
    };
};

export const PatchUserGlobalPreferencesDocument = gql`
  mutation PatchUserGlobalPreferences($input: PatchUserGlobalPreferencesInput!) {
    patchUserGlobalPreferences(input: $input) {
      emailNotificationsEnabled
      pushNotificationsEnabled
      smsNotificationsEnabled
    }
  }
`;
export const PatchUserPreferenceDocument = gql`
  mutation PatchUserPreference($input: PatchUserPreferenceInput!) {
    patchUserPreference(input: $input) {
      categoryPreferences {
        preferenceCategoryName
        email
        push
        sms
      }
    }
  }
`;
export const GetUserPreferencesDocument = gql`
  query GetUserPreferences {
    UserPreferences {
      emailNotificationsEnabled
      pushNotificationsEnabled
      smsNotificationsEnabled
      categoryPreferences {
        preferenceCategoryName
        email
        push
        sms
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    PatchUserGlobalPreferences(
      variables: PatchUserGlobalPreferencesMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PatchUserGlobalPreferencesMutation> {
      return withWrapper(() =>
        client.request<PatchUserGlobalPreferencesMutation>(
          PatchUserGlobalPreferencesDocument,
          variables,
          requestHeaders
        )
      );
    },
    PatchUserPreference(
      variables: PatchUserPreferenceMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PatchUserPreferenceMutation> {
      return withWrapper(() =>
        client.request<PatchUserPreferenceMutation>(PatchUserPreferenceDocument, variables, requestHeaders)
      );
    },
    GetUserPreferences(
      variables?: GetUserPreferencesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetUserPreferencesQuery> {
      return withWrapper(() =>
        client.request<GetUserPreferencesQuery>(GetUserPreferencesDocument, variables, requestHeaders)
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/* eslint-disable import/no-duplicates */
import React from 'react';
import { useIntl } from 'react-intl';
import { capitalize } from 'lodash';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  formatDistanceStrict,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Big } from 'big.js';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { isMoreThanMonthsFromNow } from '../../../common-utils/src/lib/date/dateUtils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { availableLocales } from '../../../api-sdk/src/lib/locales';

import { removeLeadingNonDigit } from './regexp';

interface Props {
  date: number | Date;
  userLocale: string | null;
  shortMonths?: boolean;
  showAlwaysInDays?: boolean;
}

export enum RemainingTimeValue {
  Today = 'today',
}

const DAYS_IN_YEAR = 365;
const AVG_DAYS_IN_MONTH = DAYS_IN_YEAR / 12;
const AVG_MINUTES_IN_MONTH = AVG_DAYS_IN_MONTH * 24 * 60;
const SEVEN_DAYS_IN_A_MONTH_RATIO = new Big(7 * 24 * 60).div(AVG_MINUTES_IN_MONTH);

/**
 * Requirements:
 * - 1 second to 2 minutes -> duration in seconds is shown
 * - 2 minutes to 2 hours -> duration in minutes is shown
 * - 2 hours to 48 hours -> duration in hours is shown
 * - 48 hours to 2 months -> duration in days is shown
 * - more than 2 months -> duration in months is shown
 *
 * As related to scenarios when months are shown it is required that
 * when when we've just started a new 4-year investment round
 * this component MUST NOT show '49 months'.
 * For this reason we must round DOWN to be safe.
 *
 * There is, however, another requirement. When the duration is e.g.
 * 47 months and 15 days we must show '48 months' (round UP).
 *
 * The sweet spot for rounding has been set around 7 days.
 * So when the duration is 47 months and 6 days we round DOWN and show '47 months'.
 * When the duration is 47 months and 7 days we round up and show '48 months'.
 *
 * The calculations are approximate as we must artificially count the fractional part
 * and decide whether to round up or down. It is not feasible to take all
 * leap years and DST changes into account in order to have exact results.
 *
 * To show remaining time always in days, use showAlwaysInDays property.
 * Last day will be shown as "today".
 * Last second will be shown as "1 day".
 */
export const getRemainingTime = ({ date, userLocale, showAlwaysInDays, shortMonths }: Props): string => {
  const locale = userLocale && availableLocales[userLocale] ? availableLocales[userLocale] : enUS;
  const now = new Date();

  // `approximateDiffInMonths` serves only for distinction
  // whether to show duration in months or not. It is not exact
  // enough to use it for rendering of the final result.
  const approximateDiffInMonths = differenceInMonths(date, now);
  if (approximateDiffInMonths >= 2 && !showAlwaysInDays) {
    const diffInMinutes = differenceInMinutes(date, now);
    const moreAccurateDiffInMonths = new Big(diffInMinutes).div(AVG_MINUTES_IN_MONTH);
    const fractionalPart = moreAccurateDiffInMonths.minus(moreAccurateDiffInMonths.toFixed(0, Big.roundDown));

    const roundingMode = fractionalPart.gte(SEVEN_DAYS_IN_A_MONTH_RATIO) ? Big.roundUp : Big.roundDown;
    const months = parseInt(moreAccurateDiffInMonths.toFixed(0, roundingMode), 10);

    const formatter = new Intl.RelativeTimeFormat(userLocale || 'en', {
      numeric: 'auto',
      style: shortMonths ? 'short' : 'long',
    });
    return removeLeadingNonDigit(formatter.format(months, 'month'));
  }

  const diffInHours = differenceInHours(date, now);
  if (showAlwaysInDays) {
    // Remaining less than one day, show "today"
    if (diffInHours < 24) {
      return RemainingTimeValue.Today;
    }
    // Remaining one day, show with number "1 day"
    if (diffInHours < 48) {
      return formatDistanceStrict(date, now, {
        unit: 'day',
        locale,
      });
    }
    return formatDistanceStrict(date, now, {
      unit: 'day',
      roundingMethod: 'floor',
      locale,
    });
  }

  if (differenceInDays(date, now) >= 2) {
    return formatDistanceStrict(date, now, {
      unit: 'day',
      roundingMethod: 'ceil',
      locale,
    });
  }

  if (diffInHours >= 2) {
    return formatDistanceStrict(date, now, {
      unit: 'hour',
      roundingMethod: 'ceil',
      locale,
    });
  }

  if (differenceInMinutes(date, now) >= 2) {
    return formatDistanceStrict(date, now, {
      unit: 'minute',
      roundingMethod: 'ceil',
      locale,
    });
  }

  return formatDistanceStrict(date, now, {
    unit: 'second',
    roundingMethod: 'ceil',
    locale,
  });
};

export const RemainingTime: React.FC<Props> = ({
  date,
  userLocale,
  shortMonths,
  showAlwaysInDays = !isMoreThanMonthsFromNow({ months: 2, timestamp: date.valueOf() }),
}) => {
  const intl = useIntl();

  let formattedTime = getRemainingTime({ date, userLocale, shortMonths, showAlwaysInDays });
  formattedTime =
    formattedTime === RemainingTimeValue.Today
      ? capitalize(intl.formatMessage({ id: 'remainingTime.today' }))
      : formattedTime;

  return <>{formattedTime}</>;
};

export const PASSWORD_RULES = {
  minLength: 8,
  hasLowercase: /[a-záéíóúýčďěňřšťůž]/,
  hasUppercase: /[A-ZÁÉÍÓÚÝČĎĚŇŘŠŤŮŽ]/,
  hasNumber: /[0-9]/,
  noWhitespaceStart: /^(?!\s)/,
  noWhitespaceEnd: /(?<!\s)$/,
};

export const PASSWORD_REGEX = new RegExp(
  `${PASSWORD_RULES.noWhitespaceStart.source}` +
    `(?=.*${PASSWORD_RULES.hasLowercase.source})` +
    `(?=.*${PASSWORD_RULES.hasUppercase.source})` +
    `(?=.*${PASSWORD_RULES.hasNumber.source})` +
    `.{${PASSWORD_RULES.minLength},}` +
    `${PASSWORD_RULES.noWhitespaceEnd.source}`
);

export const hasNumber = (value: string): boolean => PASSWORD_RULES.hasNumber.test(value);
export const hasOneLower = (value: string): boolean => PASSWORD_RULES.hasLowercase.test(value);
export const hasOneUpper = (value: string): boolean => PASSWORD_RULES.hasUppercase.test(value);
export const hasEightChars = (value: string): boolean => value.length >= PASSWORD_RULES.minLength;
export const hasNoWhitespaceStart = (value: string): boolean => PASSWORD_RULES.noWhitespaceStart.test(value);
export const hasNoWhitespaceEnd = (value: string): boolean => PASSWORD_RULES.noWhitespaceEnd.test(value);

export interface PasswordStrength {
  strength: number;
  hasOneLower: boolean;
  hasOneUpper: boolean;
  hasOneNumber: boolean;
  longEnough: boolean;
  noWhitespaceStart: boolean;
  noWhitespaceEnd: boolean;
}

export const strengthIndicator = (value: string): PasswordStrength | null => {
  let strength = 0;
  let hasOneNumberResult = false;
  let hasOneLowerResult = false;
  let hasOneUpperResult = false;
  let longEnough = false;
  let noWhitespaceStartResult = false;
  let noWhitespaceEndResult = false;

  if (value.length === 0) {
    return null;
  }

  if (value.length > 4) {
    strength += 20;
  }
  if (hasEightChars(value)) {
    strength += 10;
    longEnough = true;
  }
  if (hasNumber(value)) {
    strength += 20;
    hasOneNumberResult = true;
  }
  if (hasOneLower(value)) {
    strength += 10;
    hasOneLowerResult = true;
  }
  if (hasOneUpper(value)) {
    strength += 20;
    hasOneUpperResult = true;
  }
  if (hasNoWhitespaceStart(value)) {
    strength += 10;
    noWhitespaceStartResult = true;
  }
  if (hasNoWhitespaceEnd(value)) {
    strength += 10;
    noWhitespaceEndResult = true;
  }

  return {
    strength,
    hasOneLower: hasOneLowerResult,
    hasOneUpper: hasOneUpperResult,
    hasOneNumber: hasOneNumberResult,
    longEnough,
    noWhitespaceStart: noWhitespaceStartResult,
    noWhitespaceEnd: noWhitespaceEndResult,
  };
};

import { createAction } from 'typesafe-actions';

import { AbilityToBearLossQuestionnaireSubmitResponseData } from '@investown/fe/api-sdk';

export const signUpClickedAction = createAction('SIGNUP_CLICKED')<void>();
export const signUpOpenedAction = createAction('SIGNUP_OPENED')<void>();

export const verificationOpenedAction = createAction('VERIFICATION_OPENED')<void>();
export const verificationStartAction = createAction('VERIFICATION_START')<void>();
export const verificationDocumentsCaptureStartAction = createAction('VERIFICATION_DOCUMENTS_CAPTURE_START')<void>();
export const verificationSecondPhaseStartAction = createAction('VERIFICATION_SECOND_PHASE_START')<void>();
export const verificationFinishedPep = createAction('VERIFICATION_FINISHED_PEP')<void>();
export const verificationFinishedDocuments = createAction('VERIFICATION_FINISHED_DOCUMENTS')<void>();
export const verificationFinishedMoneyOrigin = createAction('VERIFICATION_FINISHED_MONEY_ORIGIN')<void>();
export const verificationInvestorQuestionnaireOpenedAction = createAction('VERIFICATION_QUESTIONNAIRE_START')<void>();

export const verificationInvestorQuestionnaireSubmittedAction = createAction('VERIFICATION_QUESTIONNAIRE_COMPLETE')<{
  verifResult: string;
  isUpgrade?: boolean;
}>();

export const verificationSubmittedAction = createAction('VERIFICATION_SUBMITTED')<void>();

export const abilityToBearLossQuestionnaireCompleteAction = createAction('ABILITY_TO_BEAR_LOSS_COMPLETE')<{
  submitResponseData: AbilityToBearLossQuestionnaireSubmitResponseData;
}>();

import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { getInstallments } from '../core/getInstallments';
import { InstallmentFilterType, InstallmentsQuery } from '../core/__generated__/sdk';
import { QueryKeys } from '../enums';

interface InstallmentsRequest {
  propertyInvestmentRoundId: string;
  page?: number;
  perPage?: number;
  filter?: InstallmentFilterType;
}

export function useInfiniteInstallments({
  propertyInvestmentRoundId,
  page = 0,
  perPage = 100,
  filter,
  enabled,
  onApiCall,
}: {
  propertyInvestmentRoundId: string;
  page?: number;
  perPage?: number;
  filter?: InstallmentFilterType;
  enabled?: boolean;
  onApiCall?: () => void;
}): UseInfiniteQueryResult<InstallmentsQuery, Error> {
  return useInfiniteQuery<InstallmentsQuery, Error, InstallmentsQuery, [string, InstallmentsRequest]>(
    [QueryKeys.Installments, { propertyInvestmentRoundId, page, perPage, filter }],
    ({ pageParam = page }) => {
      if (onApiCall) {
        onApiCall();
      }
      return getInstallments({
        propertyInvestmentRoundId,
        page: pageParam,
        perPage,
        filter,
      });
    },
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        return lastPage.installments.length < perPage ? undefined : pages.length;
      },
      staleTime: 0,
    }
  );
}

import stringify from 'fast-safe-stringify';

import type { Logger, LoggerCategory } from './types';

export class CustomError extends Error {
  constructor(anyError: unknown) {
    super(`(${typeof anyError}): ${tryStringify(anyError)}`);
  }
}

export const tryStringify = (obj: unknown): string => {
  try {
    if (obj instanceof Error) return obj.message;
    return JSON.stringify(obj);
  } catch {
    try {
      return stringify(obj);
    } catch {
      return '(failed to stringify error object)';
    }
  }
};

export const timestamp = (): string => {
  const date = new Date();
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
    .getSeconds()
    .toString()
    .padStart(2, '0')}.${date.getMilliseconds().toString().padStart(3, '0')}`;
};

export const createLoggerImpl = (
  isDev: boolean,
  sentry: typeof import('@sentry/react') | typeof import('@sentry/react-native'),
  setupGlobalHandlers?: (logger: Logger) => void
): Logger => {
  const logWithLevel =
    (level: 'info' | 'warning' | 'error' | 'fatal') =>
    (category: LoggerCategory, message: string): void => {
      if (isDev) {
        let method: typeof console.log;

        switch (level) {
          case 'info':
            // eslint-disable-next-line no-console
            method = console.log;
            break;
          case 'warning':
            method = console.warn;
            break;
          default:
            method = console.error;
        }
        method(`[${timestamp()}]\t${category} ${message}`);
      } else {
        sentry.addBreadcrumb({ category, message, level });
      }
    };

  const errorWithCapture =
    (level: 'error' | 'fatal') =>
    (category: LoggerCategory, message: string, error: unknown): void => {
      if (isDev) {
        console.error(`[${timestamp()}] (${level.toUpperCase()}) ${category} ${message}`, tryStringify(error));
      } else {
        sentry.addBreadcrumb({ category, message, level });
        sentry.captureException(error instanceof Error ? error : new CustomError(error ?? `${category} ${message}`));
      }
    };

  const logger: Logger = {
    debug: (category, message) => {
      // eslint-disable-next-line no-console
      if (isDev) console.debug(`[${timestamp()}] ${category} ${message}`);
    },
    log: logWithLevel('info'),
    warn: logWithLevel('warning'),
    error: errorWithCapture('error'),
    fatal: errorWithCapture('fatal'),
  };

  if (setupGlobalHandlers) {
    setupGlobalHandlers(logger);
  }

  return logger;
};

import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import IcomoonReact from 'icomoon-react';

import { CoreSDK } from '@investown/fe/api-sdk';
import { iconMoonIconSet } from '@investown/fe/ui-utils/icons/icomoon';
import { useProlongedBannerLangValues } from '@investown/fe/ui-utils/properties';

import StatusLabel from 'components/V2/StatusLabel/StatusLabel';
import { getBoldTextRenderer, getLinkRenderer } from 'util/lang';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';

function ProlongedBanner({
  interestRates,
  onClick,
  onActityPress = () => null,
  onRepaymentSchedulePress = () => null,
}: {
  interestRates: Omit<CoreSDK.InterestChangeObjectType, 'id'>[];
  onClick?: (params: { title: string; description: ReactNode }) => void;
  onActityPress?: () => void;
  onRepaymentSchedulePress?: () => void;
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const tagValues = {
    b: getBoldTextRenderer(),
    activity: getLinkRenderer({ onClick: onActityPress, testId: 'prolonged-activity-link', url: '#activity' }),
    repaymentSchedule: getLinkRenderer({
      onClick: onRepaymentSchedulePress,
      testId: 'prolonged-repayment-schedule-link',
      url: '#repayment-schedule',
    }),
  };
  const langValues = useProlongedBannerLangValues(interestRates, tagValues);

  const onBannerClick = useCallback(() => {
    if (onClick == null || typeof onClick !== 'function') {
      return;
    }

    onClick({
      title: formatMessage({ id: 'property.detail.prolongation.title' }),
      description: langValues ? (
        <Typography variant="bodyBASERegular">
          {formatMessage(
            { id: langValues.id },
            {
              ...langValues.values,
              // @ts-expect-error passing a function instead of ReactNode
              b: (text: string) => {
                return text;
              },
            }
          )}
        </Typography>
      ) : (
        ''
      ),
    });
  }, [onClick, formatMessage, langValues]);

  if (interestRates == null || interestRates.length === 0 || langValues == null) {
    return null;
  }

  return (
    <StatusLabel
      icon={<IcomoonReact iconSet={iconMoonIconSet} color={theme.colorTokens.icon.brand} icon="info" />}
      color="primary"
      fontColor="strong"
      textLeft={formatMessage({ id: 'property.detail.prolonged' })}
      description={<Lang id={langValues.id} values={langValues.values} />}
      onClick={onBannerClick}
    />
  );
}

export default ProlongedBanner;

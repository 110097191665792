import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  testID: string;
  disabled?: boolean;
}

export default function Switch({ onChange, checked, testID, disabled = false }: SwitchProps) {
  const randomString = useMemo(() => Math.random().toString(36).substring(7), []);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <Wrapper checked={checked}>
      <Handle checked={checked} />
      <Input
        data-testid={testID}
        checked={checked}
        type="checkbox"
        id={`switch_${randomString}`}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </Wrapper>
  );
}

const Input = styled.input`
  display: none;
`;

const Wrapper = styled.label<{ checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.colorTokens.surface.brand : theme.colorTokens.surface.strong};
  border-radius: 31px;
  transition: all 0.3s;
  cursor: pointer;
`;

const Handle = styled.div<{ checked: boolean }>`
  content: '';
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  top: 2px;
  left: ${({ checked }) => (checked ? 22 : 2)}px;
  transition: all 0.3s;
`;

import React, { FC } from 'react';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'styled-components';

import { iconMoonIconSet } from '@investown/fe/ui-utils/icons/icomoon';
import { UtilityColorName, WithChildren } from '@investown/fe/common-utils';

export interface Props extends WithChildren {
  color: UtilityColorName;
  width?: string;
}

const SandWatch: FC<Props> = ({ width, color }) => {
  const theme = useTheme();
  return (
    <IcomoonReact
      iconSet={iconMoonIconSet}
      size={width}
      color={theme.colorTokens.utility[color].strong}
      icon="hourglass"
    />
  );
};

export default SandWatch;

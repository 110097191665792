import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionStorageKeys } from 'constants/SessionStorageKeys';
import {
  PATH_DASHBOARD,
  PATH_FULL_PROPERTIES,
  PATH_OPEN_PROPERTIES,
  PATH_SECONDARY_MARKET,
  PATH_INVESTED_PROPERTIES,
  PATH_INVESTED_PROPERTIES_COLLECTION,
  PATH_INVESTED_PROPERTIES_DELAYED,
  PATH_INVESTED_PROPERTIES_ENDED,
  PATH_INVESTED_PROPERTIES_OPEN,
  PATH_INVESTED_PROPERTIES_PROCESSING,
  PATH_INVESTED_PROPERTIES_REGULAR,
} from 'routes/routesPaths';

const PathnameLogger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      setCurrentPathnameSession(location.pathname);
      resetSearchSession();
    } catch (e) {
      Logger.warn('📦', `Failed to save pathnames to sessionStorage: ${e}`);
    }
  }, [location.pathname]);

  return null;
};

export const setCurrentPathnameSession = (pathname: string): void => {
  const pathnamesString = sessionStorage.getItem(SessionStorageKeys.Pathnames);
  const pathnames = pathnamesString ? JSON.parse(pathnamesString) : [];

  pathnames.unshift(pathname);

  const reducedPathnames = pathnames.slice(0, 2);

  sessionStorage.setItem(SessionStorageKeys.Pathnames, JSON.stringify(reducedPathnames));
};

export const getPreviousPathname = (): string | null => {
  const pathnamesString = sessionStorage.getItem(SessionStorageKeys.Pathnames);

  if (pathnamesString) {
    try {
      const pathnames: string[] = JSON.parse(pathnamesString);
      return pathnames[1] || null;
    } catch (e) {
      Logger.warn('📦', `Invalid pathnames in sessionStorage: ${pathnamesString}. ${e}`);
      return null;
    }
  }
  return null;
};

export const getCurrentPathname = (): string | null => {
  const pathnamesString = sessionStorage.getItem(SessionStorageKeys.Pathnames);

  if (pathnamesString) {
    try {
      const pathnames: string[] = JSON.parse(pathnamesString);
      return pathnames[0] || null;
    } catch (e) {
      Logger.warn('📦', `Invalid pathnames in sessionStorage: ${pathnamesString}. ${e}`);
      return null;
    }
  }
  return null;
};

export const isPreviousPathDetail = (pathname: string | null): boolean => {
  return Boolean(pathname?.includes('/property/'));
};

export const resetSearchSession = (): void => {
  const current = getCurrentPathname();
  const previous = getPreviousPathname();

  if (!isPreviousPathDetail(previous)) {
    if (isHomePath(current)) {
      setSearchQueryInSession(SessionStorageKeys.SearchDashboard, '');
    }

    if (isPortfolioPath(current)) {
      setSearchQueryInSession(SessionStorageKeys.SearchPortfolio, '');
    }
  }
};

export const getSearchQueryFromSession = (queryKey: SessionStorageKeys): string | null => {
  try {
    return sessionStorage.getItem(queryKey);
  } catch (e) {
    Logger.warn('📦', `Error accessing searchQuery from sessionStorage: ${e}`);
    return null;
  }
};

export const setSearchQueryInSession = (queryKey: SessionStorageKeys, query: string): void => {
  try {
    sessionStorage.setItem(queryKey, query);
  } catch (e) {
    Logger.warn('📦', `Error setting searchQuery in sessionStorage: ${e}`);
  }
};

export const isHomePath = (pathname: string | null): boolean => {
  return [PATH_DASHBOARD, PATH_OPEN_PROPERTIES, PATH_SECONDARY_MARKET, PATH_FULL_PROPERTIES].includes(pathname || '');
};

export const isPortfolioPath = (pathname: string | null): boolean => {
  return [
    PATH_INVESTED_PROPERTIES,
    PATH_INVESTED_PROPERTIES_COLLECTION,
    PATH_INVESTED_PROPERTIES_DELAYED,
    PATH_INVESTED_PROPERTIES_ENDED,
    PATH_INVESTED_PROPERTIES_OPEN,
    PATH_INVESTED_PROPERTIES_PROCESSING,
    PATH_INVESTED_PROPERTIES_REGULAR,
  ].includes(pathname || '');
};

export default PathnameLogger;

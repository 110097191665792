import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { DateTime, getCircleColor } from '@investown/fe/common-utils';
import { CoreSDK } from '@investown/fe/api-sdk';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import Check from 'components/V2/Icons/Check';
import ExclamationMark from 'components/V2/Icons/Danger';
import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';

export type DataItem = {
  id: string;
  time: string;
  type: CoreSDK.PropertyInvestmentRoundActivityType;
  content: ReactNode;
};

export type TimelineProps = {
  data: Array<DataItem>;
  loading?: boolean;
};

export default function Timeline({ data, loading = false }: TimelineProps) {
  return (
    <TimelineContainer data-testid="timeline">
      {data.map(({ id, time, type, content }, index) => (
        <Step key={id} data-testid="timeline-item">
          <StepLine>
            <Circle type={type} data-testid="timeline-item-icon">
              <TimelineIcon type={type} />
            </Circle>
            {index !== data.length - 1 ? <Line /> : null}
          </StepLine>
          <Spacer width="24" />
          <StepInfo>
            <Typography variant="labelSMRegular" color="subtle" data-testid="timeline-item-label">
              <DateTime date={time} />
            </Typography>
            {content ? (
              <>
                <Spacer height="8" />
                <StepContent data-testid="timeline-item-content">{content}</StepContent>
              </>
            ) : null}
            {index < data.length - 1 ? <Spacer height="40" /> : null}
          </StepInfo>
        </Step>
      ))}
      {loading && (
        <>
          <StepSkeleton />
          <StepSkeleton />
          <StepSkeleton last />
        </>
      )}
    </TimelineContainer>
  );
}

function TimelineIcon({ type }: { type: CoreSDK.PropertyInvestmentRoundActivityType }) {
  const theme = useTheme();

  if (type === CoreSDK.PropertyInvestmentRoundActivityType.Positive) {
    return (
      <Check
        width="14px"
        color={theme.colorTokens.text.invertedStrong}
        fillColor="transparent"
        hoverColor={theme.colorTokens.text.invertedStrong}
      />
    );
  }

  if (type === CoreSDK.PropertyInvestmentRoundActivityType.Neutral) {
    return (
      <Check
        width="14px"
        color={theme.colorTokens.text.invertedStrong}
        fillColor="transparent"
        hoverColor={theme.colorTokens.text.invertedStrong}
      />
    );
  }

  if (type === CoreSDK.PropertyInvestmentRoundActivityType.Negative) {
    return (
      <ExclamationMark
        variant="regular"
        width="16px"
        color={theme.colorTokens.text.invertedStrong}
        fillColor="transparent"
        hoverColor={theme.colorTokens.text.invertedStrong}
      />
    );
  }

  if (type === CoreSDK.PropertyInvestmentRoundActivityType.Info) {
    return (
      <ExclamationMark
        variant="regular"
        width="16px"
        color={theme.colorTokens.text.invertedStrong}
        fillColor="transparent"
        hoverColor={theme.colorTokens.text.invertedStrong}
      />
    );
  }
  return (
    <Check
      width="16px"
      color={theme.colorTokens.text.invertedStrong}
      fillColor="transparent"
      hoverColor={theme.colorTokens.text.invertedStrong}
    />
  );
}

function StepSkeleton({ last = false }: { last?: boolean }) {
  return (
    <Step data-testid="timeline-item-skeleton">
      <StepLine>
        <LoadingSkeleton circle width="24px" height="24px" containerClassName="timeline-skeleton-circle" />
        {last ? null : <Line />}
      </StepLine>
      <Spacer width="24" />
      <StepInfo>
        <LoadingSkeleton width="100px" height="20px" />
        <Spacer height="4" />
        <LoadingSkeleton width="200px" height="16px" />
        {last ? null : <Spacer height="40" />}
      </StepInfo>
    </Step>
  );
}

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  padding-top: ${({ theme }) => theme.spacing.regular};

  .timeline-skeleton-circle {
    display: flex;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const StepLine = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const Circle = styled.div<{ type: CoreSDK.PropertyInvestmentRoundActivityType }>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  background-color: ${({ type }) => getCircleColor(type)};
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Line = styled.div`
  flex: 1;
  width: 2px;
  background-color: ${({ theme }) => theme.colorTokens.stroke.medium};
`;

const StepInfo = styled.div`
  max-width: 100%;
  flex-shrink: 1;
`;

const StepContent = styled.div`
  flex-direction: column;
`;

import { trimEnd } from 'lodash';

export type TableColumnSize = 'wide' | 'narrow' | 'short' | 'tiny';

// Return column width for columns in PropertyList
export const getColumnWidth = ({
  index,
  columnName,
  isPortfolio,
}: {
  index: number;
  columnName: string;
  isPortfolio?: boolean;
}): TableColumnSize => {
  if (index === 0) {
    return 'wide';
  }
  if (isPortfolio) {
    switch (columnName) {
      case 'investedAmount':
      case 'interestAmount':
      case 'annualPercentageYield':
        return 'narrow';
      case 'riskCategory':
        return 'tiny';
      default:
        return 'short';
    }
  }
  switch (columnName) {
    case 'annualPercentageYield':
      return 'narrow';
    case 'riskCategory':
    case 'loanToValue':
      return 'tiny';
    default:
      return 'short';
  }
};

export function removePxSuffix(value: string): number {
  return Number(trimEnd(value, 'px'));
}

export type TryCatchError = {
  code: string;
  message: string;
  status: number;
};

export const screenResolution = `${window.screen.width}x${window.screen.height}`;

import React, { AnchorHTMLAttributes } from 'react';

import { TextColorName } from '@investown/fe/common-utils';

import Typography from 'components/V2/Typography/Typography';
import { TypographyName } from 'styles/theme';

export function getLinkRenderer(
  attributes: { onClick: () => void; testId: string; url: string } & AnchorHTMLAttributes<'a'>
) {
  const { onClick, testId, url } = attributes;
  return function renderLink(chunks: string) {
    return (
      <a data-testid={testId} href={url} onClick={onClick}>
        {chunks}
      </a>
    );
  };
}

export function getBoldTextRenderer(params?: { fontVariant?: TypographyName; color?: TextColorName }) {
  const { fontVariant = 'bodySMSemiBold', color } = params || {};

  return function BoldString(chunks: string) {
    return (
      <Typography variant={fontVariant} color={color}>
        {chunks}
      </Typography>
    );
  };
}

import React, { ReactNode, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { CoreSDK, useInfiniteInstallments } from '@investown/fe/api-sdk';
import {
  currencyFormat,
  TextColorName,
  useAnalytics,
  projectDetailTabOpenedAction,
  ProjectDetailTabs,
  DateTime,
} from '@investown/fe/common-utils';

import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import Spacer from 'components/Spacer';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import Check from 'components/V2/Icons/Check';
import Calendar from 'components/V2/Icons/Calendar';
import InformationCircle from 'components/V2/InformationCircle/InformationCircle';
import Clock from 'components/V2/Icons/StatusLabel/Clock';

const INSTALLMENTS_PER_PAGE = 20;

export default function TabRepaymentSchedule({ propertyInvestmentRoundId }: { propertyInvestmentRoundId: string }) {
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteInstallments({
    propertyInvestmentRoundId,
    page: 0,
    perPage: INSTALLMENTS_PER_PAGE,
    enabled: !!propertyInvestmentRoundId,
  });

  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackEvent(
      projectDetailTabOpenedAction({
        path: location.pathname,
        tab: ProjectDetailTabs.RepaymentSchedule,
      })
    );
  }, [analytics, location]);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: '0px 0px 400px 0px',
  });

  const installments = data?.pages.map((query) => query.installments).flat();

  return (
    <>
      <MemoizedInstallmentList installments={installments} isLoading={isLoading || isFetchingNextPage} />
      <div ref={infiniteScrollRef} />
    </>
  );
}

function InstallmentList({
  installments,
  isLoading,
}: {
  installments?: CoreSDK.InstallmentsQuery['installments'];
  isLoading: boolean;
}) {
  if (!isLoading && (installments == null || installments.length === 0)) {
    return <ErrorEmptyState content={errorEmptyStates.propertyHasNoRepaymentSchedule} />;
  }

  return (
    <>
      <TableRow alignVertical={AlignDirections.Center}>
        <FlexRowCell width="98px" alignVertical={AlignDirections.Center}>
          <Typography variant="labelSMRegular" color="subtle">
            <Lang id="repaymentSchedule.dueDate" />
          </Typography>
        </FlexRowCell>
        <Spacer width="huge" />
        <FlexRowCell width="128px" alignVertical={AlignDirections.Center}>
          <Typography variant="labelSMRegular" color="subtle">
            <Lang id="repaymentSchedule.type" />
          </Typography>
        </FlexRowCell>
        <Spacer width="huge" />
        <FlexRowCell width="118px" alignVertical={AlignDirections.Center}>
          <Typography variant="labelSMRegular" color="subtle">
            <Lang id="repaymentSchedule.totalAmount" />
          </Typography>
        </FlexRowCell>
        <Spacer width="huge" />
        <FlexRowCell width="98px" alignVertical={AlignDirections.Center}>
          <Typography variant="labelSMRegular" color="subtle">
            <Lang id="repaymentSchedule.settlementDate" />
          </Typography>
        </FlexRowCell>
        <Spacer width="huge" />
        <FlexRowCell width="192px" alignVertical={AlignDirections.Center}>
          <Typography variant="labelSMRegular" color="subtle">
            <Lang id="repaymentSchedule.status" />
          </Typography>
        </FlexRowCell>
      </TableRow>
      {installments &&
        installments.map((installment) => <Installment installment={installment} key={installment.id} />)}
      {isLoading && (
        <>
          <Spacer height="extraLarge" />
          <LoadingWidget />
        </>
      )}
    </>
  );
}

const MemoizedInstallmentList = React.memo(InstallmentList);

function Installment({ installment }: { installment: CoreSDK.InstallmentsQuery['installments'][0] }) {
  const { locale } = useIntl();

  const tooltip = useMemo(() => {
    switch (installment.type) {
      case CoreSDK.InstallmentType.LateInterestFee:
        return <Lang id="repaymentSchedule.type.LateInterestFee.description" />;
      case CoreSDK.InstallmentType.StatutoryLateInterestFee:
        return <Lang id="repaymentSchedule.type.StatutoryLateInterestFee.description" />;
      default:
        return null;
    }
  }, [installment.type]);

  return (
    <TableRow withBorder alignVertical={AlignDirections.Center}>
      <FlexRowCell width="98px" alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexStart}>
        <Typography variant="labelSMRegular" color="strong">
          <DateTime date={installment.dueDate} />
        </Typography>
      </FlexRowCell>
      <Spacer width="huge" />
      <FlexRowCell width="128px" alignVertical={AlignDirections.FlexStart} alignHorizontal={AlignDirections.FlexStart}>
        <Typography variant="labelSMRegular" color="strong">
          <Lang id={`repaymentSchedule.type.${installment.type}`} />
        </Typography>
        {tooltip ? (
          <>
            <Spacer width="small" />
            <InformationCircle title={tooltip} />
          </>
        ) : null}
      </FlexRowCell>
      <Spacer width="huge" />
      <FlexRowCell width="118px" alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexEnd}>
        <Typography variant="labelSMRegular" color="strong">
          {currencyFormat({
            input: installment.totalAmount.value,
            currency: installment.totalAmount.currency,
            locale,
            showAllFractionDigits: true,
          })}
        </Typography>
      </FlexRowCell>
      <Spacer width="huge" />
      <FlexRowCell width="98px" alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexStart}>
        <Typography variant="labelSMRegular" color="strong">
          {installment.settlementDate ? <DateTime date={new Date(installment.settlementDate)} /> : '-'}
        </Typography>
      </FlexRowCell>
      <Spacer width="huge" />
      <FlexRowCell width="192px" alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexStart}>
        <RepaymentStatus status={installment.status} />
      </FlexRowCell>
    </TableRow>
  );
}

function RepaymentStatus({ status }: { status: CoreSDK.InstallmentStatusForFrontend }) {
  const theme = useTheme();

  const types: {
    [key in CoreSDK.InstallmentStatusForFrontend]: { backgroundColor: string; icon: ReactNode; color: TextColorName };
  } = {
    Settled: {
      backgroundColor: theme.colorTokens.utility.success.subtle,
      icon: <Check width="16px" color={theme.colorTokens.text.success} hoverColor={theme.colorTokens.text.success} />,
      color: 'success',
    },
    Planned: {
      backgroundColor: theme.colorTokens.surface.brandFaded25,
      icon: <Calendar width="16px" color={theme.colorTokens.text.brand} hoverColor={theme.colorTokens.text.brand} />,
      color: 'brand',
    },
    Overdue: {
      backgroundColor: theme.colorTokens.utility.warning.subtle,
      icon: <Clock width="16px" color="warning" />,
      color: 'warning',
    },
    SettledAfterDueDate: {
      backgroundColor: theme.colorTokens.utility.success.subtle,
      icon: <Check width="16px" color={theme.colorTokens.text.success} hoverColor={theme.colorTokens.text.success} />,
      color: 'success',
    },
  };

  const { backgroundColor, icon, color } = types[status];

  return (
    <StatusWrapper backgroundColor={backgroundColor}>
      {icon}
      <Spacer width="medium" />
      <Typography variant="labelXSMedium" color={color as TextColorName}>
        <Lang id={`repaymentSchedule.status.${status}`} />
      </Typography>
    </StatusWrapper>
  );
}

const TableRow = styled(FlexRow)<{ withBorder?: boolean }>`
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: ${({ withBorder }) => (withBorder ? '1px' : '0')};
  border-bottom-color: ${({ theme }) => theme.colorTokens.stroke.subtle};
  padding: ${({ theme }) => theme.spacing.medium} 0;
`;

const StatusWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  height: 26px;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.regular};
  border-radius: 18px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const FlexRowCell = styled(FlexRow)<{ width: string }>`
  max-width: ${({ width }) => width};
  width: ${({ width }) => width};
`;

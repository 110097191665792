import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useFeatureFlag } from 'configcat-react';

import { RemoteConfigParameter, TextColorName, percentFormat, useGetYieldRange } from '@investown/fe/common-utils';

import DividerVertical from '../Divider/DividerVertical/DividerVertical';

import PerAnnum from './PerAnnum';

import InformationCircle from 'components/V2/InformationCircle/InformationCircle';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { TypographyName } from 'styles/theme';
import { getJSONValue } from 'util/firebase';
import { getAppLanguageLocalVariable } from 'lngProvider';
import { FallbackFeatureFlagValues, FeatureFlags } from 'constants/FeatureFlags';

interface PerAnnumBonusYieldProps {
  bonusYieldEligible: boolean;
  annualPercentageYield: number;
  layout: 'row' | 'column';
  valueFont?: TypographyName;
  valueColor?: TextColorName;
  prefixFont?: TypographyName;
  suffixFont?: TypographyName;
  suffixColor?: TextColorName;
  strikeThroughPrefixFont?: TypographyName;
  strikeThroughFont?: TypographyName;
  strikeThroughColor?: TextColorName;
  strikeThroughSuffixFont?: TypographyName;
  isWithYieldRangeShortText?: boolean;
  isWithTooltip?: boolean;
}

function LinkString(chunks: string) {
  const { url: investownMembershipLandingPageUrl } = getJSONValue<{ url: Record<string, string> }>(
    RemoteConfigParameter.MembershipLevelsLandingPageUrlWithTopMenu
  );
  return (
    <>
      <Spacer height="large" />
      <a
        data-testid="link-per-annum-tooltip"
        href={investownMembershipLandingPageUrl[getAppLanguageLocalVariable()]}
        target="_blank"
      >
        {chunks}
      </a>
    </>
  );
}

const PerAnnumBonusYield: FC<PerAnnumBonusYieldProps> = ({
  bonusYieldEligible,
  annualPercentageYield,
  layout,
  valueFont,
  valueColor,
  suffixFont = 'labelSMRegular',
  suffixColor,
  strikeThroughPrefixFont = 'bodySMRegular',
  strikeThroughFont = 'bodySMSemiBold',
  strikeThroughSuffixFont = 'bodyXSRegular',
  strikeThroughColor = 'subtle',
  isWithYieldRangeShortText = false,
  isWithTooltip = true,
  prefixFont = 'displayXSRegular',
}) => {
  const intl = useIntl();

  const { value: showYieldRangeEnabled, loading: showYieldRangeLoading } = useFeatureFlag(
    FeatureFlags.ShowYieldRangeWeb,
    FallbackFeatureFlagValues[FeatureFlags.ShowYieldRangeWeb]
  );

  const yieldRange = useGetYieldRange({
    annualPercentageYield,
    bonusYieldEligible,
  });

  if (showYieldRangeLoading || yieldRange === undefined) {
    return <StyledLoadingSkeleton parentFlex />;
  }

  if (yieldRange.bonusYieldEligible) {
    if (!showYieldRangeLoading && showYieldRangeEnabled) {
      return (
        <Container layout={layout}>
          <StyledFlexRow alignVertical={AlignDirections.Baseline}>
            <Typography variant={prefixFont} color={valueColor}>
              <Lang id="perAnnum.upTo" />
            </Typography>
            <Spacer width="small" />
            <PerAnnum
              pa={yieldRange?.maxYield}
              valueFont={valueFont}
              valueColor={valueColor}
              suffixFont={suffixFont}
              suffixColor={suffixColor}
            />
          </StyledFlexRow>
          {layout === 'column' && <Spacer height="medium" />}
          <FlexRow alignVertical={AlignDirections.Center}>
            {layout === 'row' && (
              <>
                <Spacer width="medium" />
                <DividerVertical height="large" />
                <Spacer width="medium" />
              </>
            )}
            <StyledFlexRow alignVertical={AlignDirections.Baseline}>
              <Typography variant={strikeThroughPrefixFont} color={strikeThroughColor}>
                <Lang id={isWithYieldRangeShortText ? 'perAnnum.yourYieldShort' : 'perAnnum.yourYield'} />
              </Typography>
              <Spacer width="small" />
              <Typography variant={strikeThroughFont} color={strikeThroughColor}>
                {percentFormat({
                  input: yieldRange?.currentYield,
                  locale: intl.locale,
                })}
              </Typography>
              <Spacer width="small" />
              <Typography variant={strikeThroughSuffixFont} color={suffixColor}>
                <Lang id="global.perAnnum" />
              </Typography>
            </StyledFlexRow>
            {isWithTooltip && (
              <>
                <Spacer width="medium" />
                <InformationCircle
                  title={<Lang id="global.perAnnum.tooltip" values={{ a: LinkString }} />}
                  placement="bottom"
                />
              </>
            )}
          </FlexRow>
        </Container>
      );
    }
    return (
      <Container layout={layout} isDeprecated={!showYieldRangeLoading && !showYieldRangeEnabled}>
        <PerAnnum pa={yieldRange.currentYield} valueFont={valueFont} valueColor={valueColor} />
        {layout === 'column' ? <Spacer height="medium" /> : <Spacer width="medium" />}

        <Typography variant={strikeThroughFont} color={strikeThroughColor} isStrikeThrough>
          {percentFormat({
            input: annualPercentageYield,
            locale: intl.locale,
          })}
        </Typography>
      </Container>
    );
  }
  return (
    <PerAnnum
      pa={yieldRange.basicYield}
      valueFont={valueFont}
      valueColor={valueColor}
      suffixFont={suffixFont}
      suffixColor={suffixColor}
    />
  );
};

const Container = styled.div<{ layout: 'row' | 'column'; isDeprecated?: boolean }>`
  ${({ layout, isDeprecated }) =>
    layout === 'row' &&
    `
  display: flex;
  flex-direction: row;
  align-items: ${isDeprecated ? 'baseline' : 'center'};
  `};
`;

const StyledFlexRow = styled(FlexRow)`
  white-space: pre;
`;

const StyledLoadingSkeleton = styled(LoadingSkeleton)`
  max-width: 60px;
`;

export default PerAnnumBonusYield;

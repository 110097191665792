import { Auth, CognitoUser } from '@aws-amplify/auth';

import { sanitizeEmail } from '../string';

import { SignInResult, UserAttributeName } from './model';
import { setDeviceStatusRemembered, setMfaAuthResult } from './mfa';

export async function getAccessToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

export async function signIn(username: string, password: string): Promise<SignInResult> {
  const user: SignInResult = await Auth.signIn(username, password);

  if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
    throw new Error('Not implemented');
  } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    throw new Error('Not implemented');
  } else if (user.challengeName === 'MFA_SETUP') {
    throw new Error('Not implemented');
  } else {
    return user;
  }
}

export async function signInConfirmCode(
  authResult: SignInResult,
  code: string,
  mfaType: 'SMS_MFA',
  rememberDevice = false
): Promise<void> {
  const user = await Auth.confirmSignIn(authResult, code, mfaType);
  if (rememberDevice && user) {
    try {
      await setDeviceStatusRemembered(user);
    } catch (err) {
      console.error('Device could not be remembered', err);
    }
  }
  setMfaAuthResult(undefined);
}

/**
 * Wrapper around `signIn` with MFA helper callback
 */
export async function signInWithMfaCallback({
  username,
  password,
  onSmsMfaChallenge,
}: {
  username: string;
  password: string;
  onSmsMfaChallenge: (authResult: SignInResult) => void;
}): Promise<SignInResult> {
  const authResult: SignInResult = await signIn(sanitizeEmail(username), password);

  // MFA
  if (authResult.challengeName) {
    if (authResult.challengeName === 'SMS_MFA') {
      setMfaAuthResult(authResult);
      onSmsMfaChallenge(authResult);
    } else {
      throw new Error(`${authResult.challengeName} challenge not implemented`);
    }
  }

  return authResult;
}

export async function signOut(global = false): Promise<void> {
  await Auth.signOut({ global }).catch((err) => console.error(err));
}

/**
 * Auth.forgotPassword seems to resolve even if username does not exist (security feature).
 * If we want we can check if user exist with our backend function.
 */
export async function forgotPassword(username: string): Promise<void> {
  return Auth.forgotPassword(username);
}

export async function forgotPasswordSubmit(username: string, code: string, password: string): Promise<string> {
  return Auth.forgotPasswordSubmit(username, code, password);
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
  const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(currentUser, oldPassword, newPassword);
}

export async function sendCodeToVerifyPhoneNumber(): Promise<void> {
  await Auth.verifyCurrentUserAttribute(UserAttributeName.PhoneNumber);
}

export async function isUserLoggedInAWS(): Promise<boolean> {
  try {
    await Auth.currentSession();
    return true;
  } catch (err) {
    return false;
  }
}

export async function refreshUserSessionByRefreshTokenManually(): Promise<void> {
  // If user is not logged in, ignore it
  const user = await Auth.currentAuthenticatedUser().catch(() => null);
  if (!user) return;

  const session = await Auth.currentSession().catch(() => null);
  if (!session) return;

  const refreshToken = session.getRefreshToken();

  user.refreshSession(refreshToken, (err: never) => {
    if (err) {
      console.error('💥', 'Unable to get new user session using refresh token - refresh token is invalid', err);
    }
  });
}

export enum AnalyticsAction {
  /** @deprecated */
  ABILITY_TO_BEAR_LOSS_COMPLETE = 'ability_to_bear_loss_complete',
  /** @deprecated */
  ABILITY_TO_BEAR_LOSS_START = 'ability_to_bear_loss_start',
  AUTOINVEST_ACTIVATED = 'autoinvest_activated',
  AUTOINVEST_BANK_DETAILS_COPIED = 'autoinvest_bank_details_copied',
  AUTOINVEST_DEACTIVATED = 'autoinvest_deactivated',
  AUTOINVEST_PARAMETERS_CHANGED = 'autoinvest_parameters_changed',
  AUTOINVEST_STEP = 'autoinvest_step',
  BIOMETRIC_PROFILE_SELECT = 'biometric_profile_select',
  CANCEL_ALL_SECONDARY_MARKET_OFFERS = 'cancel_all_secondary_market_offers',
  ERROR = 'error',
  FAST_INVESTMENT_INITIALIZATION = 'fastinvest_homepage_btn_ctr',
  FAST_SECONDARY_MARKET_INVESTMENT_INITIALIZATION = 'fast_secondary_market_invest_homepage_btn_ctr',
  FIRST_INVESTMENT = 'user_first_investment',
  INVEST = 'invest',
  INVEST_OVERVIEW = 'invest_overview',
  INVITE_FRIENDS = 'invite_friends',
  LEAD = 'lead',
  LOGIN = 'login',
  LOGIN_START = 'login_start',
  LOGOUT = 'logout',
  ONBOARDING_STEP = 'onboarding_step',
  PASSWORD_RESET = 'password_reset',
  PROJECT_DETAIL_TAB_OPENED = 'project_detail_tab_opened',
  REFERRAL_ICON_CLICKED = 'referral_icon_clicked',
  REFUND = 'refund',
  /** @deprecated */
  REGISTRATION_STEP = 'registration_step',
  SCREEN_APP_VIEW = 'screen_app_view',
  SCREEN_VIEW = 'screen_view',
  /** @deprecated */
  SIGN_UP_START = 'sign_up_start',
  STARTUP_OS_SCHEME = 'startup_os_scheme',
  /** @deprecated */
  VERIFICATION_DOCUMENTS_CAPTURE_START = 'verification_documents_capture_start',
  /** @deprecated */
  VERIFICATION_FIRST_PHASE_COMPLETE = 'verification_first_phase_complete',
  /** @deprecated */
  VERIFICATION_QUESTIONNAIRE_COMPLETE = 'verification_questionnaire_complete',
  /** @deprecated */
  VERIFICATION_QUESTIONNAIRE_START = 'verification_questionnaire_start',
  /** @deprecated */
  VERIFICATION_SECOND_PHASE_COMPLETED = 'verification_second_phase_completed',
  /** @deprecated */
  VERIFICATION_SECOND_PHASE_START = 'verification_second_phase_start',
  /** @deprecated */
  VERIFICATION_START = 'verification_start',
  /** @deprecated */
  VERIFICATION_STEP = 'verification_step',
  VIEW_ITEM = 'view_item',
  WITHDRAW_FUNDS = 'withdraw_funds',
  SEARCH = 'search',
}

import React, { FC } from 'react';
import styled from 'styled-components';

import { TextColorName, WithChildren } from '@investown/fe/common-utils';
import { getMembershipLevelsLink } from '@investown/fe/ui-utils/properties';

import Lang from 'util/IntlMessages';
import InformationCircle from 'components/V2/InformationCircle/InformationCircle';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { getAppLanguageLocalVariable } from 'lngProvider';
import { TypographyName } from 'styles/theme';

interface Props extends WithChildren {
  title: string;
  titleFontVariant?: TypographyName;
  valueFontVariant?: TypographyName;
  titleColor?: TextColorName;
  tooltip?: string;
  icon?: React.ReactNode;
  tooltipClassName?: string;
  testID?: string;
}

function LinkString(chunks: string) {
  const userLocale = getAppLanguageLocalVariable();
  const membershipLevelsUrl = getMembershipLevelsLink(userLocale);
  return (
    <>
      <Spacer height="regular" />
      <a data-testid="membership-levels-link" href={membershipLevelsUrl} target="_blank">
        {chunks}
      </a>
    </>
  );
}

const InvestmentInfoRow: FC<Props> = ({
  title,
  titleColor = 'medium',
  titleFontVariant = 'labelBASERegular',
  valueFontVariant = 'labelBASERegular',
  tooltip,
  icon,
  tooltipClassName,
  testID,
  children,
}) => (
  <Row alignVertical={AlignDirections.Center}>
    <LeftSide>
      {icon && (
        <>
          {icon}
          <Spacer width="regular" />
        </>
      )}
      <Typography variant={titleFontVariant} color={titleColor} numberOfLines={2}>
        <Lang id={title} />
      </Typography>
      {tooltip && (
        <>
          <Spacer width="small" />
          <InformationCircle className={tooltipClassName} title={<Lang id={tooltip} values={{ a: LinkString }} />} />
        </>
      )}
    </LeftSide>
    <RightSide>
      <Typography variant={valueFontVariant} color="strong" testID={testID}>
        {children}
      </Typography>
    </RightSide>
  </Row>
);

const Row = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
  text-align: right;
`;

export default InvestmentInfoRow;

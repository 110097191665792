// Uses dynamic require() calls to load the appropriate logger implementation based on the platform.
// The logger is then assigned to globalThis.Logger so it can be accessed globally throughout the app.

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./globals.d.ts" />

export const SentryLogger = {
  init: (platform: 'web' | 'mobile'): void => {
    if (platform === 'web') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      const { createLogger: createWebLogger } = require('./logger.web');

      // Double type assertion is necessary because TypeScript does not automatically know that our global augmentation (defined in globals.d.ts) has added the 'Logger' property to globalThis.
      (globalThis as unknown as { Logger: import('./types').Logger }).Logger = createWebLogger();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      const { createLogger: createMobileLogger } = require('./logger.mobile');
      (globalThis as unknown as { Logger: import('./types').Logger }).Logger = createMobileLogger();
    }
  },
};

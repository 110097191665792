import React, { FC } from 'react';
import styled from 'styled-components';

import { SpacingSizeName } from 'styles/theme';

interface Props {
  height?: string | SpacingSizeName;
  className?: string;
}
const DividerVertical: FC<Props> = ({ height, className }) => <Divider height={height} className={className} />;

const Divider = styled.div<Props>`
  height: ${({ height, theme }) => theme.spacing[height as SpacingSizeName] || height};
  width: 1px;
  background-color: ${({ theme }) => theme.colorTokens.stroke.medium};
`;

export default DividerVertical;

export type LocaleType = 'cs' | 'en';

export function transformLangToIETFLanguageTag(lang: string): string {
  switch (lang) {
    case 'cs':
    case 'cs-CZ':
    case 'cs_CZ':
    case 'sk':
    case 'sk-SK':
    case 'sk_SK':
      return 'cs-CZ';
    default:
      return 'en-US';
  }
}

export function transformLangFromIETFLanguageTag(lang: string | undefined): LocaleType {
  switch (lang) {
    case 'cs-CZ':
      return 'cs';
    default:
      return 'en';
  }
}

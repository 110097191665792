import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  ApiError,
  ApiErrorCode,
  CoreSDK,
  getInvestmentRoundSecondaryMarketInfo,
  getProperty,
  PropertyInvestmentRoundContractVersion,
  QueryKeys,
  refetchIntervals,
  usePropertyInvestments,
  useUserDetails,
  useUserVerificationData,
} from '@investown/fe/api-sdk';
import { isProjectLegacy } from '@investown/fe/ui-utils/properties';
import { investOverviewAction, isProjectWithContractVersion, useAnalytics } from '@investown/fe/common-utils';

import TabPropertyBorrower from './Tabs/TabPropertyBorrower';
import Sidebar from './Sidebar/Sidebar';
import SidebarLoadingSkeleton from './Sidebar/SidebarLoadingSkeleton';
import Tabs from './Tabs/Tabs';
import TabPropertyFAQ from './Tabs/TabPropertyFAQ';
import TabLoanActivity from './Tabs/TabLoanActivity';
import TabRepaymentSchedule from './Tabs/TabRepaymentSchedule';

import Lang from 'util/IntlMessages';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import TabPropertyDescription from 'containers/Property/Tabs/TabPropertyDescription';
import { PATH_ERROR_404 } from 'routes/routesPaths';
import Layout from 'components/V2/Layout/Layout';
import LayoutContainer from 'components/V2/LayoutContainer/LayoutContainer';
import LayoutContainerContent from 'components/V2/LayoutContainerContent/LayoutContainerContent';
import Spacer from 'components/V2/Spacer/Spacer';
import { secondaryMarketOffersModalClosedAction } from 'appRedux/secondaryMarket/actions';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import ErrorEmptyStates from 'constants/ErrorEmptyStates';
import Typography from 'components/V2/Typography/Typography';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import PhotoGallery from 'components/V2/PhotoGallery/PhotoGallery';
import InvestmentsWithdrawalModal from 'modals/Property/InvestmentsWithdrawalModal/InvestmentsWithdrawalModal';
import SecondaryMarketOffersModal from 'modals/Property/SecondaryMarketOffersModal/SecondaryMarketOffersModal';
import RevenuesAndFeesModal from 'modals/Property/RevenuesAndFeesModal/RevenuesAndFeesModal';
import SecondaryMarketOffersCancelModal from 'modals/Property/SecondaryMarketOffersCancelModal/SecondaryMarketOffersCancelModal';
import OfferInvestmentToSecondaryMarketModal from 'modals/Property/OfferInvestmentToSecondaryMarketModal/OfferInvestmentToSecondaryMarketModal';
import InvestmentModal from 'modals/Property/InvestmentModal/InvestmentModal';

export enum PropertyTabs {
  ABOUT = 'about',
  PARTNER = 'partner',
  ACTIVITY = 'activity',
  REPAYMENT_SCHEDULE = 'repayment-schedule',
  FAQ = 'faq',
}

const Property: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [investmentModalVisible, setInvestmentModalVisible] = useState(false);
  const [withdrawFromInvestmentModalVisible, setWithdrawFromInvestmentModalVisible] = useState(false);
  const [investmentRoundSecondaryOffersModalVisible, setInvestmentRoundSecondaryOffersModalVisible] = useState(false);
  const [revenuesAndFeesModalVisible, setRevenuesAndFeesModalVisible] = useState(false);
  const [cancelAllSecondaryMarketOffersVisible, setCancelAllSecondaryMarketOffersVisible] = useState(false);
  const [refetchSecondaryMarketOffers, setRefetchSecondaryMarketOffers] = useState(false);
  const [offerInvestmentToMarketplaceModalVisible, setOfferInvestmentToMarketplaceModalVisible] = useState(false);
  const { slug } = useParams<{ slug: string }>();
  const userDetails = useUserDetails({ refetchInterval: refetchIntervals.thirtySeconds });
  const userVerification = useUserVerificationData(refetchIntervals.thirtySeconds);

  const propertyQueryResult = useQuery<CoreSDK.PropertyQuery, Error, CoreSDK.PropertyQuery, [string, { slug: string }]>(
    [QueryKeys.Property, { slug }],
    ({ queryKey: [, variables] }) => getProperty(variables.slug),
    {
      refetchInterval: refetchIntervals.thirtySeconds, // If interval change, update also delay before refetch Wallet in resetCaches() in investment/sagas.ts
    }
  );

  const investmentRoundId = propertyQueryResult.data?.property?.investmentRound.id;
  const investmentRoundSecondaryMarketInfoQuery = useQuery<CoreSDK.InvestmentRoundSecondaryMarketInfoQuery, Error>(
    [QueryKeys.InvestmentRoundsSecondaryMarketInfo, investmentRoundId],
    () => getInvestmentRoundSecondaryMarketInfo(investmentRoundId ?? ''),
    { enabled: !!investmentRoundId }
  );

  const investmentsResult = usePropertyInvestments(slug);

  const tabs = [
    {
      id: PropertyTabs.ABOUT,
      title: intl.formatMessage({ id: 'property.tabs.description' }),
      component: propertyQueryResult.data && (
        <TabPropertyDescription
          slug={slug}
          investmentRoundId={propertyQueryResult.data.property.investmentRound.id}
          description={propertyQueryResult.data.property.description}
          showLatestActivity={
            propertyQueryResult.data.property.investmentRound.status === CoreSDK.PropertyInvestmentRoundStatus.Funded
          }
        />
      ),
      url: PropertyTabs.ABOUT,
    },
    {
      id: PropertyTabs.PARTNER,
      title: intl.formatMessage({ id: 'property.tabs.borrower' }),
      component: propertyQueryResult.data?.property.investmentRound && (
        <TabPropertyBorrower
          borrowers={propertyQueryResult.data.property.investmentRound.borrowers}
          investmentRoundType={propertyQueryResult.data.property.investmentRound.type}
          slug={slug}
        />
      ),
      url: PropertyTabs.PARTNER,
    },
    {
      id: PropertyTabs.ACTIVITY,
      title: intl.formatMessage({ id: 'property.tabs.activity' }),
      component: propertyQueryResult.data?.property.investmentRound && (
        <TabLoanActivity
          investmentRoundId={propertyQueryResult.data?.property.investmentRound.id}
          currentUsersTotalInvestment={propertyQueryResult.data?.property.investmentRound.currentUsersTotalInvestment}
        />
      ),
      url: PropertyTabs.ACTIVITY,
    },
    {
      id: PropertyTabs.REPAYMENT_SCHEDULE,
      title: intl.formatMessage({ id: 'property.tabs.repaymentSchedule' }),
      component: propertyQueryResult.data?.property.investmentRound ? (
        <TabRepaymentSchedule propertyInvestmentRoundId={propertyQueryResult.data.property.investmentRound.id} />
      ) : null,
      url: PropertyTabs.REPAYMENT_SCHEDULE,
    },
    {
      id: PropertyTabs.FAQ,
      title: intl.formatMessage({ id: 'property.tabs.faq' }),
      component: propertyQueryResult.data?.property.investmentRound && (
        <TabPropertyFAQ
          investmentRoundType={propertyQueryResult.data.property.investmentRound.type}
          isPropertyPremium={
            propertyQueryResult.data.property.investmentRound.level === CoreSDK.PropertyInvestmentRoundLevel.Premium
          }
        />
      ),
      url: PropertyTabs.FAQ,
    },
  ];

  const onInvestmentOfferDropdownButtonClick = useCallback(() => {
    setRefetchSecondaryMarketOffers(false);
    setOfferInvestmentToMarketplaceModalVisible(true);
  }, []);

  const onCloseInvestmentOfferDropdownModal = useCallback(() => {
    setRefetchSecondaryMarketOffers(true);
    setOfferInvestmentToMarketplaceModalVisible(false);
  }, []);

  const onShowSecondaryOffersButtonClick = useCallback(() => {
    setInvestmentRoundSecondaryOffersModalVisible(true);
  }, []);

  const onCloseSecondaryOffersModal = useCallback(() => {
    setInvestmentRoundSecondaryOffersModalVisible(false);
    dispatch(secondaryMarketOffersModalClosedAction());
  }, [dispatch]);

  const trackInvestmentClickEvent = useCallback(
    (project: CoreSDK.PropertyQuery['property'] | undefined) => {
      if (!project) {
        return;
      }

      analytics.trackEvent(investOverviewAction({ project: { ...project, slug } }));
    },
    [analytics, slug]
  );

  const onInvestmentButtonClick = useCallback(() => {
    setInvestmentModalVisible(true);
    trackInvestmentClickEvent(propertyQueryResult.data?.property);
    // we only want to track the event once. Data of the {propertyQueryResult.data?.property}
    // can change however the {property.name} is always the same.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyQueryResult.data?.property.name, trackInvestmentClickEvent]);

  const onCloseInvestmentModal = useCallback(() => {
    setInvestmentModalVisible(false);
  }, []);

  const onWithdrawFromInvestmentDropdownButtonClick = useCallback(() => {
    setWithdrawFromInvestmentModalVisible(true);
  }, []);

  const onWithdrawFromInvestmentDropdownClose = useCallback(() => {
    setWithdrawFromInvestmentModalVisible(false);
  }, []);

  const onRevenuesAndFeesButtonClick = useCallback(() => {
    setRevenuesAndFeesModalVisible(true);
  }, []);

  const onRevenuesAndFeesModalClose = useCallback(() => {
    setRevenuesAndFeesModalVisible(false);
  }, []);

  const onCancelAllSecondaryMarketOffersClick = useCallback(() => {
    setRefetchSecondaryMarketOffers(false);
    setCancelAllSecondaryMarketOffersVisible(true);
  }, []);

  const onCancelAllSecondaryMarketOffersClose = useCallback(() => {
    setCancelAllSecondaryMarketOffersVisible(false);
  }, []);

  const onConfirmCancelAllSecondaryMarketOffers = useCallback(() => {
    setCancelAllSecondaryMarketOffersVisible(false);
    setRefetchSecondaryMarketOffers(true);
  }, []);

  const requestRefetchOnBid = useCallback(() => {
    investmentRoundSecondaryMarketInfoQuery.refetch();
  }, [investmentRoundSecondaryMarketInfoQuery]);

  useEffect(() => {
    if (userVerification.data && userVerification.data.suitability === null) {
      userVerification.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.data, userVerification.data]);

  if (propertyQueryResult.error instanceof ApiError && propertyQueryResult.error.code === ApiErrorCode.NotFound) {
    return <Redirect to={PATH_ERROR_404} />;
  }

  if (propertyQueryResult.isError || userDetails.isError || userVerification.isError) {
    return <ErrorEmptyState content={ErrorEmptyStates.errorPropertyDetail} />;
  }

  const isProjectWithOldContract = !isProjectWithContractVersion(
    propertyQueryResult.data?.property.investmentRound.contractVersionNumber,
    PropertyInvestmentRoundContractVersion.WithConfirmedContract
  );

  return (
    <>
      <Layout>
        {/* Main Content */}
        <LayoutContainer>
          <LayoutContainerContent isColumn>
            {!propertyQueryResult.isLoading &&
            !userDetails.isLoading &&
            propertyQueryResult.data &&
            userDetails.data ? (
              <>
                <FlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.FlexStart}>
                  <div>
                    <Typography variant="displayMDRegular" color="strong">
                      {propertyQueryResult.data.property.name}
                    </Typography>
                    <Spacer width="large" />
                    <Typography variant="bodyBASERegular" color="subtle">
                      {propertyQueryResult.data.property.city}
                      {propertyQueryResult.data.property.suburb && `, ${propertyQueryResult.data.property.suburb}`}
                    </Typography>
                  </div>
                </FlexRow>
                <Spacer height="huge" />
                <PhotoGallery photos={propertyQueryResult.data.property.photos.sort((a) => (a.isPrimary ? -1 : 0))} />
                <Spacer height="huge" />
                <Tabs tabs={tabs} />
              </>
            ) : (
              <LoadingWidget />
            )}
          </LayoutContainerContent>
        </LayoutContainer>
        <Spacer width="extraLarge" />

        {/* Sidebar */}
        <LayoutContainer width="416px">
          <LayoutContainerContent isColumn noPadding>
            {!propertyQueryResult.isLoading &&
            !userDetails.isLoading &&
            !userVerification.isLoading &&
            propertyQueryResult.data &&
            userDetails.data &&
            userVerification.data ? (
              <Sidebar
                userVerification={userVerification.data}
                propertyQueryResult={propertyQueryResult.data}
                investmentRoundSecondaryMarketInfoQuery={investmentRoundSecondaryMarketInfoQuery}
                onWithdrawFromInvestmentDropdownButtonClick={onWithdrawFromInvestmentDropdownButtonClick}
                onInvestmentButtonClick={onInvestmentButtonClick}
                onShowSecondaryOffersButtonClick={onShowSecondaryOffersButtonClick}
                onInvestmentOfferDropdownButtonClick={onInvestmentOfferDropdownButtonClick}
                onRevenuesAndFeesButtonClick={onRevenuesAndFeesButtonClick}
                onCancelAllSecondaryMarketOffersClick={onCancelAllSecondaryMarketOffersClick}
                shouldRefetchSecondaryMarkerOffers={refetchSecondaryMarketOffers}
              />
            ) : (
              <SidebarLoadingSkeleton />
            )}
          </LayoutContainerContent>
        </LayoutContainer>
      </Layout>
      <Spacer height="massive" />

      {/* Modals */}
      {!!propertyQueryResult.data && (
        <InvestmentModal
          visible={investmentModalVisible}
          onClose={onCloseInvestmentModal}
          propertyName={propertyQueryResult.data.property.name}
        />
      )}
      {!!investmentsResult.investments?.data.length && !!propertyQueryResult.data && (
        <InvestmentsWithdrawalModal
          visible={withdrawFromInvestmentModalVisible}
          onClose={onWithdrawFromInvestmentDropdownClose}
          investments={investmentsResult.investments.data}
          investmentRoundId={propertyQueryResult.data.property.investmentRound.id}
          investmentRoundStatus={propertyQueryResult.data.property.investmentRound.status}
        />
      )}
      {!!propertyQueryResult.data && (
        <SecondaryMarketOffersModal
          visible={investmentRoundSecondaryOffersModalVisible}
          onClose={onCloseSecondaryOffersModal}
          investmentRoundId={propertyQueryResult.data.property.investmentRound.id}
          requestRefetchOnBid={requestRefetchOnBid}
          isProjectWithOldContract={isProjectWithOldContract}
        />
      )}
      {!!propertyQueryResult.data && !isProjectLegacy(propertyQueryResult.data.property.investmentRound.type) && (
        <RevenuesAndFeesModal
          visible={revenuesAndFeesModalVisible}
          onClose={onRevenuesAndFeesModalClose}
          investmentRoundId={propertyQueryResult.data.property.investmentRound.id}
          investmentCurrency={propertyQueryResult.data.property.investmentRound.investmentCurrency}
          header={<Lang id="property.revenuesAndFees.title" />}
        />
      )}
      {!!propertyQueryResult.data && (
        <SecondaryMarketOffersCancelModal
          visible={cancelAllSecondaryMarketOffersVisible}
          onClose={onCancelAllSecondaryMarketOffersClose}
          onConfirmCancelAllSecondaryMarketOffers={onConfirmCancelAllSecondaryMarketOffers}
          investmentRoundId={propertyQueryResult.data.property.investmentRound.id}
        />
      )}
      <OfferInvestmentToSecondaryMarketModal
        visible={offerInvestmentToMarketplaceModalVisible}
        onClose={onCloseInvestmentOfferDropdownModal}
        slug={slug}
        repaymentStatus={propertyQueryResult.data?.property.investmentRound?.repaymentStatus}
      />
    </>
  );
};

export default Property;

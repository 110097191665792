import React, { useState, FC, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { SurfaceColorName, TextColorName } from '@investown/fe/common-utils';

import Typography from '../Typography/Typography';

import { TypographyName } from 'styles/theme';

export type TooltipPlacement = 'top' | 'left' | 'right' | 'bottom';

export type TooltipTrigger = 'hover' | 'focus' | 'click' | 'contextMenu';

const tooltipClassName = 'tooltip-bubble';

interface TooltipProps {
  isDisabled?: boolean;
  textComponent: React.ReactNode;
  position?: TooltipPlacement;
  trigger?: TooltipTrigger;
  defaultVisible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  children: React.ReactNode;
  fontVariant?: TypographyName;
  fontColor?: TextColorName;
  backgroundColor?: SurfaceColorName;
  className?: string;
}

const Tooltip: FC<TooltipProps> = ({
  isDisabled = false,
  textComponent,
  position = 'top',
  trigger = 'hover',
  defaultVisible = false,
  onVisibleChange,
  mouseEnterDelay = 0,
  mouseLeaveDelay = 0.1,
  children,
  fontVariant = 'bodySMRegular',
  fontColor = 'subtle',
  backgroundColor = 'card',
  className,
}) => {
  const [isVisible, setIsVisible] = useState(defaultVisible);
  const [tooltipStyles, setTooltipStyles] = useState<React.CSSProperties | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);
  const hideTimeoutRef = useRef<number | null>(null);

  const handleVisibilityChange = (newVisibility: boolean) => {
    setIsVisible(newVisibility);
    onVisibleChange?.(newVisibility);
  };

  const handleMouseEnter = () => {
    if (trigger === 'hover') {
      clearTimeout(hideTimeoutRef.current!);
      hideTimeoutRef.current = window.setTimeout(() => handleVisibilityChange(true), mouseEnterDelay * 1000);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover') {
      hideTimeoutRef.current = window.setTimeout(() => {
        if (!isHoveringTooltip) {
          handleVisibilityChange(false);
        }
      }, mouseLeaveDelay * 1000);
    }
  };

  const handleTooltipMouseEnter = () => {
    clearTimeout(hideTimeoutRef.current!);
    setIsHoveringTooltip(true);
    setIsVisible(true);
  };

  const handleTooltipMouseLeave = () => {
    setIsHoveringTooltip(false);
    hideTimeoutRef.current = window.setTimeout(() => handleVisibilityChange(false), mouseLeaveDelay * 1000);
  };

  const handleClick = () => {
    if (trigger === 'click') {
      handleVisibilityChange(!isVisible);
    }
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    if (trigger === 'contextMenu') {
      e.preventDefault();
      handleVisibilityChange(!isVisible);
    }
  };

  useEffect(() => {
    if (isVisible && targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const styles: React.CSSProperties = {};

      const scrollX = window.scrollX ?? document.documentElement.scrollLeft;
      const scrollY = window.scrollY ?? document.documentElement.scrollTop;
      const spaceFromTarget = 8;

      switch (position) {
        case 'top':
          styles.top = targetRect.top + scrollY - tooltipRect.height - spaceFromTarget;
          styles.left = targetRect.left + scrollX + targetRect.width / 2 - tooltipRect.width / 2;
          break;
        case 'bottom':
          styles.top = targetRect.bottom + scrollY + spaceFromTarget;
          styles.left = targetRect.left + scrollX + targetRect.width / 2 - tooltipRect.width / 2;
          break;
        case 'left':
          styles.top = targetRect.top + scrollY + targetRect.height / 2 - tooltipRect.height / 2;
          styles.left = targetRect.left + scrollX - tooltipRect.width - spaceFromTarget;
          break;
        case 'right':
          styles.top = targetRect.top + scrollY + targetRect.height / 2 - tooltipRect.height / 2;
          styles.left = targetRect.right + scrollX + spaceFromTarget;
          break;
      }

      styles.position = 'absolute';
      styles.zIndex = 1000;

      setTooltipStyles(styles);
    } else if (!isVisible) {
      setTooltipStyles(null);
    }

    return () => clearTimeout(hideTimeoutRef.current!);
  }, [isVisible, position]);

  const renderTooltip = () => {
    if (!tooltipStyles) {
      return (
        <TooltipBubble
          ref={tooltipRef}
          style={{ visibility: 'hidden' }}
          className={`${tooltipClassName} ${className || ''}`}
          backgroundColor={backgroundColor}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <TooltipContent>
            <Typography variant={fontVariant} color={fontColor}>
              {textComponent}
            </Typography>
          </TooltipContent>
        </TooltipBubble>
      );
    }

    return (
      <TooltipBubble
        ref={tooltipRef}
        style={tooltipStyles}
        className={`${tooltipClassName} ${className || ''}`}
        backgroundColor={backgroundColor}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <TooltipContent>
          <Typography variant={fontVariant} color={fontColor}>
            {textComponent}
          </Typography>
        </TooltipContent>
      </TooltipBubble>
    );
  };

  if (isDisabled) {
    return children as JSX.Element;
  }

  return (
    <>
      <Wrapper
        ref={targetRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      >
        {children}
      </Wrapper>
      {isVisible && ReactDOM.createPortal(renderTooltip(), document.body)}
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const TooltipBubble = styled.div<{ backgroundColor: SurfaceColorName }>`
  background-color: ${({ theme, backgroundColor }) => theme.colorTokens.surface[backgroundColor]};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  max-width: 250px;
  width: auto;
  box-sizing: border-box;
`;

const TooltipContent = styled.div`
  display: block;
  text-align: left;
  white-space: normal;
  max-width: 250px;
  width: max-content;
  padding: ${({ theme }) => theme.spacing.large};
  box-sizing: border-box;

  span {
    line-height: 18.4px;
  }
`;

export default Tooltip;

/* SDK */
import * as OrdersSDK from './lib/orders/__generated__/sdk';
import * as CoreSDK from './lib/core/__generated__/sdk';
import * as NotificationsSDK from './lib/notifications/__generated__/sdk';
import * as AffiliateSDK from './lib/affiliate/__generated__/sdk';

export { OrdersSDK, CoreSDK, NotificationsSDK, AffiliateSDK };

/* API Types */

/* Basic config api */
export * from './lib/enums';
export * from './lib/utils';
export * from './lib/model';
export * from './lib/constants';
export * from './lib/ApiError';
export * from './lib/api';
export * from './lib/reactQueryConfig';

/* api parts */
export * from './lib/core';
export * from './lib/userVerification';
export * from './lib/users';
export * from './lib/walletWithdrawal';
export * from './lib/orders';
export * from './lib/investment';
export * from './lib/documents-server';
export * from './lib/notifications';
export * from './lib/affiliate';
export * from './lib/verifySMSCode';

export * from './lib/hooks';
export * from './lib/investorQuestionnaire';

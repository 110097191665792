/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import styled, { ThemeContext } from 'styled-components';

import {
  generateUUID,
  translateCurrency,
  translateInvestmentError,
  useAnalytics,
  investAction,
  LegalDocuments,
  isProjectWithContractVersion,
  numberWithSpaceSeparator,
  getSuitabilityQuestionnaireAvailability,
  removeNumberSpaceSeparator,
  deFormattedStringToFloat,
} from '@investown/fe/common-utils';
import {
  ApiError,
  ApiErrorCode,
  InvestmentOrderStatus,
  useInvestmentOrder,
  PlaceOrderInput,
  InvestmentThresholdResponse,
  UserTimestampsResponse,
  CoreSDK,
  QueryKeys,
  getProperty,
  PropertyInvestmentRoundContractVersion,
} from '@investown/fe/api-sdk';
import { TestIds } from '@investown/fe/test-utils/testIds';

import OrderPlacedSuccessfully from './OrderPlacedSuccessfully';

import Lang from 'util/IntlMessages';
import Input from 'components/V2/Input/Input';
import Button from 'components/Button';
import {
  enoughAvailableBalanceHF,
  maxDecimalDigitsHF,
  maxInvestmentValueHF,
  minPropertyInvestmentValueHF,
  sanitizedValue,
} from 'constants/ValidationRules';
import { investedAction, investmentErrorAction, investmentPurchaseAction } from 'appRedux/investment/actions';
import Widget from 'components/Widget';
import Spacer from 'components/Spacer';
import Paragraph from 'components/Typography/Paragraph';
import WarningOutlineIcon from 'components/Icons/WarningOutlineIcon';
import { PATH_INVESTOR_QUESTIONNAIRE_ADDITION } from 'routes/routesPaths';
import { suitabilityQuestionnaireRepeatPeriod } from 'constants/SuitabilityQuestionnaireRepeatPeriod';
import AvailableMoney from 'components/V2/AvailableMoney/AvailableMoney';
import ReturnOnInvest from 'components/V2/ReturnOnInvest/ReturnOnInvest';
import Typography from 'components/V2/Typography/Typography';
import { handleValueFormating } from 'components/V2/Input/helpers';
import Alert from 'components/V2/Alert/Alert';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

interface InvestmentFormProps {
  minInvestment: number;
  maxInvestment: number;
  investmentRoundCurrency: string;
  investmentRoundId: string;
  limitPerUser: number;
  currentUsersTotalInvestment: number;
  investmentLengthInMonths: number;
  totalBonusYield: number | undefined;
  endPercentageBonus: number;
  annualPercentageYield: number;
  onModalClose: () => void;
  wallet: CoreSDK.WalletQuery;
  investmentThresholds: InvestmentThresholdResponse;
  userTimestamps: UserTimestampsResponse;
  slugFromParent?: string;
}

function LinkString(chunks: string, link: string, testID: string) {
  return (
    <a data-testid={testID} href={link} target="_blank">
      {chunks}
    </a>
  );
}

const InvestmentOrderForm: FC<InvestmentFormProps> = ({
  minInvestment,
  maxInvestment,
  investmentRoundCurrency,
  investmentRoundId,
  limitPerUser,
  currentUsersTotalInvestment,
  investmentLengthInMonths,
  totalBonusYield,
  endPercentageBonus,
  annualPercentageYield,
  onModalClose,
  wallet,
  investmentThresholds,
  userTimestamps,
  slugFromParent,
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [orderPlacedSuccessfully, setOrderPlacedSuccessfully] = useState<boolean>(false);
  const [forcedValue, setForcedValue] = useState<string | undefined>(undefined);
  const { slug: urlSlug } = useParams<{ slug: string }>();
  const slug = slugFromParent || urlSlug;
  const dispatch = useDispatch();
  const history = useHistory();
  const [transactionId, setTransactionId] = useState(generateUUID()); // should be generated with first render to ensure idempotency when user taps twice
  const analytics = useAnalytics();
  const useInvestmentOrderResult = useInvestmentOrder({});
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setFocus,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '',
    },
  });
  const { data: propertyData } = useQuery<
    CoreSDK.PropertyQuery,
    Error,
    CoreSDK.PropertyQuery,
    [string, { slug: string }]
  >([QueryKeys.Property, { slug }], ({ queryKey: [, variables] }) => getProperty(variables.slug));

  const isProjectWithOldContract = !isProjectWithContractVersion(
    propertyData?.property.investmentRound.contractVersionNumber,
    PropertyInvestmentRoundContractVersion.WithConfirmedContract
  );

  const amountValue = watch('amount');

  const investBalance = amountValue ? Number(removeNumberSpaceSeparator(amountValue, intl.locale)) : 0;
  const insufficientFundsAmount = investBalance - wallet.Wallet.availableBalance;
  const insufficientFunds = insufficientFundsAmount > 0;

  useEffect(() => {
    setValue('amount', String(minInvestment));
  }, [setValue, minInvestment]);

  const onInvestAll = () => {
    setForcedValue(
      handleValueFormating({
        value: String(Math.min(wallet.Wallet.availableBalance ?? 0, maxInvestment)),
        type: 'number',
        intl,
      })
    );
    setValue(
      'amount',
      handleValueFormating({
        value: String(Math.min(wallet.Wallet.availableBalance ?? 0, maxInvestment)),
        type: 'number',
        intl,
      }) as string
    );
    trigger();
    setFocus('amount');
  };

  const dispatchPurchaseAction = useCallback(
    (input: PlaceOrderInput) => {
      if (propertyData) {
        analytics.trackEvent(
          investAction({
            amount: input.amount.value,
            transactionId,
            currency: input.amount.currency,
            project: { ...propertyData.property, slug },
          })
        );
      }
      dispatch(
        investmentPurchaseAction({
          transactionId: input.transactionId,
          propertySlug: slug,
          amount: input.amount.value,
          currency: input.amount.currency,
        })
      );
    },
    [dispatch, slug, transactionId, propertyData, analytics]
  );

  const onPlaceOrderSuccess = (input: PlaceOrderInput, investedAt: number) => {
    dispatch(
      investedAction({
        transactionId: input.transactionId,
        propertySlug: slug,
        amount: input.amount.value,
        currency: input.amount.currency,
        investedAt,
      })
    );
    setTransactionId(generateUUID());
    setOrderPlacedSuccessfully(true);
    dispatchPurchaseAction(input);
  };

  const onPlaceOrderError = (error: Error) => {
    Logger.error('💥', 'Error placing investment order', error);
    setTransactionId(generateUUID());
    dispatch(investmentErrorAction());
    if (error instanceof ApiError) {
      if (error.code === ApiErrorCode.FeatureTurnedOffError) {
        setApiError(translateInvestmentError(intl, error.code));
      } else if (error.code) {
        setApiError(translateInvestmentError(intl, error.code));
      } else {
        setApiError(intl.formatMessage({ id: 'errors.unknown' }));
      }
    }
  };

  const onSubmit = async (data: { amount: string }) => {
    const input: PlaceOrderInput = {
      transactionId,
      projectId: investmentRoundId,
      amount: {
        value: deFormattedStringToFloat(data.amount, intl),
        currency: investmentRoundCurrency,
      },
    };
    const investedAt = Date.now();
    useInvestmentOrderResult.placeOrder(input, {
      onSuccess: () => onPlaceOrderSuccess(input, investedAt),
      onError: onPlaceOrderError,
    });
  };
  const cleanAmount = deFormattedStringToFloat(amountValue, intl);
  const shouldShowWarning =
    (investmentThresholds.hasToPassAdditionalSuitabilityQuestionnaireThreshold &&
      cleanAmount > investmentThresholds.hasToPassAdditionalSuitabilityQuestionnaireThreshold) ||
    (!investmentThresholds.showAlertThreshold &&
      !investmentThresholds.hasToPassAdditionalSuitabilityQuestionnaireThreshold &&
      isNaN(cleanAmount));
  const shouldShowAlertThreshold =
    investmentThresholds.showAlertThreshold && cleanAmount > investmentThresholds.showAlertThreshold;

  const { canPassSuitabilityQuestionnaire, nextTryAvailableOn } = getSuitabilityQuestionnaireAvailability({
    blockPeriod: suitabilityQuestionnaireRepeatPeriod,
    lastSuitabilityTry: userTimestamps?.investorQuestionnairePartTwo.endedAt,
    locale: intl.locale,
  });

  const pathInvestorQuestionnaireLocation = {
    pathname: PATH_INVESTOR_QUESTIONNAIRE_ADDITION,
    state: { slug: history.location.pathname },
  };

  const renderInvestmentQuestionnaireBanners = () => {
    if (canPassSuitabilityQuestionnaire) {
      return (
        <>
          <Spacer height="extraLarge" />
          <Widget backgroundColor={theme.colorTokens.utility.warning.subtle} padding="large">
            <FlexRow alignVertical={AlignDirections.Center}>
              <WarningOutlineIcon color={theme.colorTokens.utility.warning.strong} />
              <Spacer width="medium" />
              <Typography variant="labelBASEMedium" color="warning">
                <Lang id="property.investmentPopUp.warning.title" />
              </Typography>
            </FlexRow>
            <Spacer height="large" />
            <Typography variant="bodySMRegular" color="warning">
              <Lang id="property.investmentPopUp.warning.content" />
            </Typography>
            <Spacer height="large" />
            <Button
              color="primary"
              onClick={() => history.push(pathInvestorQuestionnaireLocation)}
              block
              data-testid={TestIds.Invest}
            >
              <Lang id="property.investmentPopUp.warning.button" />
            </Button>
          </Widget>
        </>
      );
    }

    return (
      <>
        <Spacer height="extraLarge" />
        <Widget backgroundColor={theme.colorTokens.utility.warning.subtle} padding="large">
          <FlexRow alignVertical={AlignDirections.Center}>
            <WarningOutlineIcon color={theme.colorTokens.utility.warning.strong} />
            <Spacer width="large" />
            <Typography variant="labelBASEMedium" color="warning">
              <Lang id="property.investmentPopUp.temporarilyBlocked.warning.title" />
            </Typography>
          </FlexRow>
          <Spacer height="large" />
          <Typography variant="bodySMRegular" color="warning">
            <Lang
              id="property.investmentPopUp.temporarilyBlocked.warning.content"
              values={{ dateTime: nextTryAvailableOn }}
            />
          </Typography>
          <Spacer height="large" />
          <Button
            color="primary"
            onClick={() => history.push(pathInvestorQuestionnaireLocation)}
            block
            disabled
            data-testid={TestIds.Invest}
          >
            <Lang id="property.investmentPopUp.warning.button" />
          </Button>
        </Widget>
      </>
    );
  };

  return orderPlacedSuccessfully ? (
    <OrderPlacedSuccessfully closeModal={onModalClose} />
  ) : (
    <InvestmentFormHolder onSubmit={handleSubmit(onSubmit)}>
      <AvailableMoney
        amount={wallet.Wallet.availableBalance}
        currency={investmentRoundCurrency}
        availableBalance={wallet.Wallet.availableBalance}
        investBalance={amountValue ? Number(removeNumberSpaceSeparator(amountValue, intl.locale)) : 0}
        maxInvestment={maxInvestment}
        minInvestment={minInvestment}
        onClick={onInvestAll}
      />
      <Spacer height="extraLarge" />
      <Controller
        name="amount"
        control={control}
        rules={{
          validate: {
            required: (value) => {
              if (!value) {
                return minPropertyInvestmentValueHF(intl, minInvestment, investmentRoundCurrency)(0);
              }
            },
            minValue: (value) => {
              if (sanitizedValue(value)) {
                return minPropertyInvestmentValueHF(
                  intl,
                  minInvestment,
                  investmentRoundCurrency
                )(deFormattedStringToFloat(value, intl));
              }
            },
            availableBalance: (value) => {
              if (sanitizedValue(value)) {
                return enoughAvailableBalanceHF({ intl, userAvailableBalance: wallet.Wallet.availableBalance || 0 })(
                  deFormattedStringToFloat(value, intl)
                );
              }
            },
            maxValue: (value) => {
              if (sanitizedValue(value)) {
                return maxInvestmentValueHF({
                  intl,
                  max: maxInvestment,
                  limitPerUser,
                  alreadyInvested: currentUsersTotalInvestment,
                  currency: investmentRoundCurrency,
                })(deFormattedStringToFloat(value, intl));
              }
            },
            maxDecimalDigits: (value) => {
              if (sanitizedValue(value)) {
                return maxDecimalDigitsHF(intl, 2)(deFormattedStringToFloat(value, intl));
              }
            },
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <Input
              {...field}
              id="amount"
              name={intl.formatMessage({ id: 'property.investmentPopUp.amount' })}
              data-testid={TestIds.AmountToInvest}
              unit={translateCurrency(intl, investmentRoundCurrency)}
              defaultValue={numberWithSpaceSeparator(minInvestment, intl.locale)}
              forceValue={forcedValue}
              placeholder="0"
              type="number"
              inputMode="decimal"
              autoFocus
              important
              min={minInvestment}
              step="any"
              autoComplete="nope"
              onChange={(e) => {
                const formattedValue = handleValueFormating({ value: e.target.value, type: 'number', intl });
                field.onChange(formattedValue ?? '');
                if (formattedValue) {
                  setForcedValue(undefined);
                }
                trigger();
              }}
              hasError={!!fieldState.error}
              errorText={fieldState.error?.message}
            />
          </>
        )}
      />
      {errors.amount ? <Spacer height="large" /> : <Spacer height="extraLarge" />}
      {shouldShowWarning ? (
        renderInvestmentQuestionnaireBanners()
      ) : (
        <>
          <ReturnOnInvest
            amount={cleanAmount}
            investmentLengthInMonths={investmentLengthInMonths}
            annualPercentageYield={totalBonusYield !== undefined ? totalBonusYield : annualPercentageYield}
            endPercentageBonus={endPercentageBonus}
          />
          <Button
            data-testid={TestIds.SubmitInvestment}
            htmlType="submit"
            color="primary"
            block
            disabled={insufficientFunds || !!errors.amount || !amountValue || shouldShowWarning}
            loading={useInvestmentOrderResult.orderResult.status === InvestmentOrderStatus.InProgress}
          >
            <Lang id="property.investmentPopUp.confirmButton" />
          </Button>
          <Spacer height="large" />
          <Paragraph fontSize="mini" fontWeight="regular" color="textFaded">
            {shouldShowAlertThreshold ? (
              <Lang id="property.investmentPopUp.showAlertThreshold" />
            ) : (
              <Lang id="property.investmentPopUp.warning" />
            )}
          </Paragraph>
          {isProjectWithOldContract && (
            <>
              <Spacer height="large" />
              <Typography variant="bodyXSRegular" color="subtle">
                <Lang
                  id="property.investmentPopUp.contractDisclaimer"
                  values={{
                    a: LinkString,
                    vopLink: (chunks: string) => LinkString(chunks, LegalDocuments.TermsAndConditions, 'terms-link'),
                    oupLink: (chunks: string) =>
                      LinkString(chunks, LegalDocuments.GeneralLoanConditions, 'loan-terms-link'),
                  }}
                />
              </Typography>
            </>
          )}
          <Spacer height="medium" />
        </>
      )}
      {apiError && <Alert message={apiError} type="error" />}
    </InvestmentFormHolder>
  );
};

const InvestmentFormHolder = styled.form`
  min-width: 462px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default InvestmentOrderForm;

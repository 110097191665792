import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import * as CoreSDK from '../core/__generated__/sdk';
import { QueryKeys } from '../enums';
import { getInvestedProperties, PageRequest, SortRequest } from '../core';

export enum SortBy {
  CreatedAt = 'createdAt',
  InvestedAmount = 'investedAmount',
  AnnualYield = 'annualYield',
  InvestmentLengthInMonths = 'investmentLengthInMonths',
  InterestAmount = 'interestAmount',
  TimeLeft = 'timeLeft',
  PayDay = 'installmentDueDate',
}

export enum FilterBy {
  Autoinvest = CoreSDK.OrderSource.Autoinvest,
  User = CoreSDK.OrderSource.User,
  Regular = CoreSDK.PropertyInvestmentRoundRepaymentStatus.Regular,
  Collection = CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection,
  Delayed = CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed,
  Open = CoreSDK.PropertyInvestmentRoundStatus.Open,
  Processing = CoreSDK.PropertyInvestmentRoundStatus.FullyInvested,
  Completed = CoreSDK.PropertyInvestmentRoundStatus.Ended,
  OnlyProlongated = CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundProlonged,
}

export enum FilterItemGroup {
  'orderSources',
  'investmentRoundStatuses',
  'repaymentStatuses',
  'prolongated',
}

export type ProlongedFilterItem = {
  key: FilterBy;
  type: keyof CoreSDK.InvestedPropertiesFilterType;
  value: boolean;
  label: { value: string };
  isRadio?: false;
  group: FilterItemGroup.prolongated;
};

export type FilterItem =
  | {
      key: FilterBy;
      type: keyof CoreSDK.InvestedPropertiesFilterType;
      value: FilterBy;
      label: { value: string };
      isRadio?: boolean;
      group: FilterItemGroup;
    }
  | ProlongedFilterItem;

export type InvestedProject = CoreSDK.InvestedPropertyObjectType & {
  returns?: CoreSDK.AmountWithCurrencyObjectType;
};

export type RelatedProject = CoreSDK.RelatedPropertyObjectType & {
  returns?: CoreSDK.AmountWithCurrencyObjectType;
};

export type ProjectDetailedItemType = InvestedProject | RelatedProject;

type QueryOptions = Parameters<
  typeof useInfiniteQuery<
    CoreSDK.InvestedPropertiesQuery,
    Error,
    CoreSDK.InvestedPropertiesQuery,
    [string, PageRequest & SortRequest & { filter: CoreSDK.InvestedPropertiesFilterType | undefined }]
  >
>[2];

type RequestParams = PageRequest & SortRequest & { filter: CoreSDK.InvestedPropertiesFilterType | undefined };

export function useInvestedProperties(
  {
    page = 0,
    perPage = 10,
    sortField = 'createdAt',
    sortOrder = 'ASC',
    filter,
  }: {
    page?: number;
    perPage?: number;
    sortField?: string;
    sortOrder?: 'ASC' | 'DESC';
    filter?: CoreSDK.InvestedPropertiesFilterType | undefined;
  } = {},
  queryOptions?: QueryOptions & { onBeforeRequest?: (params: RequestParams) => void }
): UseInfiniteQueryResult<CoreSDK.InvestedPropertiesQuery, Error> {
  return useInfiniteQuery<
    CoreSDK.InvestedPropertiesQuery,
    Error,
    CoreSDK.InvestedPropertiesQuery,
    [string, PageRequest & SortRequest & { filter: CoreSDK.InvestedPropertiesFilterType | undefined }]
  >(
    [QueryKeys.InvestedProperties, { page, perPage, sortField, sortOrder, filter }],
    ({ pageParam = page }) => {
      const queryParams = {
        page: pageParam,
        perPage,
        sortField,
        sortOrder,
        filter,
      };
      if (queryOptions?.onBeforeRequest && typeof queryOptions.onBeforeRequest === 'function') {
        queryOptions.onBeforeRequest(queryParams);
      }
      return getInvestedProperties(queryParams);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.investedProperties.length < perPage ? undefined : pages.length;
      },
      staleTime: 0,
      ...queryOptions,
    }
  );
}

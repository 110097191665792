import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAnalytics } from '@investown/fe/common-utils';

interface RedirectWithTrackingProps {
  redirectFrom: string;
  redirectTo: string;
}

const RedirectRoute: React.FC<RedirectWithTrackingProps> = ({ redirectFrom, redirectTo }) => {
  const analytics = useAnalytics();
  // Track the screen view before redirecting so later on we will know that this redirect can be removed
  analytics.trackScreenView({ path: redirectFrom });

  return <Redirect to={redirectTo} />;
};

export default RedirectRoute;

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./globals.d.ts" />
import * as Sentry from '@sentry/react-native';

import { createLoggerImpl } from './core';
import type { Logger } from './types';

export const createLogger = (): Logger => {
  const logger = createLoggerImpl(__DEV__, Sentry, (Logger) => {
    const globalErrorHandler = ErrorUtils.getGlobalHandler();
    ErrorUtils.setGlobalHandler((error, isFatal) => {
      const fn = isFatal ? Logger.fatal : Logger.error;
      fn('💥', 'Global Error Handler caught an uncaught error!', error);
      globalErrorHandler(error, isFatal);
    });
  });

  return logger;
};

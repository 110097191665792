import React from 'react';
import styled from 'styled-components';

import { TextColorName } from '@investown/fe/common-utils';

import FlexRow, { AlignDirections } from '../Grid/FlexRow/FlexRow';

import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import { TypographyName } from 'styles/theme';

export type StatusLabelColor = 'primary' | 'error' | 'warning' | 'success' | 'default';
interface Props {
  color: StatusLabelColor;
  fontColor?: TextColorName;
  textLeft: React.ReactNode;
  fontLeftVariant?: TypographyName;
  textRight?: React.ReactNode;
  icon?: React.ReactNode;
  isThin?: boolean;
  description?: React.ReactNode;
  onClick?: () => void;
}

function StatusLabel({
  icon,
  color,
  fontColor,
  textLeft,
  fontLeftVariant = 'labelSMMedium',
  textRight,
  description,
  isThin = false,
  onClick,
}: Props) {
  return (
    <Wrapper color={color} isThin={isThin} hasDescription={!!description} onClick={onClick}>
      <StyledFlexRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
        <LeftSide>
          {icon && icon}
          <Spacer width="medium" />
          {textLeft && (
            <Typography variant={fontLeftVariant} {...(fontColor ? { color: fontColor } : {})}>
              {textLeft}
            </Typography>
          )}
        </LeftSide>
        {textRight && <Typography variant="labelSMRegular">{textRight}</Typography>}
      </StyledFlexRow>

      {onClick && description && (
        <>
          <Spacer height="large" />
          <StyledFlexRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
            <Typography variant="bodySMRegular" numberOfLines={3} {...(fontColor ? { color: fontColor } : {})}>
              {description}
            </Typography>
          </StyledFlexRow>
          <Spacer height="large" />
          <StyledFlexRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexEnd}>
            <Typography variant="labelSMMedium" align="right" {...(fontColor ? { color: fontColor } : {})}>
              <Lang id="global.showMore" />
            </Typography>
          </StyledFlexRow>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ color: Props['color']; isThin?: boolean; hasDescription?: boolean; onClick?: () => void }>`
  ${(props) => {
    switch (props.color) {
      case 'primary':
        return `
        color: ${props.theme.colorTokens.text.brand};
        background-color: ${props.theme.colorTokens.surface.brandFaded25};
        svg {
          color: ${props.theme.colorTokens.text.brand};
          path {
            stroke: ${props.theme.colorTokens.text.brand};
          }
        }
      `;
      case 'error':
        return `
        color: ${props.theme.colorTokens.utility.semiWarning.strong};
        background-color: ${props.theme.colorTokens.utility.semiWarning.subtle};
        svg {
          color: ${props.theme.colorTokens.utility.semiWarning.strong};
          path {
            stroke: ${props.theme.colorTokens.utility.semiWarning.strong};
          }
        }
      `;
      case 'warning':
        return `
        color: ${props.theme.colorTokens.text.warning};
        background-color: ${props.theme.colorTokens.utility.warning.subtle};
        svg {
          color: ${props.theme.colorTokens.text.warning};
          path {
            stroke: ${props.theme.colorTokens.text.warning};
          }
        }
      `;
      case 'success':
        return `
        color: ${props.theme.colorTokens.text.success};
        background-color: ${props.theme.colorTokens.utility.success.subtle};
        svg {
          color: ${props.theme.colorTokens.text.success};
          path {
            stroke: ${props.theme.colorTokens.text.success};
          }
        }
      `;
      case 'default':
        return `
        color: ${props.theme.colorTokens.text.medium};
        background-color: ${props.theme.colorTokens.surface.subtle};
        svg {
          color: ${props.theme.colorTokens.text.medium};
          path {
            stroke: ${props.theme.colorTokens.text.medium};
          }
        }
      `;
    }
  }};
  padding: ${(props) => props.theme.spacing.large};
  border-radius: ${(props) => props.theme.borderRadius.small};
  display: flex;
  flex-direction: ${({ hasDescription }) => (hasDescription ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;
  ${({ isThin }) => isThin && `max-height: 32px;`}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  svg {
    width: 16px;
    height: 16px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFlexRow = styled(FlexRow)`
  width: 100%;
`;

export default React.memo(StatusLabel);

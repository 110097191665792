/* eslint-disable @nx/enforce-module-boundaries */
import { useUserLevels } from '../../../../api-sdk/src/lib/hooks/useUserLevels';
import { useUserDetails } from '../../../../api-sdk/src/lib/hooks/useUserDetails';
import { getLevelFromConfig } from '../userLevel';
import { calculateBonusYield } from '../number/numberUtils';

export type GetYieldRangeResult = {
  bonusYieldEligible: boolean;
  basicYield: number;
  currentYield: number;
  maxYield: number;
};

export function useGetYieldRange({
  annualPercentageYield,
  bonusYieldEligible,
}: {
  annualPercentageYield: number;
  bonusYieldEligible: boolean;
}): GetYieldRangeResult | undefined {
  const {
    data: userDetails,
    isLoading: userDetailsIsLoading,
    isError: userDetailsIsError,
    error: useUserDetailsError,
  } = useUserDetails();
  const {
    data: userLevels,
    isLoading: userLevelsIsLoading,
    isError: userLevelsIsError,
    error: useUserLevelsError,
  } = useUserLevels({});

  if (userLevelsIsLoading || userDetailsIsLoading || userDetails == null || userLevels == null) {
    return undefined;
  }
  if (userLevelsIsError || userDetailsIsError || !userDetails || userLevels?.levels.length === 0) {
    console.error('Error fetching user levels or user details', useUserDetailsError, useUserLevelsError);
    return undefined;
  }

  const userLevelConfig = getLevelFromConfig({
    levelId: userDetails.level.currentLevel,
    levels: userLevels.levels,
  });

  if (userLevelConfig === undefined) {
    console.error('Error getting user level');
    return undefined;
  }

  const currentYield = calculateBonusYield({
    annualPercentageYield,
    bonusYield: userLevelConfig.bonusYield,
  });

  const maxLevelYield = userLevels.levels.reduce(
    (prev, curr) => (curr.order > prev.order ? curr : prev),
    userLevels.levels[0]
  ).bonusYield;

  const maxYield = calculateBonusYield({
    annualPercentageYield,
    bonusYield: maxLevelYield,
  });

  return {
    bonusYieldEligible,
    basicYield: annualPercentageYield,
    currentYield,
    maxYield,
  };
}

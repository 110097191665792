import React, { useMemo } from 'react';

import {
  UserDetailsDTO,
  UserVerificationDataDTO,
  UserVerificationStatus,
  useUserTimestamps,
} from '@investown/fe/api-sdk';
import { getNewContractAvailability, isAbilityToBearLossQuestionnaireExpired } from '@investown/fe/common-utils';

import OnboardingSteps from './OnboardingSteps';
import { OnboardingCallbackFnParams } from './helpers';
import OnboardingBanner from './OnboardingBanner/OnboardingBanner';

import FadeIn from 'components/Animations/FadeIn';
import WidgetErrorBoundary from 'components/Widget/WidgetErrorBoundary';
import { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';

interface Props {
  userDetails: UserDetailsDTO;
  userVerificationData: UserVerificationDataDTO;
  direction?: OnboardingBannerDirection;
  isProjectWithOldContract?: boolean;
  isProjectBlockedForUser?: boolean;
  showDepositBanner?: boolean;
  callbackFn: (params: OnboardingCallbackFnParams) => void;
}

function Onboarding({
  userDetails,
  userVerificationData,
  direction = 'row',
  isProjectWithOldContract,
  isProjectBlockedForUser,
  showDepositBanner = true,
  callbackFn,
}: Props) {
  const shouldSignNewContract = getNewContractAvailability({ userDetailsData: userDetails, userVerificationData });
  const { data: userTimestamps } = useUserTimestamps({ refetchOnMount: 'always' });
  const shouldShowOnboardingSteps = useMemo(() => {
    if (direction === 'column') {
      return true;
    }
    if (
      direction === 'row' &&
      userDetails.madeDeposit &&
      (shouldSignNewContract || isAbilityToBearLossQuestionnaireExpired(userTimestamps))
    ) {
      return true;
    }
    return false;
  }, [direction, userDetails.madeDeposit, shouldSignNewContract, userTimestamps]);

  const shouldShowOnboardingBanner = useMemo(() => {
    if (
      direction === 'row' &&
      (!userDetails.madeDeposit || userDetails.verificationStatus !== UserVerificationStatus.Verified)
    ) {
      return true;
    }
    return false;
  }, [direction, userDetails.madeDeposit, userDetails.verificationStatus]);

  return (
    <FadeIn flex>
      <WidgetErrorBoundary>
        {shouldShowOnboardingSteps && (
          <OnboardingSteps
            userDetails={userDetails}
            userVerificationData={userVerificationData}
            isProjectWithOldContract={isProjectWithOldContract}
            isProjectBlockedForUser={isProjectBlockedForUser}
            direction={direction}
            showDepositBanner={showDepositBanner}
            callbackFn={(params) => callbackFn(params)}
          />
        )}
        {shouldShowOnboardingBanner && (
          <OnboardingBanner userDetails={userDetails} userVerificationData={userVerificationData} />
        )}
      </WidgetErrorBoundary>
    </FadeIn>
  );
}

export default Onboarding;

import React from 'react';
import { useIntl } from 'react-intl';

import { DateTimeFormat, DateTimeFormatCZ, formatDateTime } from './dateUtils';

const DateTime: React.FC<
  Omit<DateTimeFormat, 'locale' | 'dateFormat'> & { dateFormat?: DateTimeFormatCZ | string }
> = ({ date, dateFormat = DateTimeFormatCZ.dMyyyy }) => {
  const { locale } = useIntl();
  return (
    <>
      {formatDateTime({
        date,
        dateFormat,
        locale,
      })}
    </>
  );
};

export default DateTime;
